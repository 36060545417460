var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "login" }, [
    _c("div", { staticClass: "title" }, [
      _c("div", { staticClass: "productName" }, [
        _vm._v(_vm._s(_vm.goodsList.productName)),
      ]),
      _c("div", { staticClass: "price" }, [
        _vm._v(" ￥ "),
        _c("span", { staticClass: "price-title" }, [
          _vm._v(_vm._s(_vm.goodsList.currentPrice)),
        ]),
      ]),
    ]),
    _c("div", { staticClass: "btn-wrapper" }, [
      _c("div", { staticClass: "my", on: { click: _vm.toIndex } }, [
        _c("i", {
          staticClass: "iconfont icon-icon_wode",
          staticStyle: { color: "#165dff" },
        }),
        _c("div", { staticStyle: { "margin-top": "0.08rem" } }, [
          _vm._v("我的"),
        ]),
      ]),
      _c(
        "div",
        {
          staticClass: "cost2 toTest",
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.toTest.apply(null, arguments)
            },
          },
        },
        [
          _c("p", [_vm._v("立即检测")]),
          _c("p", { staticClass: "times" }, [
            _vm._v("(" + _vm._s(_vm.goodsList.times) + "次)"),
          ]),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "cost2",
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.toPurchase.apply(null, arguments)
            },
          },
        },
        [_vm._v(_vm._s(_vm.goodsList.currentPrice ? "立即购买" : "免费领取"))]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }