var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "mobile" }, [
    _c("div", { staticClass: "title" }, [_vm._v("使用完整服务")]),
    _c("div", { staticClass: "code" }, [_vm._v("手机号")]),
    _c(
      "div",
      { staticClass: "code-input" },
      [
        _c("el-input", {
          attrs: { placeholder: "请输入手机号" },
          model: {
            value: _vm.mobile,
            callback: function ($$v) {
              _vm.mobile = $$v
            },
            expression: "mobile",
          },
        }),
      ],
      1
    ),
    _c("div", { staticClass: "code code-mobile" }, [_vm._v("验证码")]),
    _c(
      "div",
      { staticClass: "code-input" },
      [
        _vm.getCodeStatus == 1
          ? _c("div", { staticClass: "get-code", on: { click: _vm.getCode } }, [
              _vm._v("获取验证码"),
            ])
          : _c("div", { staticClass: "get-code get-code59" }, [
              _vm._v(_vm._s(_vm.codeNum) + "s后重发"),
            ]),
        _c("el-input", {
          attrs: { placeholder: "请输入验证码" },
          model: {
            value: _vm.code,
            callback: function ($$v) {
              _vm.code = $$v
            },
            expression: "code",
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "serviceAgreement", staticStyle: { display: "none" } },
      [
        _c("el-checkbox", {
          on: { change: _vm.radioChange },
          model: {
            value: _vm.checked,
            callback: function ($$v) {
              _vm.checked = $$v
            },
            expression: "checked",
          },
        }),
        _vm._v(" 我已阅读并同意"),
        _c("span", { on: { click: _vm.peopleServiceAgreement } }, [
          _vm._v("《用户服务协议》"),
        ]),
        _vm._v("和"),
        _c("span", { on: { click: _vm.privacyPolicy } }, [
          _vm._v("《隐私政策》"),
        ]),
      ],
      1
    ),
    _c("div", { staticClass: "btn-wrapper" }, [
      _c(
        "div",
        {
          staticClass: "check",
          staticStyle: { display: "none" },
          on: { click: _vm.close },
        },
        [_vm._v("稍后完善")]
      ),
      _c(
        "div",
        {
          staticClass: "check submit",
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.submitPhone.apply(null, arguments)
            },
          },
        },
        [_vm._v("立即使用")]
      ),
    ]),
    _vm.peopleServiceAgreementShow
      ? _c("div", { staticClass: "serviceAgreementContent" }, [
          _c("div", { staticClass: "serverTitle" }, [
            _c("span", [_vm._v("用户隐私协议")]),
            _c("i", {
              staticClass: "el-icon-warning-outline",
              on: {
                click: function ($event) {
                  _vm.peopleServiceAgreementShow = false
                },
              },
            }),
          ]),
          _vm._m(0),
        ])
      : _vm._e(),
    _vm.privacyPolicyShow
      ? _c("div", { staticClass: "privacyPolicyShowContent" }, [
          _c("div", { staticClass: "serverTitle" }, [
            _c("span", [_vm._v("隐私政策")]),
            _c("i", {
              staticClass: "el-icon-warning-outline",
              on: {
                click: function ($event) {
                  _vm.privacyPolicyShow = false
                },
              },
            }),
          ]),
          _vm._m(1),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "serviceContent" }, [
      _vm._v(
        " 本协议旨在明确说明我们如何收集、使用、保护以及可能披露您的个人信息。我们非常重视 您的隐私，并承诺按照相关法律法规的要求处理您的个人信息。请仔细阅读以下条款，以确 保您了解我们的隐私政策和做法。 "
      ),
      _c("div", { staticClass: "serverTitleList" }, [
        _vm._v("一、信息的收集和使用"),
      ]),
      _c("div", { staticClass: "serverTitleItem" }, [
        _vm._v(
          " 当您使用我们的服务时，我们可能会收集您的个人信息，包括但不限于：姓名、电子邮件地 址、电话号码、地理位置等。这些信息将用于提供、改进和个性化我们的服务。 此外，我们还可能收集与您使用我们的产品和服务相关的其他信息，例如使用记录、浏览记 录等。 "
        ),
      ]),
      _c("div", { staticClass: "serverTitleList" }, [
        _vm._v("二、信息的保护和存储"),
      ]),
      _c("div", { staticClass: "serverTitleItem" }, [
        _vm._v(
          " 我们将采取合理的技术和管理措施来保护您的个人信息，防止数据泄露、篡改或损坏。您的 个人信息将存储在安全的服务器上，我们将遵守相关法律法规的要求，确保您的数据得到充 分保护。 但是，由于网络环境的不安全性，我们不能保证您的个人信息永远不会被盗取或泄露。 "
        ),
      ]),
      _c("div", { staticClass: "serverTitleList" }, [
        _vm._v("三、信息的披露和共享"),
      ]),
      _c("div", { staticClass: "serverTitleItem" }, [
        _vm._v(
          " 我们不会向第三方出售或出租您的个人信息，除非事先得到您的明确同意或法律要求。我们 可能会与我们的合作伙伴、代理商或承包商分享您的个人信息，以便他们能够提供服务。但 是，我们会确保这些合作伙伴、代理商或承包商遵守与我们相同的隐私保护标准，或者按照 我们的要求进行保密。 "
        ),
      ]),
      _c("div", { staticClass: "serverTitleList" }, [
        _vm._v("四、您的权利和选择"),
      ]),
      _c("div", { staticClass: "serverTitleItem" }, [
        _vm._v(
          " 您有权访问和更正您的个人信息。如果您想访问或更正您的个人信息，或者对我们的隐私政 策有任何疑问或建议，可以通过我们的客服渠道联系我们。您还可以要求我们删除您的个人 信息或限制我们对您的个人信息的处理。如果您不同意我们的隐私政策，您可以选择停止使 用我们的产品和服务。 "
        ),
      ]),
      _c("div", { staticClass: "serverTitleList" }, [
        _vm._v("五、变更和争议解决"),
      ]),
      _c("div", { staticClass: "serverTitleItem" }, [
        _vm._v(
          " 我们保留随时修改本协议的权利。如有重大变更，我们将通过平台或其他适当方式提前通知 您。 如您在执行本协议过程中与我们发生争议，应首先通过友好协商解决；协商不成的，任何一 方均有权向有管辖权的法院提起诉讼。 "
        ),
      ]),
      _c("div", { staticClass: "serverTitleList" }, [_vm._v("六、其他")]),
      _c("div", { staticClass: "serverTitleItem" }, [
        _vm._v(
          " 本协议自您使用我们的服务之日起生效，并持续有效直至您停止使用我们的服务或我们终止 本协议。 请注意，本隐私协议可能会根据我们的服务发展和法规变化进行更新。我们鼓励您定期查看 本协议以确保您了解其最新内容。如果您有任何问题或疑虑，或者需要行使本隐私协议中提 到的任何权利，请通过我们的联系方式与我们联系。 "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "serviceContent" }, [
      _vm._v(
        " 我们深知隐私对您的重要性，因此我们制定了本隐私政策，以明确我们收集、使用和保护您 的个人信息的方式。本隐私政策适用于我们所有的产品和服务。 "
      ),
      _c("div", { staticClass: "serverTitleList" }, [_vm._v("一、信息收集")]),
      _c("div", { staticClass: "serverTitleItem" }, [
        _c("div", { staticClass: "serverTtem" }, [
          _vm._v(
            " 1. 我们可能会收集您的姓名、电子邮件地址、电话号码、住址、IP 地址、设备信息以及其他 非公开的个人信息。 "
          ),
        ]),
        _c("div", { staticClass: "serverTtem" }, [
          _vm._v(
            " 2. 我们可能会收集您在使用我们的产品和服务过程中产生的其他信息，例如搜索记录、浏览 记录、购买记录等。 "
          ),
        ]),
        _c("div", { staticClass: "serverTtem" }, [
          _vm._v(
            " 3. 我们可能会从第三方获取您的个人信息，例如合作伙伴、社交媒体平台或其他数据源。 "
          ),
        ]),
      ]),
      _c("div", { staticClass: "serverTitleList" }, [_vm._v("二、信息使用")]),
      _c("div", { staticClass: "serverTitleItem" }, [
        _c("div", { staticClass: "serverTtem" }, [
          _vm._v(
            " 1. 我们将使用您的个人信息来提供和改进我们的产品和服务，包括但不限于定制内容、个性 化推荐、客户服务等。 "
          ),
        ]),
        _c("div", { staticClass: "serverTtem" }, [
          _vm._v(
            " 2. 我们可能会使用您的个人信息进行内部研究和改进，以提高我们的产品和服务的质量和效率。 "
          ),
        ]),
        _c("div", { staticClass: "serverTtem" }, [
          _vm._v(
            " 3. 我们可能会将您的个人信息用于市场营销和宣传活动，例如发送电子邮件、短信或推送通知。 "
          ),
        ]),
      ]),
      _c("div", { staticClass: "serverTitleList" }, [_vm._v("三、信息保护")]),
      _c("div", { staticClass: "serverTitleItem" }, [
        _c("div", { staticClass: "serverTtem" }, [
          _vm._v(
            " 1. 我们将采取合理的安全措施来保护您的个人信息，包括但不限于加密、访问控制和数据备份。 "
          ),
        ]),
        _c("div", { staticClass: "serverTtem" }, [
          _vm._v(
            " 2. 我们将限制对您的个人信息的访问和使用，只有经过授权的员工和合作伙伴才能访问和使 用您的个人信息。 "
          ),
        ]),
        _c("div", { staticClass: "serverTtem" }, [
          _vm._v(
            " 3. 我们将遵守适用的法律和法规，确保您的个人信息的合法性和安全性。 "
          ),
        ]),
        _c("div", { staticClass: "serverTtem" }, [
          _vm._v(
            " 4. 我们不会向任何第三方出售或泄露用户个人信息，除非得到用户的明确授权或法律法规要 求。 "
          ),
        ]),
      ]),
      _c("div", { staticClass: "serverTitleList" }, [
        _vm._v("四、信息共享和披露"),
      ]),
      _c("div", { staticClass: "serverTitleItem" }, [
        _c("div", { staticClass: "serverTtem" }, [
          _vm._v(
            " 1. 我们可能会将您的个人信息共享给我们的合作伙伴、代理商或承包商，以提供更好的服务。 但是，我们将确保这些合作伙伴遵守与我们相同的隐私保护标准。 "
          ),
        ]),
        _c("div", { staticClass: "serverTtem" }, [
          _vm._v(
            " 2. 在法律要求的情况下，我们可能会披露您的个人信息给相关的政府机构或法律程序。 "
          ),
        ]),
        _c("div", { staticClass: "serverTtem" }, [
          _vm._v(
            " 3. 在紧急情况下，为了保护您的人身安全或他人的利益，我们可能会披露必要的个人信息。 "
          ),
        ]),
      ]),
      _c("div", { staticClass: "serverTitleList" }, [
        _vm._v("五、隐私政策的更新和修改"),
      ]),
      _c("div", { staticClass: "serverTitleItem" }, [
        _c("div", { staticClass: "serverTtem" }, [
          _vm._v(
            " 1. 我们可能会根据我们的业务需求和市场变化更新或修改本隐私政策。在更新或修改本隐私 政策时，我们将通过网站公告或电子邮件等方式通知您。 "
          ),
        ]),
        _c("div", { staticClass: "serverTtem" }, [
          _vm._v(
            " 2. 如果我们对本隐私政策的修改涉及到您的重要权益，您有权要求我们提供修改后的隐私政 策，并有权在收到通知后的合理时间内提出异议。 "
          ),
        ]),
      ]),
      _c("div", { staticClass: "serverTitleList" }, [_vm._v("六、争议解决")]),
      _c("div", { staticClass: "serverTitleItem" }, [
        _c("div", { staticClass: "serverTtem" }, [
          _vm._v(
            " 1. 如果您对我们的隐私政策或我们的隐私实践有任何疑问或异议，您可以通过我们的客服联 系我们，我们将尽快回复您。 "
          ),
        ]),
        _c("div", { staticClass: "serverTtem" }, [
          _vm._v(
            " 2. 如果您认为我们的隐私政策或我们的隐私实践违反了适用的法律和法规，您可以通过我们 的客服提出投诉，我们将尽快调查并采取适当的纠正措施。 "
          ),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }