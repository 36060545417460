var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "paymentSuccess" }, [
    _c("img", {
      staticClass: "icon-wode",
      attrs: { src: require("@/assets/noposter.png"), alt: "" },
    }),
    _c("div", { staticClass: "successText" }, [
      _vm._v("海报信息不存在或者已被删除"),
    ]),
    _c("div", { staticClass: "info" }, [
      _vm._v(" 您可以用手机验证码快速登录电脑端官方网站，查看海报的相关信息 "),
    ]),
    _c("div", { staticClass: "inputs", attrs: { id: "copyInner" } }, [
      _vm._v(" www.ai-tax.cn "),
    ]),
    _c("div", { staticClass: "copy", on: { click: _vm.copy } }, [
      _vm._v(" 复制链接 "),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }