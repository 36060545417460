var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "whiteStyle" }),
    _c("div", [
      _c("div", { staticClass: "h5-wrapper" }, [
        _c(
          "div",
          { staticClass: "content-section" },
          [
            _c("div", { staticClass: "title" }, [_vm._v("收入类型分析")]),
            _vm.incomeTypeData
              ? _c("RingBarTabs", { attrs: { data: _vm.incomeTypeData } })
              : _vm._e(),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "content-section" },
          [
            _c("div", { staticClass: "title" }, [_vm._v("成本费用分析")]),
            _vm.costAnalysis
              ? _c("RingBarTabs", { attrs: { data: _vm.costAnalysis } })
              : _vm._e(),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "content-section" },
          [
            _c("div", { staticClass: "title" }, [_vm._v("核心财务数据")]),
            _vm.coreFinancialData
              ? _c("CoreFinanceData", {
                  attrs: { data: _vm.coreFinancialData },
                })
              : _vm._e(),
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass: "content-section",
            staticStyle: { "margin-bottom": "20px" },
          },
          [
            _c("div", { staticClass: "title" }, [_vm._v("偿债能力分析")]),
            _vm.solvencyAnalysis
              ? _c("DebtPayingAbilityTabs", {
                  attrs: { data: _vm.solvencyAnalysis },
                })
              : _vm._e(),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }