var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "chart-wrapper" }, [
    _c("div", { staticClass: "unit" }, [_vm._v("单位：" + _vm._s(_vm.unit))]),
    _c("div", { ref: "theChart", staticClass: "echarts1" }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }