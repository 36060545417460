var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "whiteStyle" }),
    _c(
      "div",
      { staticClass: "h5-wrapper" },
      [
        _c(
          "van-tabs",
          {
            model: {
              value: _vm.active,
              callback: function ($$v) {
                _vm.active = $$v
              },
              expression: "active",
            },
          },
          _vm._l(_vm.imgData, function (v, k) {
            return _c("van-tab", { key: k, attrs: { title: v.name } }, [
              _c(
                "div",
                { staticClass: "invoice-list" },
                _vm._l(v.invoiceList, function (a, b) {
                  return _c("fapiao", {
                    key: b,
                    staticClass: "invoice-item",
                    attrs: { data: a },
                  })
                }),
                1
              ),
            ])
          }),
          1
        ),
        _c(
          "van-overlay",
          {
            attrs: { show: _vm.show },
            on: {
              click: function ($event) {
                _vm.show = false
              },
            },
          },
          [
            _c("img", {
              staticClass: "invoice-item-horizontal",
              attrs: { src: _vm.currentPic, alt: "" },
            }),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }