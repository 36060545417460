var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "whiteStyle" }),
    _c(
      "div",
      { staticClass: "h5-wrapper" },
      [
        _c(
          "van-tabs",
          {
            attrs: { swipeable: "" },
            on: { change: _vm.tabschange },
            model: {
              value: _vm.active,
              callback: function ($$v) {
                _vm.active = $$v
              },
              expression: "active",
            },
          },
          [
            _c("van-tab", { attrs: { title: "进项发票", name: "entry" } }, [
              _c(
                "div",
                { staticClass: "content-section" },
                [
                  _c(
                    "div",
                    { staticClass: "titleTop" },
                    [
                      _c("div", { staticClass: "title" }, [_vm._v("发票类型")]),
                      _c("stateType", {
                        ref: "typebutton1_1",
                        on: {
                          statTypechange: function ($event) {
                            return _vm.statTypechange($event)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.typeData
                    ? _c("RingBarTabs", {
                        attrs: { data: _vm.typeData, active: _vm.active },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "content-section" },
                [
                  _c(
                    "div",
                    { staticClass: "titleTop" },
                    [
                      _c("div", { staticClass: "title" }, [_vm._v("发票状态")]),
                      _c("stateType", {
                        ref: "typebutton2_1",
                        on: {
                          statTypechange: function ($event) {
                            return _vm.statTypechange2($event)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.stateData
                    ? _c("HistogramTabs", { attrs: { data: _vm.stateData } })
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "content-section" },
                [
                  _c(
                    "div",
                    { staticClass: "titleTop" },
                    [
                      _c("div", { staticClass: "title" }, [_vm._v("特殊发票")]),
                      _c("stateType", {
                        ref: "typebutton3_1",
                        on: {
                          statTypechange: function ($event) {
                            return _vm.statTypechange3($event)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.specialData
                    ? _c("HistogramTabs", { attrs: { data: _vm.specialData } })
                    : _vm._e(),
                  _c(
                    "router-link",
                    {
                      staticClass: "btn btn-special-invoice",
                      attrs: {
                        to: {
                          name: "SpecialInvoiceDetail",
                          params: {
                            txType: _vm.active,
                            desensitizationStatus:
                              _vm.$route.params.desensitizationStatus,
                          },
                        },
                      },
                    },
                    [_vm._v("特殊发票明细")]
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "content-section mb40" },
                [
                  _c(
                    "div",
                    { staticClass: "titleTop" },
                    [
                      _c("div", { staticClass: "title" }, [
                        _vm._v("可能存在风险的发票"),
                      ]),
                      _c("stateType", {
                        ref: "typebutton4_1",
                        on: {
                          statTypechange: function ($event) {
                            return _vm.statTypechange4($event)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.riskData
                    ? _c("HistogramTabs", { attrs: { data: _vm.riskData } })
                    : _vm._e(),
                ],
                1
              ),
            ]),
            _c("van-tab", { attrs: { title: "销项发票", name: "sale" } }, [
              _c(
                "div",
                { staticClass: "content-section" },
                [
                  _c(
                    "div",
                    { staticClass: "titleTop" },
                    [
                      _c("div", { staticClass: "title" }, [_vm._v("发票类型")]),
                      _c("stateType", {
                        ref: "typebutton1_2",
                        on: {
                          statTypechange: function ($event) {
                            return _vm.statTypechange($event)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.typeData
                    ? _c("RingBarTabs", {
                        attrs: { txType: _vm.active, data: _vm.typeData },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "content-section" },
                [
                  _c(
                    "div",
                    { staticClass: "titleTop" },
                    [
                      _c("div", { staticClass: "title" }, [_vm._v("发票状态")]),
                      _c("stateType", {
                        ref: "typebutton2_2",
                        on: {
                          statTypechange: function ($event) {
                            return _vm.statTypechange2($event)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.stateData
                    ? _c("HistogramTabs", { attrs: { data: _vm.stateData } })
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "content-section" },
                [
                  _c(
                    "div",
                    { staticClass: "titleTop" },
                    [
                      _c("div", { staticClass: "title" }, [_vm._v("特殊发票")]),
                      _c("stateType", {
                        ref: "typebutton3_2",
                        on: {
                          statTypechange: function ($event) {
                            return _vm.statTypechange3($event)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.specialData
                    ? _c("HistogramTabs", { attrs: { data: _vm.specialData } })
                    : _vm._e(),
                  _c(
                    "router-link",
                    {
                      staticClass: "btn btn-special-invoice",
                      attrs: {
                        to: {
                          name: "SpecialInvoiceDetail",
                          params: {
                            txType: _vm.active,
                            desensitizationStatus:
                              _vm.$route.params.desensitizationStatus,
                          },
                        },
                      },
                    },
                    [_vm._v("特殊发票明细")]
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "content-section mb40" },
                [
                  _c(
                    "div",
                    { staticClass: "titleTop" },
                    [
                      _c("div", { staticClass: "title" }, [
                        _vm._v("可能存在风险的发票"),
                      ]),
                      _c("stateType", {
                        ref: "typebutton4_2",
                        on: {
                          statTypechange: function ($event) {
                            return _vm.statTypechange4($event)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.riskData
                    ? _c("HistogramTabs", { attrs: { data: _vm.riskData } })
                    : _vm._e(),
                ],
                1
              ),
            ]),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }