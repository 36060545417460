var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "orderInformation" },
    [
      _c(
        "el-form",
        {
          ref: "ruleForm",
          attrs: {
            model: _vm.ruleForm,
            "label-width": "",
            id: "demo-ruleForm",
          },
        },
        [
          _c(
            "el-form-item",
            {
              staticClass: "demo-ruleForm",
              attrs: {
                prop: "companyName",
                rules: {
                  required: true,
                  message: "请输入企业名称，至少4个字",
                  trigger: "blur",
                },
                label: "企业名称",
              },
            },
            [
              _c(
                "el-select",
                {
                  ref: "select",
                  attrs: {
                    filterable: "",
                    remote: "",
                    "reserve-keyword": "",
                    placeholder: "请输入企业名称，最少4个字",
                    "remote-method": _vm.remoteMethod,
                    loading: _vm.loading,
                  },
                  on: {
                    "visible-change": _vm.cancalReadOnly,
                    change: _vm.nameChange,
                    "hook:mounted": _vm.cancalReadOnly,
                  },
                  model: {
                    value: _vm.companyName,
                    callback: function ($$v) {
                      _vm.companyName = $$v
                    },
                    expression: "companyName",
                  },
                },
                _vm._l(_vm.options, function (item, index) {
                  return _c("el-option", {
                    key: item.creditCode,
                    attrs: { label: item.name, value: index },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                prop: "nsrsbh",
                rules: {
                  required: true,
                  message: "请先搜索企业",
                  trigger: "blur",
                },
                label: "社会信用代码",
              },
            },
            [
              _c("el-input", {
                attrs: {
                  placeholder: "选择企业名称后自动带出，无需填写",
                  disabled: "",
                },
                model: {
                  value: _vm.ruleForm.nsrsbh,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "nsrsbh", $$v)
                  },
                  expression: "ruleForm.nsrsbh",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                prop: "time",
                rules: {
                  required: true,
                  message: "请选择结束日期",
                  trigger: "blur",
                },
                label: "检测期间",
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "timeSelect",
                  on: {
                    click: function ($event) {
                      _vm.timeShow = true
                    },
                  },
                },
                [
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm.time ? _vm.timeStart : "请先选择开始日期")
                    ),
                  ]),
                  _vm._v(" - "),
                  _c("span", [
                    _vm._v(_vm._s(_vm.time ? _vm.time : "结束日期")),
                  ]),
                  _c("i", { staticClass: "iconfont icon-icon_deta" }),
                ]
              ),
              _c("DatetimePicker", {
                ref: "popup",
                attrs: {
                  values: _vm.time,
                  title: "请选择结束" + _vm.unitChinese,
                  valueFormat: _vm.unit,
                  showPicker: _vm.timeShow,
                },
                on: {
                  changeValue: function ($event) {
                    _vm.timeShow = false
                  },
                  confirm: _vm.selectTime,
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                prop: "bizType",
                rules: {
                  required: true,
                  message: "请选择业务类型",
                  trigger: "blur",
                },
                label: "业务类型",
              },
            },
            [
              _c(
                "van-radio-group",
                {
                  model: {
                    value: _vm.ruleForm.bizType,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "bizType", $$v)
                    },
                    expression: "ruleForm.bizType",
                  },
                },
                [
                  _c("van-radio", { attrs: { name: 2 } }, [_vm._v("出口为主")]),
                  _c(
                    "van-radio",
                    {
                      staticStyle: { "margin-left": "70px" },
                      attrs: { name: 1 },
                    },
                    [_vm._v("内销为主")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                prop: "remainDaysName",
                rules: {
                  required: true,
                  message: "数据保留天数",
                  trigger: "change",
                },
                label: "数据保留天数",
              },
            },
            [
              _c(
                "div",
                { staticClass: "timeSelect", on: { click: _vm.inputfocus } },
                [
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.ruleForm.remainDaysName
                          ? _vm.ruleForm.remainDaysName
                          : "请选择数据保留天数"
                      )
                    ),
                  ]),
                  _c("i", { staticClass: "iconfont icon-icon_arrow_down" }),
                ]
              ),
              _c(
                "van-popup",
                {
                  attrs: { "get-container": "body", position: "bottom" },
                  model: {
                    value: _vm.isPicker,
                    callback: function ($$v) {
                      _vm.isPicker = $$v
                    },
                    expression: "isPicker",
                  },
                },
                [
                  _vm.isPicker
                    ? _c(
                        "div",
                        [
                          _c("van-picker", {
                            ref: "picker",
                            attrs: {
                              "show-toolbar": "",
                              title: "保留天数",
                              columns: _vm.columns,
                              "default-index": _vm.defaultIndex,
                            },
                            on: {
                              confirm: _vm.onConfirm,
                              cancel: _vm.cancelOn,
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              ),
            ],
            1
          ),
          _c("div", { staticStyle: { height: "0.64rem" } }),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "btn-item",
          on: {
            click: function ($event) {
              return _vm.submitForm("ruleForm")
            },
          },
        },
        [_vm._v("下一步")]
      ),
      _c("vantDialog", { ref: "vantDialogRef" }),
      _c("sqxy", { ref: "sqxyRef" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }