var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("router-view", {
    attrs: {
      channelUniqueId: _vm.channelUniqueId,
      posterUniqueId: _vm.posterUniqueId,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }