var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "topdiv" }, [
      _c("div", [
        _c("span", { staticClass: "totalNum" }, [
          _vm._v(_vm._s(_vm.data.undeclared)),
        ]),
        _c("span", { staticClass: "fontStyle" }, [_vm._v("零申报月份数量")]),
      ]),
      _c("div", [
        _c("span", { staticClass: "totalNum" }, [
          _vm._v(_vm._s(_vm.data.continuousUndeclared)),
        ]),
        _c("span", [_vm._v("连续3个月零申报次数")]),
      ]),
    ]),
    _c("div", { staticClass: "bottomdiv" }, [
      _c("div", [_vm._v("零申报月份为：")]),
      _c("div", [_vm._v(_vm._s(_vm.months))]),
      _c("div", [_vm._v("零申报的影响：")]),
      _c("div", [
        _vm._v(
          " 企业在正常经营的状态下，一般不会出现增值税多次或者连续零申报的情况，可能存在错误适用增值税免税政策、接受虚开发票、少申报应税收入等风险，请注意自查。 "
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }