var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "wrapper" }, [
    _c(
      "div",
      { staticClass: "chart-wrapper" },
      [
        _c("div", { staticClass: "unit" }, [
          _vm._v("单位：" + _vm._s(_vm.data.unit)),
        ]),
        _vm.isshowcharts
          ? _c("div", { staticClass: "isshowcharts" }, [
              _vm._v("本年度系统采集到企业"),
              _c("span", [
                _vm._v(_vm._s(_vm.activeIndex == "entry" ? "进项" : "销项")),
              ]),
              _vm._v("发票数据为0，请及时确认！"),
            ])
          : _vm._e(),
        !_vm.isshowcharts
          ? _c("PieChart", {
              key: JSON.stringify(_vm.chartData),
              attrs: {
                colors: _vm.colors,
                data: _vm.chartData,
                title: _vm.data.imageTitle,
                total: _vm.total,
              },
            })
          : _vm._e(),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "legends" },
      _vm._l(_vm.chartData, function (item, index) {
        return _c("div", { key: index, staticClass: "legend" }, [
          _c("svg", { staticClass: "icon", attrs: { "aria-hidden": "true" } }, [
            _c("use", { attrs: { "xlink:href": _vm.getIcons(item.name) } }),
          ]),
          _c(
            "div",
            { staticClass: "legend-right" },
            [
              _c("div", { staticClass: "item-text-wrapper" }, [
                _c("div", { staticClass: "item-text-item title" }, [
                  _vm._v(" " + _vm._s(item.name) + " "),
                ]),
                _c("div", { staticClass: "item-text-item percentage" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        (Math.round(item.value * 100 * 10000) / 10000).toFixed(
                          2
                        )
                      ) +
                      "% "
                  ),
                ]),
                _c("div", { staticClass: "item-text-item value" }, [
                  _vm._v(" " + _vm._s(item.quantity) + " "),
                ]),
              ]),
              _c("el-progress", {
                attrs: {
                  "show-text": false,
                  "text-inside": true,
                  "stroke-width": 17,
                  percentage: Math.round(item.value * 100 * 100) / 100,
                  color: _vm.colors[index % _vm.colors.length],
                },
              }),
            ],
            1
          ),
        ])
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }