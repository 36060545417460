var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "whiteStyle" }),
    _c("div", [
      _c("div", { staticClass: "h5-wrapper" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.alert,
                expression: "alert",
              },
            ],
            staticClass: "level",
          },
          [_vm._v("等级偏低，需关注")]
        ),
        _c(
          "div",
          { staticClass: "content-section" },
          [
            _c("div", { staticClass: "title" }, [_vm._v("纳税信用等级")]),
            _vm.solvencyAnalysis
              ? _c("DebtPayingAbilityTabs", {
                  attrs: { data: _vm.solvencyAnalysis },
                })
              : _vm._e(),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "content-section" },
          [
            _c("div", { staticClass: "title" }, [_vm._v("历年纳税情况")]),
            _vm.typeData
              ? _c("RingBarTabs", { attrs: { data: _vm.typeData } })
              : _vm._e(),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "content-section" },
          [
            _c("div", { staticClass: "title" }, [_vm._v("纳税资金流出情况")]),
            _c(
              "button",
              { staticClass: "selectButton", on: { click: _vm.startSelect } },
              [_vm._v("选择税种")]
            ),
            _c("dialogs", {
              ref: "dialogs",
              on: {
                confirm: function ($event) {
                  return _vm.confirm($event)
                },
              },
            }),
            _vm.lineData
              ? _c("LineBarTabs", {
                  ref: "LineBarTabs",
                  attrs: { data: _vm.lineData, activeIndex: _vm.activeIndex },
                  on: {
                    tabschange: function ($event) {
                      return _vm.tabschange($event)
                    },
                  },
                })
              : _vm._e(),
          ],
          1
        ),
        _vm.yearData
          ? _c(
              "div",
              { staticClass: "content-section" },
              [
                _c("div", { staticClass: "title" }, [
                  _vm._v(_vm._s(_vm.yearData.year + "年增值税申报情况")),
                ]),
                _c("report", { attrs: { data: _vm.yearData } }),
              ],
              1
            )
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }