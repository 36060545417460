var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "success" }, [
    _c("img", { attrs: { src: require("@/assets/h5_success.png"), alt: "" } }),
    _c("div", { staticClass: "t1" }, [_vm._v("提交成功！")]),
    _c("div", { staticClass: "t2" }, [_vm._v("稍后会有专属客服联系您")]),
    _c("div", { staticClass: "t3", on: { click: _vm.toIndex } }, [
      _vm._v("返回首页"),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }