var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "selectButton" }, [
      _c("div", [
        _c(
          "span",
          {
            class: _vm.statType == "quantity" ? "selected" : "",
            on: {
              click: function ($event) {
                return _vm.statTypechange("quantity")
              },
            },
          },
          [_vm._v("发票数量")]
        ),
        _c(
          "span",
          {
            class: _vm.statType == "amount" ? "selected2" : "",
            on: {
              click: function ($event) {
                return _vm.statTypechange("amount")
              },
            },
          },
          [_vm._v("含税金额")]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }