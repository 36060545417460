var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("BossTable", {
        staticClass: "table",
        attrs: { loaded: !!_vm.data, data: _vm.table },
      }),
      _c("div", { staticClass: "tableBottom" }, [
        _vm._v(
          " 企业良好的纳税信用可以作为企业融资和招投标的 “砝码”，以及国家各项税收优惠政策的“敲门砖”，企业需关注并努力提升自身的纳税信用等级。 "
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }