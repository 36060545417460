var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "info" },
    [
      _c("div", { staticClass: "title" }, [_vm._v("产品详细信息")]),
      _vm._l(_vm.goodInfo, function (item, index) {
        return _c("div", { staticClass: "item" }, [
          _c("div", [_vm._v(_vm._s(item.name))]),
          _c("div", [
            _c(
              "span",
              { staticClass: "addFam", attrs: { id: "access" + index } },
              [_vm._v(_vm._s(item.value))]
            ),
            _vm._v(_vm._s(item.unit) + " "),
            item.label == "systemUrl"
              ? _c(
                  "span",
                  {
                    staticClass: "systemUrl",
                    on: {
                      click: function ($event) {
                        return _vm.copyCode("access" + index)
                      },
                    },
                  },
                  [_vm._v("复制")]
                )
              : _vm._e(),
          ]),
        ])
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }