var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "backPic" }, [
    _vm._m(0),
    _c("div", { staticClass: "btn2" }, [
      _vm.btnStatus == true && !this.$route.query.btnStatus
        ? _c("div", { staticClass: "btnList" }, [
            _c("div", { staticClass: "order", on: { click: _vm.goEdit } }, [
              _vm._v("返回修改"),
            ]),
            _c(
              "div",
              { staticClass: "cost", on: { click: _vm.generatePoster } },
              [_vm._v("生成海报")]
            ),
          ])
        : _c("div", { staticClass: "btnList" }, [
            _c("div", { staticClass: "order", on: { click: _vm.goBack } }, [
              _vm._v("返回我的"),
            ]),
            _c("div", { staticClass: "cost", on: { click: _vm.savePicture } }, [
              _vm._v("保存到相册"),
            ]),
          ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "img", staticStyle: { height: "calc(100vh - 2rem)" } },
      [
        _c("div", { staticClass: "posterContainer" }, [
          _c("div", [
            _c("div", { staticClass: "posterName" }, [
              _vm._v("这里是海报名称这里是海报"),
            ]),
            _c("div", { staticClass: "posterItem" }, [
              _c("div", [_vm._v("产品名称：老板看报告")]),
              _c("div", [_vm._v("产品单价：0 元")]),
              _c("div", [_vm._v("项目数量：1 个")]),
              _c("div", [_vm._v("单项目ocr次数：200 次")]),
              _c("div", [_vm._v("报告单价：0 元/次")]),
              _c("div", [_vm._v("账号数量：10 个")]),
              _c("div", [_vm._v("系统网址：http://lvat.ai-tax.cn/")]),
              _c("div", [_vm._v("使用时效：365 天")]),
            ]),
          ]),
          _c("div", [
            _c("div", { staticClass: "posterQR" }, [
              _c("img", {
                attrs: { src: require("@/assets/banner.png"), alt: "" },
              }),
            ]),
            _c("div", { staticClass: "QRTip" }, [
              _vm._v("微信扫一扫 立即体验"),
            ]),
            _c("div", { staticClass: "posterTel" }, [
              _c("div", [_vm._v("联系人：李晓峰")]),
              _c("div", [_vm._v("联系方式：15983720371")]),
            ]),
          ]),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }