var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticStyle: { color: "#1D2129" } }, [
    _c(
      "div",
      {
        staticStyle: {
          display: "flex",
          "flex-direction": "column",
          "align-items": "center",
          "justify-content": "center",
          "margin-top": "0.6rem",
        },
      },
      [
        _c("img", {
          staticStyle: { width: "1.20rem", height: "1.20rem" },
          attrs: { src: require("@/assets/icon_dzf.svg"), alt: "" },
        }),
        _c(
          "div",
          {
            staticStyle: {
              color: "#1D2129",
              "font-weight": "600",
              "font-size": ".4rem",
              "margin-top": "0.32rem",
              "margin-bottom": "0.16rem",
            },
          },
          [
            _vm.orderInfo.orderStatus == -1
              ? [_vm._v(" 已取消 ")]
              : _vm.orderInfo.orderStatus == 1
              ? [_vm._v(" 待支付 ")]
              : _vm.orderInfo.orderStatus == 2
              ? [_vm._v(" 已完成 ")]
              : _vm._e(),
          ],
          2
        ),
        _vm.orderInfo.orderCountDown != null
          ? _c(
              "div",
              { staticStyle: { "font-weight": "400", "font-size": ".28rem" } },
              [
                _c("span", [_vm._v("还剩")]),
                _vm._v(" "),
                _c("count-down", {
                  attrs: {
                    milliseconds: parseInt(_vm.orderInfo.orderCountDown),
                  },
                  on: { timeout: _vm.handleCountDownTimeout },
                }),
                _c("span", [_vm._v("订单自动取消")]),
              ],
              1
            )
          : _vm._e(),
      ]
    ),
    _c(
      "div",
      { staticStyle: { padding: "0 .3rem", "margin-top": ".6rem" } },
      [
        _c(
          "van-radio-group",
          {
            model: {
              value: _vm.selectPayment,
              callback: function ($$v) {
                _vm.selectPayment = $$v
              },
              expression: "selectPayment",
            },
          },
          _vm._l(_vm.payments, function (payment, index) {
            return _c(
              "div",
              {
                key: index,
                staticStyle: {
                  background: "#fff",
                  "margin-bottom": ".2rem",
                  display: "flex",
                  padding: ".2rem",
                  "align-items": "center",
                  "justify-content": "space-between",
                },
                on: {
                  click: function ($event) {
                    return _vm.handlePaymentSelect(index)
                  },
                },
              },
              [
                _c(
                  "div",
                  { staticStyle: { display: "flex", "align-items": "center" } },
                  [
                    _c("img", {
                      staticStyle: {
                        width: ".72rem",
                        height: ".72rem",
                        "margin-right": ".24rem",
                      },
                      attrs: { src: payment.icon },
                    }),
                    _c("div", { staticStyle: { "font-size": ".32rem" } }, [
                      _vm._v(_vm._s(payment.name)),
                    ]),
                  ]
                ),
                _c("div", [_c("van-radio", { attrs: { name: index } })], 1),
              ]
            )
          }),
          0
        ),
      ],
      1
    ),
    _c("div", { staticClass: "footer-toolbar" }, [
      _vm.orderInfo.orderStatus == 1
        ? _c(
            "button",
            {
              staticClass: "btn btn-primary",
              staticStyle: { "font-size": ".32rem" },
              on: { click: _vm.handlePaymentClick },
            },
            [
              _vm._v(
                " 确认支付 ￥" +
                  _vm._s(_vm.formatToDecimal(_vm.orderInfo.totalAmount)) +
                  " "
              ),
            ]
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }