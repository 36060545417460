var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "whiteStyle" }),
    _c("div", { staticClass: "h5-wrapper", attrs: { id: "h5-wrapper" } }, [
      _vm.isShow == true
        ? _c("div", { staticClass: "top-bg" }, [
            _c("div", { staticClass: "company-name" }, [
              _c("span", [_vm._v(_vm._s(_vm.riskInfo.companyName))]),
            ]),
            _c("div", { staticClass: "date" }, [
              _vm._v(
                _vm._s(_vm.riskInfo.startTime) +
                  " - " +
                  _vm._s(_vm.riskInfo.endTime)
              ),
            ]),
          ])
        : _c("div", { staticClass: "top-bg" }, [
            this.$route.query.desensitization == 0
              ? _c("div", { staticClass: "company-name" }, [
                  _c("span", [_vm._v(_vm._s(_vm.riskInfo.companyName))]),
                ])
              : _c("div", { staticClass: "company-name" }, [
                  _vm._v(
                    " " + _vm._s(_vm.basicInfo.desensitizationCompanyName) + " "
                  ),
                ]),
            _c("div", { staticClass: "date" }, [
              _vm._v(
                _vm._s(_vm.riskInfo.startTime) +
                  " - " +
                  _vm._s(_vm.riskInfo.endTime)
              ),
            ]),
          ]),
      _c("div", { staticClass: "main-section" }, [
        _vm.isShow
          ? _c(
              "div",
              { staticClass: "share", staticStyle: { display: "none" } },
              [
                _c(
                  "div",
                  { staticClass: "wrapper" },
                  [
                    _c(
                      "p",
                      {
                        on: {
                          click: function ($event) {
                            _vm.dialogVisible = true
                          },
                        },
                      },
                      [_vm._v("分享")]
                    ),
                    _c(
                      "van-popup",
                      {
                        style: { width: "360px" },
                        attrs: { closeable: "" },
                        model: {
                          value: _vm.dialogVisible,
                          callback: function ($$v) {
                            _vm.dialogVisible = $$v
                          },
                          expression: "dialogVisible",
                        },
                      },
                      [
                        _c(
                          "van-form",
                          {
                            ref: "form",
                            staticClass: "content",
                            attrs: { "validate-first": "" },
                          },
                          [
                            _c("div", { staticClass: "title" }, [
                              _vm._v("安全提示"),
                            ]),
                            _c("div", { staticClass: "text" }, [
                              _vm._v(
                                " 检测报告涉及企业隐私数据，请谨慎分享，分享后对方查看报告需要输入校验码。 "
                              ),
                            ]),
                            _c(
                              "div",
                              { staticClass: "form-control" },
                              [
                                _c("van-field", {
                                  attrs: {
                                    name: "验证码有效期",
                                    label: "验证码有效期",
                                    rules: [
                                      {
                                        validator: _vm.rules.expireHours,
                                        message: "请输入1-48的整数",
                                      },
                                    ],
                                    border: true,
                                  },
                                  model: {
                                    value: _vm.form.expireHours,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.form,
                                        "expireHours",
                                        _vm._n($$v)
                                      )
                                    },
                                    expression: "form.expireHours",
                                  },
                                }),
                                _c("div", { staticClass: "unit" }, [
                                  _vm._v("小时"),
                                ]),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "tuomin" },
                              [
                                _vm.desensitizationAvailable
                                  ? _c(
                                      "van-checkbox",
                                      {
                                        attrs: { shape: "square" },
                                        model: {
                                          value: _vm.form.desensitization,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "desensitization",
                                              $$v
                                            )
                                          },
                                          expression: "form.desensitization",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "对报告中关键的公司名称、税号、地址进行脱敏 "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c("div", { staticClass: "dialog-footer" }, [
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.shareText,
                                    expression: "shareText",
                                  },
                                ],
                                staticClass: "textarea",
                                attrs: { id: "input" },
                                domProps: { value: _vm.shareText },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.shareText = $event.target.value
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-ok",
                                  on: { click: _vm.handleConfirmClick },
                                },
                                [_vm._v("复制链接及验证码")]
                              ),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            )
          : _vm._e(),
        _c("div", { staticClass: "top-scores" }, [
          _c("div", { staticClass: "top-score" }, [
            _c("div", { staticClass: "score dark_grey" }, [
              _vm._v(_vm._s(_vm.riskInfo.total)),
            ]),
            _c("div", { staticClass: "score-name" }, [_vm._v("检测总项")]),
          ]),
          _c("div", { staticClass: "top-score" }, [
            _c("div", { staticClass: "score dark_grey" }, [
              _vm._v(_vm._s(_vm.riskInfo.active)),
            ]),
            _c("div", { staticClass: "score-name" }, [_vm._v("风险项")]),
          ]),
          _c("div", { staticClass: "top-score" }, [
            _c("div", { staticClass: "score" }, [
              _vm._v(
                " " +
                  _vm._s(_vm.getRiskLevelLabel(_vm.riskInfo.riskLevel)) +
                  " "
              ),
            ]),
            _c("div", { staticClass: "score-name" }, [_vm._v("风险等级")]),
          ]),
        ]),
        _c("div", { staticClass: "statistic-section" }, [
          _c("div", { staticClass: "date-unit-wrapper" }, [
            _c("div", { staticClass: "date" }, [
              _vm._v(
                _vm._s(_vm.businessInfo.startTime) +
                  " - " +
                  _vm._s(_vm.businessInfo.endTime)
              ),
            ]),
            _c("div", { staticClass: "unit" }, [_vm._v("单位：万元")]),
          ]),
          _c("div", { staticClass: "statistic-items" }, [
            _c("div", { staticClass: "statistic-item" }, [
              _c("div", { staticClass: "statistic-item-value" }, [
                _vm._v(_vm._s(_vm.businessInfo.yysr)),
              ]),
              _c("div", { staticClass: "statistic-item-name" }, [
                _vm._v("营业收入"),
              ]),
            ]),
            _c("div", { staticClass: "statistic-item" }, [
              _c("div", { staticClass: "statistic-item-value" }, [
                _vm._v(_vm._s(_vm.businessInfo.yycb)),
              ]),
              _c("div", { staticClass: "statistic-item-name" }, [
                _vm._v("营业成本"),
              ]),
            ]),
            _c("div", { staticClass: "statistic-item" }, [
              _c("div", { staticClass: "statistic-item-value" }, [
                _vm._v(_vm._s(_vm.businessInfo.mlr)),
              ]),
              _c("div", { staticClass: "statistic-item-name" }, [
                _vm._v("毛利额"),
              ]),
            ]),
            _c("div", { staticClass: "statistic-item" }, [
              _c("div", { staticClass: "statistic-item-value" }, [
                _vm._v(_vm._s(_vm.businessInfo.jlr)),
              ]),
              _c("div", { staticClass: "statistic-item-name" }, [
                _vm._v("净利润"),
              ]),
            ]),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "entries-section" },
          [
            _c(
              "router-link",
              {
                staticClass: "entry-box",
                attrs: { to: { name: "IndustryComparison" } },
              },
              [
                _c("div", { staticClass: "entry-name" }, [
                  _c("img", {
                    attrs: {
                      src: require("../../../assets/icon@2x.png"),
                      alt: "",
                    },
                  }),
                  _c("span", [_vm._v(" 行业对比")]),
                ]),
                _c("div", { staticClass: "entry-text" }, [
                  _vm._v("和同行比，和过往比"),
                ]),
              ]
            ),
            _c(
              "router-link",
              {
                staticClass: "entry-box",
                attrs: { to: { name: "financeAnalysis" } },
              },
              [
                _c("div", { staticClass: "entry-name" }, [
                  _c("img", {
                    attrs: {
                      src: require("../../../assets/icon@2x (1).png"),
                      alt: "",
                    },
                  }),
                  _c("span", [_vm._v(" 财务分析")]),
                ]),
                _c("div", { staticClass: "entry-text" }, [
                  _vm._v("3年账务，一目了然"),
                ]),
              ]
            ),
            _c(
              "router-link",
              {
                staticClass: "entry-box",
                staticStyle: { "margin-bottom": "0" },
                attrs: {
                  to: {
                    name: "OneKeyWatchInvoice",
                    params: {
                      desensitizationStatus:
                        _vm.$route.params.desensitizationStatus,
                    },
                  },
                },
              },
              [
                _c("div", { staticClass: "entry-name" }, [
                  _c("img", {
                    attrs: {
                      src: require("../../../assets/icon@2x (2).png"),
                      alt: "",
                    },
                  }),
                  _c("span", [_vm._v(" 一键看票")]),
                ]),
                _c("div", { staticClass: "entry-text" }, [
                  _vm._v("进/销项发票，问题发票"),
                ]),
              ]
            ),
            _c(
              "router-link",
              {
                staticClass: "entry-box",
                staticStyle: { "margin-bottom": "0" },
                attrs: { to: { name: "TaxAnalysis" } },
              },
              [
                _c("div", { staticClass: "entry-name" }, [
                  _c("img", {
                    attrs: {
                      src: require("../../../assets/icon@2x (3).png"),
                      alt: "",
                    },
                  }),
                  _c("span", [_vm._v(" 纳税分析")]),
                ]),
                _c("div", { staticClass: "entry-text" }, [
                  _vm._v("税额、税目"),
                ]),
              ]
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "risk-project-section" },
          [
            _c("div", { staticClass: "tool-bar" }, [
              _c("div", { staticClass: "title" }, [_vm._v("风险项目")]),
              _c(
                "div",
                { staticClass: "btns" },
                [
                  _c(
                    "div",
                    { staticClass: "btn2", on: { click: _vm.riskFilterShow } },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.riskFilterValue == null
                              ? "全部"
                              : _vm.riskFilterValue == -1
                              ? "未触警"
                              : _vm.riskFilterValue == 0
                              ? "无风险"
                              : _vm.riskFilterValue == 1
                              ? "低风险"
                              : _vm.riskFilterValue == 2
                              ? "中风险"
                              : "高风险"
                          ) +
                          " "
                      ),
                      _c("i", { staticClass: "iconfont icon-icon_arrow_down" }),
                    ]
                  ),
                  _c("van-action-sheet", {
                    attrs: {
                      actions: _vm.riskOptions,
                      "cancel-text": "取消",
                      "close-on-click-action": "",
                    },
                    on: {
                      cancel: _vm.onCancel,
                      select: _vm.handleRiskFilterChange,
                    },
                    model: {
                      value: _vm.riskFilter,
                      callback: function ($$v) {
                        _vm.riskFilter = $$v
                      },
                      expression: "riskFilter",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("BossTable", {
              staticClass: "risk-project-table",
              attrs: { loaded: _vm.loaded, data: _vm.riskList },
            }),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }