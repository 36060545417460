var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "van-dialog",
        {
          attrs: {
            title: "选择税种(最多三项):",
            "show-cancel-button": "",
            confirmButtonColor: "#2663F6",
          },
          on: { confirm: _vm.confirm },
          model: {
            value: _vm.show,
            callback: function ($$v) {
              _vm.show = $$v
            },
            expression: "show",
          },
        },
        [
          _c(
            "div",
            { staticClass: "btns" },
            _vm._l(_vm.btns, function (v, k) {
              return _c(
                "div",
                {
                  key: k,
                  class: v.selected == true ? "selected" : "",
                  on: {
                    click: function ($event) {
                      return _vm.choonse(v.value, k)
                    },
                  },
                },
                [_vm._v(" " + _vm._s(v.label) + " ")]
              )
            }),
            0
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }