var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "paymentSuccess" }, [
    _c("img", {
      staticClass: "icon-wode",
      attrs: { src: require("@/assets/组 8406.png"), alt: "" },
    }),
    _c("div", { staticClass: "successText" }, [_vm._v("支付成功")]),
    _c("div", { staticClass: "btn-wrapper" }, [
      _c("div", { staticClass: "cost", on: { click: _vm.goText } }, [
        _vm._v("立即检测"),
      ]),
      _c("div", { staticClass: "order", on: { click: _vm.goMy } }, [
        _vm._v("我的订单"),
      ]),
      _c("div", { staticClass: "order", on: { click: _vm.goHome } }, [
        _vm._v("商品橱窗"),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }