var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "agreement-wrapper" }, [
    _c(
      "div",
      {
        staticStyle: {
          "font-size": ".34rem",
          height: ".78rem",
          "font-weight": "600",
        },
      },
      [_vm._v("智能财税服务平台服务协议")]
    ),
    _c(
      "div",
      {
        staticStyle: {
          height: "70vh",
          overflow: "auto",
          "margin-right": "-0.3rem",
          "padding-right": ".3rem",
        },
      },
      [_c("AgreementText")],
      1
    ),
    _c("div", { staticStyle: { margin: ".32rem 0 .2rem" } }, [
      _c(
        "button",
        { staticClass: "btn btn-primary", on: { click: _vm.agree } },
        [_vm._v("已阅读并同意签署")]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }