var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "pay",
      on: {
        click: function ($event) {
          return _vm.toDetail(_vm.item.orderNo)
        },
      },
    },
    [
      _c("div", { staticClass: "pay-top" }, [
        _c("div", { staticClass: "haibaoPic" }, [
          _c("img", {
            staticClass: "backPic",
            attrs: { src: _vm.item.showImageUrl, alt: "" },
          }),
          _c("img", {
            staticClass: "version-logo",
            attrs: { src: _vm.getCornerPic(_vm.item.attrs), alt: "" },
          }),
        ]),
        _c(
          "div",
          {
            staticStyle: {
              display: "flex",
              width: "4.7rem",
              "justify-content": "space-between",
            },
          },
          [
            _c(
              "div",
              {
                staticStyle: {
                  display: "flex",
                  "flex-direction": "column",
                  "justify-content": "space-between",
                },
              },
              [
                _c(
                  "span",
                  { staticClass: "pay-title" },
                  [
                    _c("span", { staticClass: "pay-titles" }, [
                      _vm._v(_vm._s(_vm.item.productName)),
                    ]),
                    _vm.type == 2
                      ? [
                          _vm.item.orderStatus == -1
                            ? _c("img", {
                                staticClass: "yizhifu",
                                attrs: {
                                  src: require("@/assets/img_jygb.svg"),
                                  alt: "",
                                },
                              })
                            : _vm._e(),
                          _vm.item.orderStatus == 1
                            ? _c("img", {
                                staticClass: "yizhifu",
                                attrs: {
                                  src: require("@/assets/img_dzf.svg"),
                                  alt: "",
                                },
                              })
                            : _vm._e(),
                          _vm.item.orderStatus == 2
                            ? _c("img", {
                                staticClass: "yizhifu",
                                attrs: {
                                  src: require("@/assets/img_jywc.svg"),
                                  alt: "",
                                },
                              })
                            : _vm._e(),
                        ]
                      : _vm._e(),
                  ],
                  2
                ),
                _c("span", { staticClass: "pay-price" }, [
                  _c(
                    "span",
                    {
                      staticClass: "productUnitPrice",
                      class: { productUnitPrice20: _vm.type == 2 },
                    },
                    [
                      _c("FancyDecimal", { attrs: { value: _vm.item.price } }),
                      this.$route.name != "detail"
                        ? _c("span", { staticClass: "goodsCount" }, [
                            _vm._v(" x" + _vm._s(_vm.item.goodsCount)),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
                _vm.type == 2
                  ? _c("span", { staticClass: "pay-del" }, [
                      _vm._v("×" + _vm._s(_vm.item.goodsCount)),
                    ])
                  : _vm._e(),
              ]
            ),
            _vm.type != 2
              ? _c("div", { staticClass: "pay-status" }, [
                  _c(
                    "div",
                    { staticClass: "status-top" },
                    [
                      _vm.item.orderStatus == -1
                        ? [
                            _c("div", {
                              staticClass: "pay-status-icon",
                              staticStyle: { background: "#F54242" },
                            }),
                            _c(
                              "div",
                              {
                                staticClass: "pay-status-txt",
                                staticStyle: { color: "#F54242" },
                              },
                              [_vm._v("已取消")]
                            ),
                          ]
                        : _vm.item.orderStatus == 1
                        ? [
                            _c("div", {
                              staticClass: "pay-status-icon",
                              staticStyle: { background: "#FDB320" },
                            }),
                            _c("div", { staticStyle: { color: "#FDB320" } }, [
                              _vm._v("待支付"),
                            ]),
                          ]
                        : _vm.item.orderStatus == 2
                        ? [
                            _c("div", {
                              staticClass: "pay-status-icon",
                              staticStyle: { color: "#10CC99" },
                            }),
                            _c("div", { staticStyle: { color: "#10CC99" } }, [
                              _vm._v("已完成"),
                            ]),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "status-bottom",
                      staticStyle: {
                        display: "flex",
                        "align-items": "flex-end",
                      },
                    },
                    [
                      _c("FancyDecimal", {
                        attrs: { value: _vm.item.totalAmount },
                      }),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
          ]
        ),
      ]),
      _vm.type != 2
        ? _c("div", { staticClass: "pay-bottom" }, [
            _c("div", { staticClass: "pay-time" }, [
              _vm._v(_vm._s(_vm.item.createTime)),
            ]),
            _vm.item.orderStatus == 1
              ? _c("div", { staticClass: "pay-btn" }, [_vm._v("立即支付")])
              : _vm._e(),
          ])
        : _vm._e(),
      this.$route.name != "my"
        ? _c("div", { staticClass: "pay-total" }, [
            _c("div", { staticClass: "txt" }, [_vm._v("应付金额")]),
            _c(
              "div",
              {
                staticClass: "txtMoney",
                staticStyle: {
                  color: "#165dff !important",
                  display: "flex",
                  "align-items": "flex-end",
                },
              },
              [_c("FancyDecimal", { attrs: { value: _vm.item.totalAmount } })],
              1
            ),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }