var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "reportBeing" }, [
    _c("img", {
      staticClass: "statusImg",
      attrs: { src: require("@/assets/img_success.png"), alt: "" },
    }),
    _c("div", { staticClass: "title" }, [_vm._v("报告生成中…")]),
    _vm._m(0),
    _c("div", { staticClass: "btn2" }, [
      _c("div", { staticClass: "cost", on: { click: _vm.inspectionRecords } }, [
        _vm._v("检测记录"),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "waring" }, [
      _c("div", [_vm._v("风险报告生成大约需要 2 个小时")]),
      _c("div", [_vm._v("报告生成后将短信通知您，请耐心等待…")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }