var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "card-container" },
    [
      _vm._m(0),
      _c("div", { staticClass: "card-info" }, [
        _vm._m(1),
        _c(
          "div",
          { staticClass: "card-person" },
          [
            _c(
              "div",
              {
                staticClass: "organization-content",
                on: { click: _vm.clickOrganiaztion },
              },
              [
                _c("p", [_vm._v(_vm._s(_vm.organizationName))]),
                _c("van-icon", {
                  staticClass: "organization-arrow",
                  class: { arrowRotate: _vm.showOrganiaztion },
                  attrs: { name: "arrow-left" },
                }),
              ],
              1
            ),
            _c("van-action-sheet", {
              attrs: {
                round: "",
                actions: _vm.actionsOrganiaztion,
                "cancel-text": "取消",
              },
              on: {
                cancel: _vm.organiaztionCancel,
                select: _vm.organiaztionSelect,
              },
              model: {
                value: _vm.showOrganiaztion,
                callback: function ($$v) {
                  _vm.showOrganiaztion = $$v
                },
                expression: "showOrganiaztion",
              },
            }),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "showcase" }, [
        _c(
          "div",
          [
            _c("img", {
              attrs: { src: require("../../assets/showcase.png"), alt: "" },
            }),
            _c("div", { staticClass: "shu" }),
            _c(
              "van-swipe",
              {
                staticClass: "notice-swipe",
                attrs: {
                  vertical: "",
                  autoplay: 3000,
                  "show-indicators": false,
                },
              },
              _vm._l(_vm.goodsList, function (v, k) {
                return _c("van-swipe-item", { key: "goodList" + k }, [
                  _vm._v(_vm._s(v.productName) + " "),
                ])
              }),
              1
            ),
          ],
          1
        ),
        _c("div", { staticClass: "toseesee", on: { click: _vm.toseesee } }, [
          _c("span", [_vm._v("去看看")]),
          _c("i", { staticClass: "iconfont icon-icon_arrow_right" }),
        ]),
      ]),
      _c("div", { staticClass: "card-item" }, [
        _c("div", { staticClass: "card-tab" }, [
          _c(
            "div",
            {
              staticClass: "tab",
              class: { activeText: _vm.active == 3 },
              on: {
                click: function ($event) {
                  return _vm.toTab(3)
                },
              },
            },
            [
              _c("div", [_vm._v("风险检测")]),
              _vm.active == 3 ? _c("div", { staticClass: "active" }) : _vm._e(),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "tab",
              class: { activeText: _vm.active == 1 },
              on: {
                click: function ($event) {
                  return _vm.toTab(1)
                },
              },
            },
            [
              _c("div", [_vm._v("我的订单")]),
              _vm.active == 1 ? _c("div", { staticClass: "active" }) : _vm._e(),
            ]
          ),
        ]),
      ]),
      _vm.isMobile
        ? _c("mobile", {
            staticClass: "home-mobile",
            staticStyle: { display: "none" },
            on: { closeMobile: _vm.closeMobile, bindPhoneWx: _vm.bindPhoneWx },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "img-wd" }, [
      _c("img", {
        attrs: { src: require("@/assets/img_wd_bg切图用.png"), alt: "" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "card-icon" }, [
      _c("img", {
        attrs: { src: require("@/assets/icon_user_photo.png"), alt: "" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }