var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "WordSection1" }, [
      _c(
        "p",
        { staticClass: "MsoNormal", staticStyle: { "line-height": "150%" } },
        [_c("span", { attrs: { lang: "EN-US" } })]
      ),
      _c(
        "p",
        {
          staticClass: "MsoBodyText",
          staticStyle: { "text-align": "center", "line-height": "150%" },
          attrs: { align: "center" },
        },
        [
          _c("b", [
            _c(
              "span",
              {
                staticStyle: {
                  "font-size": "16.0pt",
                  "line-height": "150%",
                  color: "#0C0C0C",
                  "letter-spacing": "-.05pt",
                },
              },
              [_vm._v("智能财税服务平台服务协议")]
            ),
          ]),
        ]
      ),
      _c(
        "p",
        {
          staticClass: "MsoBodyText",
          staticStyle: { "text-align": "center", "line-height": "150%" },
          attrs: { align: "center" },
        },
        [
          _c("b", [
            _c(
              "span",
              {
                staticStyle: {
                  "font-size": "11.0pt",
                  "line-height": "150%",
                  "letter-spacing": "-.1pt",
                },
              },
              [_vm._v("特")]
            ),
          ]),
          _c("b", [
            _c(
              "span",
              {
                staticStyle: {
                  "font-size": "11.0pt",
                  "line-height": "150%",
                  "letter-spacing": "-.05pt",
                },
              },
              [_vm._v("别提醒")]
            ),
          ]),
        ]
      ),
      _c(
        "p",
        { staticClass: "MsoNormal", staticStyle: { "line-height": "150%" } },
        [_c("span", { attrs: { lang: "EN-US" } }, [_vm._v(" ")])]
      ),
      _c("p", { staticClass: "MsoBodyText", staticStyle: {} }, [
        _c("span", { staticStyle: { "letter-spacing": ".6pt" } }, [
          _vm._v("本服务协议（下称"),
          _c("span", { attrs: { lang: "EN-US" } }, [_vm._v("“")]),
          _vm._v("本协议"),
          _c("span", { attrs: { lang: "EN-US" } }, [_vm._v("”")]),
          _vm._v("）"),
        ]),
        _c("span", { staticStyle: { "letter-spacing": ".7pt" } }, [
          _vm._v("是税安科技（杭州）有限公司"),
        ]),
        _c(
          "span",
          {
            staticStyle: { "letter-spacing": ".45pt" },
            attrs: { lang: "EN-US" },
          },
          [_vm._v("(")]
        ),
        _c("span", { staticStyle: {} }, [
          _vm._v("以下统称"),
          _c("span", { attrs: { lang: "EN-US" } }, [_vm._v("“")]),
          _vm._v("税安科技"),
          _c("span", { attrs: { lang: "EN-US" } }, [_vm._v("”")]),
          _vm._v("或"),
          _c("span", { attrs: { lang: "EN-US" } }, [_vm._v("“")]),
          _vm._v("我们"),
          _c("span", { attrs: { lang: "EN-US" } }, [_vm._v("”)")]),
          _vm._v("与您（以下或称"),
          _c("span", { attrs: { lang: "EN-US" } }, [_vm._v("“")]),
          _vm._v("用户"),
          _c("span", { attrs: { lang: "EN-US" } }, [_vm._v("”")]),
          _vm._v("）"),
        ]),
        _c("span", { staticStyle: { "letter-spacing": ".6pt" } }, [
          _vm._v("就税安科技产品"),
        ]),
        _c("span", { staticStyle: { "letter-spacing": ".45pt" } }, [
          _vm._v("的相关事项所订立的有效合约。"),
        ]),
      ]),
      _c("p", { staticClass: "MsoBodyText", staticStyle: {} }, [
        _c("span", { staticStyle: { "letter-spacing": ".65pt" } }, [
          _vm._v("在接受本协议之前，请您务必审慎阅读、"),
        ]),
        _c("span", { staticStyle: { "letter-spacing": ".7pt" } }, [
          _vm._v("充分理解本"),
        ]),
        _c("span", { staticStyle: { "letter-spacing": ".65pt" } }, [
          _vm._v("协议各条款的内容，特别是免除"),
        ]),
        _c("span", { staticStyle: { "letter-spacing": ".6pt" } }, [
          _vm._v("或者限制责任的条款、"),
        ]),
        _c("span", { staticStyle: { "letter-spacing": ".7pt" } }, [
          _vm._v("法律适用和争议解决"),
        ]),
        _c("span", { staticStyle: { "letter-spacing": ".65pt" } }, [
          _vm._v("条款以及其他涉及您重大权益影响的条款，这"),
        ]),
        _c("span", { staticStyle: {} }, [
          _vm._v("些条款将以粗体标识，您应重点阅读。如果您对本协议的条款有"),
        ]),
        _c("span", { staticStyle: { "letter-spacing": ".45pt" } }, [
          _vm._v("疑问的，可向税安科技咨询，"),
        ]),
        _c("span", { staticStyle: { "letter-spacing": ".5pt" } }, [
          _vm._v("我们将向您解释该疑问"),
        ]),
        _c("span", { staticStyle: { "letter-spacing": ".45pt" } }, [
          _vm._v("条款。"),
        ]),
      ]),
      _c(
        "p",
        {
          staticClass: "MsoBodyText",
          staticStyle: { "text-align": "center", "line-height": "150%" },
          attrs: { align: "center" },
        },
        [
          _c("b", [
            _c(
              "span",
              {
                staticStyle: { "letter-spacing": ".5pt" },
                attrs: { lang: "EN-US" },
              },
              [_vm._v("通用")]
            ),
            _c(
              "span",
              {
                staticStyle: { "letter-spacing": ".4pt" },
                attrs: { lang: "EN-US" },
              },
              [_vm._v("条款")]
            ),
          ]),
        ]
      ),
      _c(
        "p",
        { staticClass: "MsoNormal", staticStyle: { "line-height": "150%" } },
        [_c("span", { attrs: { lang: "EN-US" } }, [_vm._v(" ")])]
      ),
      _c("p", { staticClass: "MsoBodyText", staticStyle: {} }, [
        _c("b", [
          _c(
            "span",
            {
              staticStyle: { "letter-spacing": "-1.05pt" },
              attrs: { lang: "EN-US" },
            },
            [
              _vm._v("1"),
              _c("span", { staticStyle: { font: '7.0pt "Times New Roman"' } }, [
                _vm._v("    "),
              ]),
            ]
          ),
        ]),
        _c("b", [
          _c(
            "span",
            {
              staticStyle: { "letter-spacing": ".25pt" },
              attrs: { lang: "EN-US" },
            },
            [_vm._v("总则")]
          ),
        ]),
      ]),
      _c("p", { staticClass: "MsoBodyText", staticStyle: {} }, [
        _c(
          "span",
          {
            staticStyle: { "letter-spacing": "-.6pt" },
            attrs: { lang: "EN-US" },
          },
          [
            _vm._v("1.1"),
            _c("span", { staticStyle: { font: '7.0pt "Times New Roman"' } }, [
              _vm._v("   "),
            ]),
          ]
        ),
        _c(
          "span",
          {
            staticStyle: { "letter-spacing": ".4pt" },
            attrs: { lang: "EN-US" },
          },
          [_vm._v("签约动作及协议范围")]
        ),
      ]),
      _c(
        "p",
        { staticClass: "MsoBodyText", staticStyle: { "line-height": "150%" } },
        [
          _c(
            "span",
            {
              staticStyle: { "letter-spacing": "-1.05pt" },
              attrs: { lang: "EN-US" },
            },
            [
              _vm._v("1.1.1"),
              _c("span", { staticStyle: { font: '7.0pt "Times New Roman"' } }, [
                _vm._v("        "),
              ]),
            ]
          ),
          _c("b", [
            _c("span", { staticStyle: { "letter-spacing": ".2pt" } }, [
              _vm._v("您自行或委托服务商通过税安科技官方网站"),
            ]),
          ]),
          _c("b", [_c("span", { staticStyle: { "letter-spacing": ".7pt" } })]),
          _c("b", [
            _c("span", { staticStyle: {}, attrs: { lang: "EN-US" } }, [
              _vm._v("www.ai-tax.cn"),
            ]),
          ]),
          _c("b", [
            _c("span", { staticStyle: { "letter-spacing": ".15pt" } }, [
              _vm._v("（以下简称"),
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v("“")]),
              _vm._v("官网"),
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v("”")]),
              _vm._v(
                "）、税安科技或服务商的推广海报页面（以下简称“推广海报”），点"
              ),
            ]),
          ]),
          _c("b", [
            _c("span", { staticStyle: { "letter-spacing": ".55pt" } }, [
              _vm._v("击确认或以其他方式选"),
            ]),
          ]),
          _c("b", [
            _c("span", { staticStyle: {} }, [
              _vm._v("择接受本协议，包括但不限于未点击确认本协议而事实上使"),
            ]),
          ]),
          _c("b", [
            _c("span", { staticStyle: { "letter-spacing": ".55pt" } }, [
              _vm._v("用了税安科技产品，即"),
            ]),
          ]),
          _c("b", [
            _c("span", { staticStyle: { "letter-spacing": ".5pt" } }, [
              _vm._v("表示您与税安科技已达成协议并同意接受本协议的全部约定内"),
            ]),
          ]),
          _c("b", [
            _c("span", { staticStyle: { "letter-spacing": ".55pt" } }, [
              _vm._v("容。本协议自您通过网"),
            ]),
          ]),
          _c("b", [
            _c("span", { staticStyle: {} }, [
              _vm._v("络页面点击同意或以其他方式选择接受本协议之日起成立。"),
            ]),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".5pt" } }, [
            _vm._v(
              "如若双方线下盖章文本与官网或推广海报点击确认或以其他方式选择接"
            ),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".45pt" } }, [
            _vm._v("受之协议文本不一致的，"),
          ]),
          _c("span", { staticStyle: {} }, [_vm._v("以双方线下盖章文")]),
          _c("span", { staticStyle: { "letter-spacing": ".45pt" } }, [
            _vm._v("本为准。"),
          ]),
        ]
      ),
      _c(
        "p",
        { staticClass: "MsoBodyText", staticStyle: { "line-height": "150%" } },
        [
          _c(
            "span",
            {
              staticStyle: { "letter-spacing": "-1.05pt" },
              attrs: { lang: "EN-US" },
            },
            [
              _vm._v("1.1.2"),
              _c("span", { staticStyle: { font: '7.0pt "Times New Roman"' } }, [
                _vm._v("        "),
              ]),
            ]
          ),
          _c("span", { staticStyle: { "letter-spacing": ".5pt" } }, [
            _vm._v(
              "如果您不同意本协议的任意内容，或者无法准确理解我们对疑问条款的解释，请不要进行后"
            ),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".45pt" } }, [
            _vm._v("续操作。"),
          ]),
        ]
      ),
      _c(
        "p",
        { staticClass: "MsoBodyText", staticStyle: { "line-height": "150%" } },
        [
          _c(
            "span",
            {
              staticStyle: { "letter-spacing": "-1.05pt" },
              attrs: { lang: "EN-US" },
            },
            [
              _vm._v("1.1.3"),
              _c("span", { staticStyle: { font: '7.0pt "Times New Roman"' } }, [
                _vm._v("        "),
              ]),
            ]
          ),
          _c("b", [
            _c("span", { staticStyle: { "letter-spacing": ".5pt" } }, [
              _vm._v(
                "您知悉并确认：本协议由特别提醒、通用条款组成，各组成部分均具有同等法律效"
              ),
            ]),
          ]),
          _c("b", [
            _c("span", { staticStyle: { "letter-spacing": ".45pt" } }, [
              _vm._v(
                "力，与您此前注册交易平台账户时点击确认的《用户注册和服务协议》、"
              ),
            ]),
          ]),
          _c("b", [
            _c("span", { staticStyle: { "letter-spacing": ".5pt" } }, [
              _vm._v("《授权协议"),
            ]),
          ]),
          _c("b", [_c("span", { staticStyle: {} }, [_vm._v("》")])]),
          _c("b", [
            _c("span", { staticStyle: { "letter-spacing": ".55pt" } }, [
              _vm._v("构成"),
            ]),
          ]),
          _c("b", [
            _c("span", { staticStyle: { "letter-spacing": ".5pt" } }, [
              _vm._v(
                "一个完整的整体，请您不定期地或在接到变更通知后登录税安科技"
              ),
            ]),
          ]),
          _c("b", [
            _c("span", { staticStyle: { "letter-spacing": ".45pt" } }, [
              _vm._v("网站查看本协议及其组成部分以及其更新后的版本"),
            ]),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".8pt" } }, [
            _vm._v("。"),
          ]),
        ]
      ),
      _c("p", { staticClass: "MsoBodyText" }, [
        _c("span", { attrs: { lang: "EN-US" } }, [
          _vm._v("1.2"),
          _c("span", { staticStyle: { font: '7.0pt "Times New Roman"' } }, [
            _vm._v("  "),
          ]),
        ]),
        _c(
          "span",
          {
            staticStyle: { "letter-spacing": ".35pt" },
            attrs: { lang: "EN-US" },
          },
          [_vm._v("签约主体")]
        ),
      ]),
      _c(
        "p",
        { staticClass: "MsoBodyText", staticStyle: { "line-height": "150%" } },
        [
          _c(
            "span",
            {
              staticStyle: { "letter-spacing": "-1.05pt" },
              attrs: { lang: "EN-US" },
            },
            [
              _vm._v("1.2.1"),
              _c("span", { staticStyle: { font: '7.0pt "Times New Roman"' } }, [
                _vm._v("        "),
              ]),
            ]
          ),
          _c("span", { staticStyle: { "letter-spacing": ".5pt" } }, [
            _vm._v(
              "您确认：在您注册、下单、认证或以其他税安科技允许的方式实际使用税安科技产品时，您应当是具有完全民事权利能力和完全民事行为能力的自然人、法人或其他非法人组"
            ),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".45pt" } }, [
            _vm._v("织（"),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".5pt" } }, [
            _vm._v("以下"),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".45pt" } }, [
            _vm._v("统称为"),
            _c("span", { attrs: { lang: "EN-US" } }, [_vm._v("“")]),
            _vm._v("法律主体"),
            _c("span", { attrs: { lang: "EN-US" } }, [_vm._v("”")]),
            _vm._v("）。"),
          ]),
        ]
      ),
      _c(
        "p",
        { staticClass: "MsoBodyText", staticStyle: { "line-height": "150%" } },
        [
          _c(
            "span",
            {
              staticStyle: { "letter-spacing": "-1.05pt" },
              attrs: { lang: "EN-US" },
            },
            [
              _vm._v("1.2.2"),
              _c("span", { staticStyle: { font: '7.0pt "Times New Roman"' } }, [
                _vm._v("        "),
              ]),
            ]
          ),
          _c("span", { staticStyle: { "letter-spacing": ".5pt" } }, [
            _vm._v(
              "如果您的本次签约行为系代理第三方主体而为之，则您承诺您的行为已经获得该第三方充分有效的授权，包括但不限于注册、"
            ),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".6pt" } }, [
            _vm._v("填写基本信息、上传"),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".55pt" } }, [
            _vm._v("证照资料、选择服"),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".5pt" } }, [
            _vm._v("务类型、"),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".55pt" } }, [
            _vm._v("填写确认订单、确认接受本协议、支付产品费用及服务费、"),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".5pt" } }, [
            _vm._v(
              "申请发票以及进行账户维护及管理等，否则可能因此给第三方造成的损失由您独立"
            ),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".45pt" } }, [
            _vm._v("承担。"),
          ]),
        ]
      ),
      _c("p", { staticClass: "MsoBodyText", staticStyle: {} }, [
        _c(
          "span",
          {
            staticStyle: { color: "#333333", "letter-spacing": "-.6pt" },
            attrs: { lang: "EN-US" },
          },
          [
            _vm._v("1.3"),
            _c("span", { staticStyle: { font: '7.0pt "Times New Roman"' } }, [
              _vm._v("   "),
            ]),
          ]
        ),
        _c("span", { staticStyle: { "letter-spacing": ".45pt" } }, [
          _vm._v("用户信息的填写、核实与变更"),
        ]),
      ]),
      _c(
        "p",
        { staticClass: "MsoBodyText", staticStyle: { "line-height": "150%" } },
        [
          _c(
            "span",
            {
              staticStyle: { color: "#333333", "letter-spacing": "-1.05pt" },
              attrs: { lang: "EN-US" },
            },
            [
              _vm._v("1.3.1"),
              _c("span", { staticStyle: { font: '7.0pt "Times New Roman"' } }, [
                _vm._v("        "),
              ]),
            ]
          ),
          _c("span", { staticStyle: { "letter-spacing": ".5pt" } }, [
            _vm._v(
              "您确认：在交易平台上填写的下单信息、订单信息及提供给税安科技的所有文件和资料都是合法、"
            ),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".55pt" } }, [
            _vm._v("真实、准确、完整和有效的，不存在虚假记载、重大遗漏或"),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".5pt" } }, [
            _vm._v("误导性陈述。如因您填写的信息、"),
          ]),
          _c("span", { staticStyle: {} }, [_vm._v("资")]),
          _c("span", { staticStyle: { "letter-spacing": ".55pt" } }, [
            _vm._v("料不真实、不完整或不准确的，您将承担无法正常使用"),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".5pt" } }, [
            _vm._v("税安科技产品（包括但不限于不能注册成功、"),
          ]),
          _c("span", { staticStyle: {} }, [_vm._v("用户认证被驳回、账")]),
          _c("span", { staticStyle: { "letter-spacing": ".55pt" } }, [
            _vm._v("户被冻结等）或承"),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".5pt" } }, [
            _vm._v(
              "担您在使用过程中被限制使用所产生的损失，税安科技对此不承担"
            ),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".45pt" } }, [
            _vm._v("责任。"),
          ]),
        ]
      ),
      _c(
        "p",
        { staticClass: "MsoBodyText", staticStyle: { "line-height": "150%" } },
        [
          _c(
            "span",
            {
              staticStyle: { "letter-spacing": "-1.05pt" },
              attrs: { lang: "EN-US" },
            },
            [
              _vm._v("1.3.2"),
              _c("span", { staticStyle: { font: '7.0pt "Times New Roman"' } }, [
                _vm._v("        "),
              ]),
            ]
          ),
          _c("span", { staticStyle: { "letter-spacing": ".45pt" } }, [
            _vm._v(
              "您了解并同意：根据《互联网用户账号信息管理规定》及其他相关法律法规的规定，"
            ),
          ]),
          _c(
            "span",
            {
              staticStyle: { "letter-spacing": ".55pt" },
              attrs: { lang: "EN-US" },
            },
            [_vm._v("“")]
          ),
          _c("span", { staticStyle: {} }, [
            _vm._v("互联网信息服务提供者为互联网用户提供信息发布、"),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".6pt" } }, [
            _vm._v("即时通"),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".55pt" } }, [
            _vm._v(
              "讯等服务的，应当对申请注册相关账号信息的用户进行基于移动电话号码、"
            ),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".6pt" } }, [
            _vm._v("身份"),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".55pt" } }, [
            _vm._v("证件号码或者统一社会"),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".6pt" } }, [
            _vm._v("信用"),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".55pt" } }, [
            _vm._v(
              "代码等方式的真实身份信息认证。用户不提供真实身份信息，或者冒用组织机构、"
            ),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".6pt" } }, [
            _vm._v("他人身份信"),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".55pt" } }, [
            _vm._v("息进行虚假注册的，不得为其提供相关服务"),
            _c("span", { attrs: { lang: "EN-US" } }, [_vm._v("”")]),
            _vm._v("。为了更好地为您提"),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".5pt" } }, [
            _vm._v("供优质服务，保障您的数据安全，税安科技有权要求您在下单"),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".45pt" } }, [
            _vm._v("前核实用户信息；同时，"),
          ]),
          _c("span", { staticStyle: {} }, [_vm._v("税安科技")]),
          _c("span", { staticStyle: { "letter-spacing": ".55pt" } }, [
            _vm._v(
              "可能会就产品或服务的开通，要求您提供更多的身份资料和信息，做进一步的身份认证或资格验证。如您的"
            ),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".6pt" } }, [
            _vm._v("认证信"),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".55pt" } }, [
            _vm._v(
              "息经核实为虚假信息，税安科技有权清除虚假的认证信息，您需要重新进行认"
            ),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".6pt" } }, [
            _vm._v("证。"),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".55pt" } }, [
            _vm._v(
              "税安科技产品成功开通服务后，超过指定期限未进行用户认证的，您将无法继"
            ),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".6pt" } }, [
            _vm._v("续使"),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".55pt" } }, [
            _vm._v(
              "用，由此产生的责任将由您承担。除工商变更等特殊情况外，认证成功的企业"
            ),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".5pt" } }, [
            _vm._v("不支持修改或解绑认证"),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".45pt" } }, [
            _vm._v("信息。"),
          ]),
        ]
      ),
      _c(
        "p",
        { staticClass: "MsoBodyText", staticStyle: { "line-height": "150%" } },
        [
          _c(
            "span",
            {
              staticStyle: { "letter-spacing": "-1.05pt" },
              attrs: { lang: "EN-US" },
            },
            [
              _vm._v("1.3.3"),
              _c("span", { staticStyle: { font: '7.0pt "Times New Roman"' } }, [
                _vm._v("        "),
              ]),
            ]
          ),
          _c("span", { staticStyle: { "letter-spacing": ".5pt" } }, [
            _vm._v("您应当准确填写并及时更新您提供的联系电话、电子邮件地址"),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".55pt" } }, [
            _vm._v("等联系方式，以便交易平台及税安科技与您进行有效联系，"),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".5pt" } }, [
            _vm._v("若发生变更的，您应按"),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".55pt" } }, [
            _vm._v("税安科技的有关要求及时更新。如通过这些联系方"),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".5pt" } }, [
            _vm._v("式无法与您取得联系，导致您在使用税安科技产品过程中产生"),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".4pt" } }, [
            _vm._v("任何损失或增加费用的，应由您承担。"),
          ]),
        ]
      ),
      _c("p", { staticClass: "MsoBodyText", staticStyle: {} }, [
        _c(
          "span",
          {
            staticStyle: { "letter-spacing": "-.6pt" },
            attrs: { lang: "EN-US" },
          },
          [
            _vm._v("1.4"),
            _c("span", { staticStyle: { font: '7.0pt "Times New Roman"' } }, [
              _vm._v("   "),
            ]),
          ]
        ),
        _c(
          "span",
          {
            staticStyle: { "letter-spacing": ".35pt" },
            attrs: { lang: "EN-US" },
          },
          [_vm._v("产品许可")]
        ),
      ]),
      _c(
        "p",
        { staticClass: "MsoBodyText", staticStyle: { "line-height": "150%" } },
        [
          _c("span", { staticStyle: { "letter-spacing": ".65pt" } }, [
            _vm._v(
              "您了解并同意：本协议许可的仅是服务期限内的税安科技产品使用权"
            ),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".6pt" } }, [
            _vm._v("，该许可是附条件的、"),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".65pt" } }, [
            _vm._v(
              "非独家的、有期限的、不可转让的，许可使用的税安科技产品著作权"
            ),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".6pt" } }, [
            _vm._v("归属于税安科技所有，并受《中华人民共和国著作权法》、"),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".7pt" } }, [
            _vm._v("《计算机软件保护条例》和其他"),
          ]),
          _c("span", { staticStyle: {} }, [_vm._v("有关")]),
          _c("span", { staticStyle: { "letter-spacing": ".6pt" } }, [
            _vm._v("法律、"),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".65pt" } }, [
            _vm._v(
              "法规的保护。使用本协议产品时，您与您的使用人员需要在税安科技"
            ),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".6pt" } }, [
            _vm._v("平台进"),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".65pt" } }, [
            _vm._v(
              "行企业注册并完成用户认证以获得授权。用户认证成功后，您所购买的"
            ),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".6pt" } }, [
            _vm._v("税安科技产品"),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".65pt" } }, [
            _vm._v(
              "使用权将绑定至完成用户认证的企业或个人账户名下，未经税安科技同"
            ),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".6pt" } }, [
            _vm._v("意，不得私自转"),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".65pt" } }, [
            _vm._v(
              "售或转移税安科技产品使用权。税安科技有权对非经税安科技同意而转售"
            ),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".6pt" } }, [
            _vm._v("或转移税安科技产品"),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".65pt" } }, [
            _vm._v(
              "使用权的产品终止使用授权，并税安科技保留追究您的侵权责任和"
            ),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".6pt" } }, [
            _vm._v("要求您赔偿损失"),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".5pt" } }, [
            _vm._v("的权"),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".4pt" } }, [
            _vm._v("利。"),
          ]),
        ]
      ),
      _c("p", { staticClass: "MsoBodyText", staticStyle: {} }, [
        _c(
          "span",
          {
            staticStyle: { "letter-spacing": "-.6pt" },
            attrs: { lang: "EN-US" },
          },
          [
            _vm._v("1.5"),
            _c("span", { staticStyle: { font: '7.0pt "Times New Roman"' } }, [
              _vm._v("   "),
            ]),
          ]
        ),
        _c(
          "span",
          {
            staticStyle: { "letter-spacing": ".35pt" },
            attrs: { lang: "EN-US" },
          },
          [_vm._v("电商店铺")]
        ),
      ]),
      _c(
        "p",
        { staticClass: "MsoBodyText", staticStyle: { "line-height": "150%" } },
        [
          _c("span", { staticStyle: { "letter-spacing": ".7pt" } }, [
            _vm._v("您了解并同意"),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".65pt" } }, [
            _vm._v("：依据《中华人民共和国电子商务法》的规定"),
            _c("span", { attrs: { lang: "EN-US" } }, [_vm._v("“")]),
            _vm._v("电子商务经营者应当依"),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".45pt" } }, [
            _vm._v("法办理市场主体登记"),
            _c("span", { attrs: { lang: "EN-US" } }, [_vm._v("”")]),
            _vm._v("；如您购买涉及电商小程序的产品或模块，例如旺铺、"),
          ]),
          _c("span", { staticStyle: {} }, [_vm._v("微商")]),
          _c("span", { staticStyle: { "letter-spacing": ".75pt" } }, [
            _vm._v("城、"),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".65pt" } }, [
            _vm._v("智慧门店、"),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".7pt" } }, [
            _vm._v("订货商城等产品，"),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".65pt" } }, [
            _vm._v("在使用店铺小程序前需要完成企业实名认证，同时您需"),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".7pt" } }, [
            _vm._v("要确保提供"),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".65pt" } }, [
            _vm._v(
              "《商家隐私政策》以符合监管要求；与此同时，根据工业和信息化部发布"
            ),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".7pt" } }, [
            _vm._v("的《工业和"),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".65pt" } }, [
            _vm._v(
              "信息化部关于开展移动互联网应用程序备案工作的通知》，您需要履行所"
            ),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".5pt" } }, [
            _vm._v(
              "属小程序的备案手续，未履行备案手续所造成的相关后果和责任均由"
            ),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".45pt" } }, [
            _vm._v("您承担。"),
          ]),
        ]
      ),
      _c(
        "p",
        { staticClass: "MsoBodyText", staticStyle: { "line-height": "150%" } },
        [
          _c(
            "span",
            {
              staticStyle: { "letter-spacing": ".45pt" },
              attrs: { lang: "EN-US" },
            },
            [_vm._v(" ")]
          ),
        ]
      ),
      _c("p", { staticClass: "MsoBodyText", staticStyle: {} }, [
        _c("b", [
          _c(
            "span",
            {
              staticStyle: { "letter-spacing": "-1.1pt" },
              attrs: { lang: "EN-US" },
            },
            [
              _vm._v("2"),
              _c("span", { staticStyle: { font: '7.0pt "Times New Roman"' } }, [
                _vm._v("    "),
              ]),
            ]
          ),
        ]),
        _c("b", [
          _c(
            "span",
            {
              staticStyle: { "letter-spacing": ".2pt" },
              attrs: { lang: "EN-US" },
            },
            [_vm._v("服务")]
          ),
        ]),
      ]),
      _c(
        "p",
        { staticClass: "MsoBodyText", staticStyle: { "line-height": "150%" } },
        [
          _c(
            "span",
            {
              staticStyle: { "letter-spacing": "-.6pt" },
              attrs: { lang: "EN-US" },
            },
            [
              _vm._v("2.1"),
              _c("span", { staticStyle: { font: '7.0pt "Times New Roman"' } }, [
                _vm._v("   "),
              ]),
            ]
          ),
          _c("span", { staticStyle: { "letter-spacing": ".45pt" } }, [
            _vm._v("服务内容：本协议中"),
            _c("span", { attrs: { lang: "EN-US" } }, [_vm._v("“")]),
            _vm._v("服务"),
            _c("span", { attrs: { lang: "EN-US" } }, [_vm._v("”")]),
            _vm._v("指税安科技向您提供的交易平台上的产品服务，以及税安科技"),
          ]),
          _c("span", { staticStyle: {} }, [
            _vm._v("或税安科技的区域服务商为您提供的产品培训、"),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".7pt" } }, [
            _vm._v("实施服务和使用答"),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".65pt" } }, [
            _vm._v("疑。"),
          ]),
        ]
      ),
      _c("p", { staticClass: "MsoBodyText", staticStyle: {} }, [
        _c(
          "span",
          {
            staticStyle: { "letter-spacing": "-.6pt" },
            attrs: { lang: "EN-US" },
          },
          [
            _vm._v("2.2"),
            _c("span", { staticStyle: { font: '7.0pt "Times New Roman"' } }, [
              _vm._v("   "),
            ]),
          ]
        ),
        _c(
          "span",
          {
            staticStyle: { "letter-spacing": ".4pt" },
            attrs: { lang: "EN-US" },
          },
          [_vm._v("提供服务的主体及内容")]
        ),
      ]),
      _c(
        "p",
        { staticClass: "MsoBodyText", staticStyle: { "line-height": "150%" } },
        [
          _c(
            "span",
            {
              staticStyle: { "letter-spacing": ".5pt" },
              attrs: { lang: "EN-US" },
            },
            [
              _vm._v("2.2.1"),
              _c("span", { staticStyle: { font: '7.0pt "Times New Roman"' } }, [
                _vm._v("   "),
              ]),
            ]
          ),
          _c("span", { staticStyle: { "letter-spacing": ".5pt" } }, [
            _vm._v(
              "为了保障您能享受到全面的税安科技产品及咨询服务，在您购买并激活产品或服务后，由您的服务商为您提供产品培训、产品使用问题解答等服务，您可以在交易订单中查阅服务商信息。如您的服务商后续与税安科技终止合作或不具备相应授权服务资质时，税安科技将会为您提供新的服务商，以确保服务的连续性和完整性。如您没有指定的服务商，将由税安科技为您服务。"
            ),
          ]),
        ]
      ),
      _c(
        "p",
        { staticClass: "MsoBodyText", staticStyle: { "line-height": "150%" } },
        [
          _c(
            "span",
            {
              staticStyle: { "letter-spacing": ".5pt" },
              attrs: { lang: "EN-US" },
            },
            [
              _vm._v("2.2.2"),
              _c("span", { staticStyle: { font: '7.0pt "Times New Roman"' } }, [
                _vm._v("   "),
              ]),
            ]
          ),
          _c("span", { staticStyle: { "letter-spacing": ".5pt" } }, [
            _vm._v(
              "税安科技或您的服务商会在您使用的产品或服务不能满足您的需求时，为您推荐更优质的税安科技产品或服务，同时为您提供下单、使用、续费和增购等过程中的培训和答疑等在线服务。如您所购买的税安科技产品需要上门指导、现场实施维护等实施服务，由此产生的实施费用由税安科技或服务商收取，《实施服务协议》视具体情况由您与税安科技或服务商自主签署，并最终由服务商完成相关实施工作。"
            ),
          ]),
        ]
      ),
      _c("p", { staticClass: "MsoBodyText", staticStyle: {} }, [
        _c("b", [
          _c(
            "span",
            {
              staticStyle: { "letter-spacing": "-1.05pt" },
              attrs: { lang: "EN-US" },
            },
            [
              _vm._v("3"),
              _c("span", { staticStyle: { font: '7.0pt "Times New Roman"' } }, [
                _vm._v("    "),
              ]),
            ]
          ),
        ]),
        _c("b", [
          _c(
            "span",
            {
              staticStyle: { "letter-spacing": ".4pt" },
              attrs: { lang: "EN-US" },
            },
            [_vm._v("服务费用及发票")]
          ),
        ]),
      ]),
      _c(
        "p",
        { staticClass: "MsoBodyText", staticStyle: { "line-height": "150%" } },
        [
          _c(
            "span",
            {
              staticStyle: { "letter-spacing": "-.6pt" },
              attrs: { lang: "EN-US" },
            },
            [
              _vm._v("3.1"),
              _c("span", { staticStyle: { font: '7.0pt "Times New Roman"' } }, [
                _vm._v("   "),
              ]),
            ]
          ),
          _c("span", { staticStyle: { "letter-spacing": ".45pt" } }, [
            _vm._v(
              "产品及服务费用将在交易平台予以列明公示，您可自行选择具体类型并按列明的价格予"
            ),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".5pt" } }, [
            _vm._v("以支"),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".4pt" } }, [
            _vm._v("付。"),
          ]),
        ]
      ),
      _c(
        "p",
        { staticClass: "MsoBodyText", staticStyle: { "line-height": "150%" } },
        [
          _c(
            "span",
            {
              staticStyle: { "letter-spacing": ".45pt" },
              attrs: { lang: "EN-US" },
            },
            [
              _vm._v("3.2"),
              _c("span", { staticStyle: { font: '7.0pt "Times New Roman"' } }, [
                _vm._v("  "),
              ]),
            ]
          ),
          _c("span", { staticStyle: { "letter-spacing": ".45pt" } }, [
            _vm._v(
              "如您购买上门指导、现场实施维护等实施服务，费用标准以《实施服务协议》约定为准。"
            ),
          ]),
        ]
      ),
      _c(
        "p",
        { staticClass: "MsoBodyText", staticStyle: { "line-height": "150%" } },
        [
          _c(
            "span",
            {
              staticStyle: { "letter-spacing": "-.6pt" },
              attrs: { lang: "EN-US" },
            },
            [
              _vm._v("3.3"),
              _c("span", { staticStyle: { font: '7.0pt "Times New Roman"' } }, [
                _vm._v("   "),
              ]),
            ]
          ),
          _c("span", { staticStyle: { "letter-spacing": ".45pt" } }, [
            _vm._v(
              "我们仅支持预付费模式，在您成功付费后，我们将开始为您提供服务。若您下单后未在交易平台规定的时间（"
            ),
            _c("span", { attrs: { lang: "EN-US" } }, [_vm._v("48")]),
          ]),
          _c("span", {
            staticStyle: { "letter-spacing": "1.1pt" },
            attrs: { lang: "EN-US" },
          }),
          _c("span", { staticStyle: { "letter-spacing": ".45pt" } }, [
            _vm._v("小时）内付款，所生成的订单将自动失效。"),
          ]),
        ]
      ),
      _c(
        "p",
        { staticClass: "MsoBodyText", staticStyle: { "line-height": "150%" } },
        [
          _c(
            "span",
            {
              staticStyle: { "letter-spacing": "-.6pt" },
              attrs: { lang: "EN-US" },
            },
            [
              _vm._v("3.4"),
              _c("span", { staticStyle: { font: '7.0pt "Times New Roman"' } }, [
                _vm._v("   "),
              ]),
            ]
          ),
          _c("span", { staticStyle: { "letter-spacing": ".45pt" } }, [
            _vm._v("服务期满双方愿意继续合作的，建议您在服务期限届满前 "),
          ]),
          _c(
            "span",
            {
              staticStyle: { "letter-spacing": ".8pt" },
              attrs: { lang: "EN-US" },
            },
            [_vm._v("30")]
          ),
          _c("span", {
            staticStyle: { "letter-spacing": ".5pt" },
            attrs: { lang: "EN-US" },
          }),
          _c("span", { staticStyle: { "letter-spacing": ".45pt" } }, [
            _vm._v("日内续费，以使服务得以"),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".65pt" } }, [
            _vm._v("继续进行，服务期满后如未及时续费的，本协议项下服务自"),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".6pt" } }, [
            _vm._v("动终止。如续费时税安科技对产品体系、"),
          ]),
          _c("span", { staticStyle: {} }, [
            _vm._v("名称或价格有调整，您愿意继续使用相应服务的，应当按照"),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".6pt" } }, [
            _vm._v("税安科技届时有效的收"),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".5pt" } }, [
            _vm._v("费政"),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".45pt" } }, [
            _vm._v("策付费。"),
          ]),
        ]
      ),
      _c(
        "p",
        { staticClass: "MsoBodyText", staticStyle: { "line-height": "150%" } },
        [
          _c(
            "span",
            {
              staticStyle: { "letter-spacing": ".45pt" },
              attrs: { lang: "EN-US" },
            },
            [
              _vm._v("3.5"),
              _c("span", { staticStyle: { font: '7.0pt "Times New Roman"' } }, [
                _vm._v("  "),
              ]),
            ]
          ),
          _c("span", { staticStyle: { "letter-spacing": ".45pt" } }, [
            _vm._v(
              "您完全理解税安科技价格体系中所有的赠送服务项目或活动均为税安科技在正常服务价格之外的特别优惠，优惠内容不包括赠送服务项目的修改、更新及维护费用，并且赠送的服务项目不可折价、冲抵服务价格。"
            ),
          ]),
        ]
      ),
      _c(
        "p",
        { staticClass: "MsoBodyText", staticStyle: { "line-height": "150%" } },
        [
          _c("a", { attrs: { name: "OLE_LINK2" } }, [
            _c(
              "span",
              {
                staticStyle: { "letter-spacing": ".45pt" },
                attrs: { lang: "EN-US" },
              },
              [
                _vm._v("3.6"),
                _c(
                  "span",
                  { staticStyle: { font: '7.0pt "Times New Roman"' } },
                  [_vm._v("  ")]
                ),
              ]
            ),
            _c("span", { staticStyle: { "letter-spacing": ".45pt" } }, [
              _vm._v("关于退货和换货："),
            ]),
          ]),
          _c("a", { attrs: { name: "OLE_LINK6" } }, [
            _c("span", { staticStyle: { "letter-spacing": ".45pt" } }, [
              _vm._v(
                "税安科技所有商品不提供退换货服务，该规则已在订购流程中告知。"
              ),
            ]),
          ]),
        ]
      ),
      _c("p", { staticClass: "MsoBodyText", staticStyle: {} }, [
        _c("b", [
          _c(
            "span",
            {
              staticStyle: { "letter-spacing": "-1.05pt" },
              attrs: { lang: "EN-US" },
            },
            [
              _vm._v("4"),
              _c("span", { staticStyle: { font: '7.0pt "Times New Roman"' } }, [
                _vm._v("    "),
              ]),
            ]
          ),
        ]),
        _c("b", [
          _c(
            "span",
            {
              staticStyle: { "letter-spacing": ".4pt" },
              attrs: { lang: "EN-US" },
            },
            [_vm._v("双方的权利与义务")]
          ),
        ]),
      ]),
      _c("p", { staticClass: "MsoBodyText", staticStyle: {} }, [
        _c(
          "span",
          {
            staticStyle: { "letter-spacing": "-.6pt" },
            attrs: { lang: "EN-US" },
          },
          [
            _vm._v("4.1"),
            _c("span", { staticStyle: { font: '7.0pt "Times New Roman"' } }, [
              _vm._v("   "),
            ]),
          ]
        ),
        _c("span", { staticStyle: { "letter-spacing": ".4pt" } }, [
          _vm._v("税安科技的权利与义务："),
        ]),
      ]),
      _c(
        "p",
        { staticClass: "MsoBodyText", staticStyle: { "line-height": "150%" } },
        [
          _c(
            "span",
            {
              staticStyle: { "letter-spacing": ".5pt" },
              attrs: { lang: "EN-US" },
            },
            [
              _vm._v("4.1.1"),
              _c("span", { staticStyle: { font: '7.0pt "Times New Roman"' } }, [
                _vm._v("   "),
              ]),
            ]
          ),
          _c("span", { staticStyle: { "letter-spacing": ".5pt" } }, [
            _vm._v(
              "税安科技有权按照本协议的约定向您收取产品及服务费用，并在收到您支付的相关费用后提供相应的产品及服务。"
            ),
          ]),
        ]
      ),
      _c(
        "p",
        { staticClass: "MsoBodyText", staticStyle: { "line-height": "150%" } },
        [
          _c(
            "span",
            {
              staticStyle: { "letter-spacing": ".5pt" },
              attrs: { lang: "EN-US" },
            },
            [
              _vm._v("4.1.2"),
              _c("span", { staticStyle: { font: '7.0pt "Times New Roman"' } }, [
                _vm._v("   "),
              ]),
            ]
          ),
          _c("span", { staticStyle: { "letter-spacing": ".5pt" } }, [
            _vm._v(
              "为改善用户体验，完善服务内容，税安科技有权定期或不定期对产品、服务、平台、系统、软件等进行升级、维护优化等日程巡检及维护，在不影响您正常使用的情况下可能导致您所使用的服务短时间的中断或暂停。"
            ),
          ]),
        ]
      ),
      _c(
        "p",
        { staticClass: "MsoBodyText", staticStyle: { "line-height": "150%" } },
        [
          _c(
            "span",
            {
              staticStyle: { "letter-spacing": ".5pt" },
              attrs: { lang: "EN-US" },
            },
            [
              _vm._v("4.1.3"),
              _c("span", { staticStyle: { font: '7.0pt "Times New Roman"' } }, [
                _vm._v("   "),
              ]),
            ]
          ),
          _c("span", { staticStyle: { "letter-spacing": ".5pt" } }, [
            _vm._v(
              "若您的行为违反国家法律法规或者税安科技用户服务协议或有关规则，税安科技有权暂时或永久限制您账户下所使用的所有产品及"
            ),
            _c("span", { attrs: { lang: "EN-US" } }, [_vm._v("/")]),
            _vm._v("或服务的部分或全部功能。"),
          ]),
        ]
      ),
      _c("p", { staticClass: "MsoBodyText", staticStyle: {} }, [
        _c(
          "span",
          {
            staticStyle: { "letter-spacing": "-.6pt" },
            attrs: { lang: "EN-US" },
          },
          [
            _vm._v("4.2"),
            _c("span", { staticStyle: { font: '7.0pt "Times New Roman"' } }, [
              _vm._v("   "),
            ]),
          ]
        ),
        _c(
          "span",
          {
            staticStyle: { "letter-spacing": ".4pt" },
            attrs: { lang: "EN-US" },
          },
          [_vm._v("您的权利与义务：")]
        ),
      ]),
      _c(
        "p",
        { staticClass: "MsoBodyText", staticStyle: { "line-height": "150%" } },
        [
          _c(
            "span",
            {
              staticStyle: { "letter-spacing": ".5pt" },
              attrs: { lang: "EN-US" },
            },
            [
              _vm._v("4.2.1"),
              _c("span", { staticStyle: { font: '7.0pt "Times New Roman"' } }, [
                _vm._v("   "),
              ]),
            ]
          ),
          _c("span", { staticStyle: { "letter-spacing": ".5pt" } }, [
            _vm._v(
              "您有权在本协议约定的范围内使用税安科技产品及服务并及时支付费用。"
            ),
          ]),
        ]
      ),
      _c(
        "p",
        { staticClass: "MsoBodyText", staticStyle: { "line-height": "150%" } },
        [
          _c(
            "span",
            {
              staticStyle: { "letter-spacing": ".5pt" },
              attrs: { lang: "EN-US" },
            },
            [
              _vm._v("4.2.2"),
              _c("span", { staticStyle: { font: '7.0pt "Times New Roman"' } }, [
                _vm._v("   "),
              ]),
            ]
          ),
          _c("span", { staticStyle: { "letter-spacing": ".5pt" } }, [
            _vm._v("您有权在因税安科技原因造成产品连续"),
            _c("span", { attrs: { lang: "EN-US" } }, [_vm._v(" 72 ")]),
            _vm._v(
              "小时不能正常使用的情况下，要求终止本协议，但非因税安科技原因引起的使用异常情况除外。"
            ),
          ]),
        ]
      ),
      _c(
        "p",
        { staticClass: "MsoBodyText", staticStyle: { "line-height": "150%" } },
        [
          _c(
            "span",
            {
              staticStyle: { "letter-spacing": ".5pt" },
              attrs: { lang: "EN-US" },
            },
            [
              _vm._v("4.2.3"),
              _c("span", { staticStyle: { font: '7.0pt "Times New Roman"' } }, [
                _vm._v("   "),
              ]),
            ]
          ),
          _c("span", { staticStyle: { "letter-spacing": ".5pt" } }, [
            _vm._v(
              "您在购买前有义务在交易平台上对产品内容进行了解，并在此确认：您是在充分了解税安科技产品的相关功能以及其所能满足的需求的情形下，做出的购买决定，产品一经开通，即会产生相应的服务费用。"
            ),
          ]),
        ]
      ),
      _c(
        "p",
        { staticClass: "MsoBodyText", staticStyle: { "line-height": "150%" } },
        [
          _c("b", [
            _c(
              "span",
              {
                staticStyle: { "letter-spacing": ".5pt" },
                attrs: { lang: "EN-US" },
              },
              [
                _vm._v("4.2.4"),
                _c(
                  "span",
                  { staticStyle: { font: '7.0pt "Times New Roman"' } },
                  [_vm._v("   ")]
                ),
              ]
            ),
          ]),
          _c("b", [
            _c("span", { staticStyle: { "letter-spacing": ".5pt" } }, [
              _vm._v(
                "您有义务妥善保管税安科技相关的账号、口令、密码以及与此绑定的手机号码、手机验证码等有关信息，因您维护或保管不当致使上述账号、口令、密码等丢失或泄漏所引起的一切损失和后果均由您承担。"
              ),
            ]),
          ]),
        ]
      ),
      _c(
        "p",
        { staticClass: "MsoBodyText", staticStyle: { "line-height": "150%" } },
        [
          _c(
            "span",
            {
              staticStyle: { "letter-spacing": ".5pt" },
              attrs: { lang: "EN-US" },
            },
            [
              _vm._v("4.2.5"),
              _c("span", { staticStyle: { font: '7.0pt "Times New Roman"' } }, [
                _vm._v("   "),
              ]),
            ]
          ),
          _c("span", { staticStyle: { "letter-spacing": ".5pt" } }, [
            _vm._v(
              "若您发现他人冒用或盗用了您交易平台或者税安科技产品有关的账号、口令、密码等，您应立即以有效书面方式通知税安科技暂停相关交易和服务，同时您理解税安科技对您的请求采取行动需要合理的期限，在此之前，税安科技对已执行的指令及所导致的您的损失不承担任何责任。"
            ),
          ]),
        ]
      ),
      _c(
        "p",
        { staticClass: "MsoBodyText", staticStyle: { "line-height": "150%" } },
        [
          _c(
            "span",
            {
              staticStyle: { "letter-spacing": ".5pt" },
              attrs: { lang: "EN-US" },
            },
            [
              _vm._v("4.2.6"),
              _c("span", { staticStyle: { font: '7.0pt "Times New Roman"' } }, [
                _vm._v("   "),
              ]),
            ]
          ),
          _c("span", { staticStyle: { "letter-spacing": ".5pt" } }, [
            _vm._v(
              "您了解税安科技无法保证其所提供的服务毫无瑕疵，但税安科技承诺不断提升服务质量及服务水平。您同意：即使税安科技提供的服务存在瑕疵，但上述瑕疵是当时行业技术水平所无法避免的，其将不被视为税安科技违约。"
            ),
          ]),
        ]
      ),
      _c(
        "p",
        { staticClass: "MsoBodyText", staticStyle: { "line-height": "150%" } },
        [
          _c(
            "span",
            {
              staticStyle: { "letter-spacing": ".5pt" },
              attrs: { lang: "EN-US" },
            },
            [
              _vm._v("4.2.7"),
              _c("span", { staticStyle: { font: '7.0pt "Times New Roman"' } }, [
                _vm._v("   "),
              ]),
            ]
          ),
          _c("span", { staticStyle: { "letter-spacing": ".5pt" } }, [
            _vm._v(
              "您需要根据国家有关法律、行政法规、行政规章以及监管指令，认真执行网络安全、数据安全与隐私保护的相关管理规定，并不会利用税安科技提供的产品和服务进行存储、发布、传播如下信息和内容："
            ),
          ]),
        ]
      ),
      _c(
        "p",
        { staticClass: "MsoBodyText", staticStyle: { "line-height": "150%" } },
        [
          _c(
            "span",
            {
              staticStyle: { "letter-spacing": "-1.3pt" },
              attrs: { lang: "EN-US" },
            },
            [
              _vm._v("•"),
              _c("span", { staticStyle: { font: '7.0pt "Times New Roman"' } }, [
                _vm._v("         "),
              ]),
            ]
          ),
          _c("span", { staticStyle: { "letter-spacing": ".35pt" } }, [
            _vm._v("违反国家法律法规政策的任何内容和"),
            _c("span", { attrs: { lang: "EN-US" } }, [_vm._v("/")]),
            _vm._v("或信息；"),
          ]),
        ]
      ),
      _c(
        "p",
        { staticClass: "MsoBodyText", staticStyle: { "line-height": "150%" } },
        [
          _c(
            "span",
            {
              staticStyle: { "letter-spacing": "-1.3pt" },
              attrs: { lang: "EN-US" },
            },
            [
              _vm._v("•"),
              _c("span", { staticStyle: { font: '7.0pt "Times New Roman"' } }, [
                _vm._v("         "),
              ]),
            ]
          ),
          _c("span", { staticStyle: { "letter-spacing": ".3pt" } }, [
            _vm._v("违反国家规定的政治宣传和"),
            _c("span", { attrs: { lang: "EN-US" } }, [_vm._v("/")]),
            _vm._v("或新闻信息；"),
          ]),
        ]
      ),
      _c(
        "p",
        { staticClass: "MsoBodyText", staticStyle: { "line-height": "150%" } },
        [
          _c(
            "span",
            {
              staticStyle: { "letter-spacing": "-1.3pt" },
              attrs: { lang: "EN-US" },
            },
            [
              _vm._v("•"),
              _c("span", { staticStyle: { font: '7.0pt "Times New Roman"' } }, [
                _vm._v("         "),
              ]),
            ]
          ),
          _c("span", { staticStyle: { "letter-spacing": ".25pt" } }, [
            _vm._v("涉及国家秘密和"),
            _c("span", { attrs: { lang: "EN-US" } }, [_vm._v("/")]),
            _vm._v("或安全的信息；"),
          ]),
        ]
      ),
      _c(
        "p",
        { staticClass: "MsoBodyText", staticStyle: { "line-height": "150%" } },
        [
          _c(
            "span",
            {
              staticStyle: { "letter-spacing": "-1.3pt" },
              attrs: { lang: "EN-US" },
            },
            [
              _vm._v("•"),
              _c("span", { staticStyle: { font: '7.0pt "Times New Roman"' } }, [
                _vm._v("         "),
              ]),
            ]
          ),
          _c("span", { staticStyle: { "letter-spacing": ".35pt" } }, [
            _vm._v("封建迷信和"),
            _c("span", { attrs: { lang: "EN-US" } }, [_vm._v("/")]),
            _vm._v("或淫秽、色情信息、教唆犯罪的信息；"),
          ]),
        ]
      ),
      _c(
        "p",
        { staticClass: "MsoBodyText", staticStyle: { "line-height": "150%" } },
        [
          _c(
            "span",
            {
              staticStyle: { "letter-spacing": "-1.3pt" },
              attrs: { lang: "EN-US" },
            },
            [
              _vm._v("•"),
              _c("span", { staticStyle: { font: '7.0pt "Times New Roman"' } }, [
                _vm._v("         "),
              ]),
            ]
          ),
          _c(
            "span",
            {
              staticStyle: { "letter-spacing": ".4pt" },
              attrs: { lang: "EN-US" },
            },
            [_vm._v("博彩、赌博信息；")]
          ),
        ]
      ),
      _c(
        "p",
        { staticClass: "MsoBodyText", staticStyle: { "line-height": "150%" } },
        [
          _c(
            "span",
            {
              staticStyle: { "letter-spacing": "-1.3pt" },
              attrs: { lang: "EN-US" },
            },
            [
              _vm._v("•"),
              _c("span", { staticStyle: { font: '7.0pt "Times New Roman"' } }, [
                _vm._v("         "),
              ]),
            ]
          ),
          _c("span", { staticStyle: { "letter-spacing": ".45pt" } }, [
            _vm._v("违反国家民族和宗教政策的信息；"),
          ]),
        ]
      ),
      _c(
        "p",
        { staticClass: "MsoBodyText", staticStyle: { "line-height": "150%" } },
        [
          _c(
            "span",
            {
              staticStyle: { "letter-spacing": "-1.3pt" },
              attrs: { lang: "EN-US" },
            },
            [
              _vm._v("•"),
              _c("span", { staticStyle: { font: '7.0pt "Times New Roman"' } }, [
                _vm._v("         "),
              ]),
            ]
          ),
          _c("span", { staticStyle: { "letter-spacing": ".45pt" } }, [
            _vm._v("防碍互联网运行安全的信息；"),
          ]),
        ]
      ),
      _c(
        "p",
        { staticClass: "MsoBodyText", staticStyle: { "line-height": "150%" } },
        [
          _c(
            "span",
            {
              staticStyle: { "letter-spacing": "-1.3pt" },
              attrs: { lang: "EN-US" },
            },
            [
              _vm._v("•"),
              _c("span", { staticStyle: { font: '7.0pt "Times New Roman"' } }, [
                _vm._v("         "),
              ]),
            ]
          ),
          _c("span", { staticStyle: { "letter-spacing": ".35pt" } }, [
            _vm._v("侵害他人合法权益的信息和"),
            _c("span", { attrs: { lang: "EN-US" } }, [_vm._v("/")]),
            _vm._v("或其他有损于公序良俗的信息或内容；"),
          ]),
        ]
      ),
      _c(
        "p",
        { staticClass: "MsoBodyText", staticStyle: { "line-height": "150%" } },
        [
          _c(
            "span",
            {
              staticStyle: { "letter-spacing": "-1.3pt" },
              attrs: { lang: "EN-US" },
            },
            [
              _vm._v("•"),
              _c("span", { staticStyle: { font: '7.0pt "Times New Roman"' } }, [
                _vm._v("       "),
              ]),
            ]
          ),
          _c("span", { staticStyle: { "letter-spacing": ".45pt" } }, [
            _vm._v(
              "在产品内上传或使用未获得完全知识产权、所有权或者第三方合法授权的任何内容。您同时承诺不使用税安科技的产品、"
            ),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".55pt" } }, [
            _vm._v("服务为他人发布上述"),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".5pt" } }, [
            _vm._v("不符合国家规定和"),
            _c("span", { attrs: { lang: "EN-US" } }, [_vm._v("/")]),
            _vm._v("或本协议条款约定"),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".45pt" } }, [
            _vm._v("的信息内容提供任何便利。如您违反"),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".25pt" } }),
          _c("span", { staticStyle: {}, attrs: { lang: "EN-US" } }, [
            _vm._v("4.2.7"),
          ]),
          _c("span", {
            staticStyle: { "letter-spacing": ".25pt" },
            attrs: { lang: "EN-US" },
          }),
          _c("span", { staticStyle: { "letter-spacing": ".5pt" } }, [
            _vm._v("条项下内容，由此引起的一切法律责任与损失"),
          ]),
          _c("span", { staticStyle: {} }, [
            _vm._v(
              "均由您承担，同时税安科技有权终止对您的服务，如因此给税安科技"
            ),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".6pt" } }, [
            _vm._v("造成损失的，您承诺将承"),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".5pt" } }, [
            _vm._v("担全部赔"),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".45pt" } }, [
            _vm._v("偿责任。"),
          ]),
        ]
      ),
      _c(
        "p",
        { staticClass: "MsoNormal", staticStyle: { "line-height": "150%" } },
        [_c("span", { attrs: { lang: "EN-US" } }, [_vm._v(" ")])]
      ),
      _c(
        "p",
        { staticClass: "MsoBodyText", staticStyle: { "line-height": "150%" } },
        [
          _c("b", [
            _c(
              "span",
              {
                staticStyle: { "letter-spacing": ".5pt" },
                attrs: { lang: "EN-US" },
              },
              [
                _vm._v("4.2.8"),
                _c(
                  "span",
                  { staticStyle: { font: '7.0pt "Times New Roman"' } },
                  [_vm._v("   ")]
                ),
              ]
            ),
          ]),
          _c("b", [
            _c("span", { staticStyle: { "letter-spacing": ".5pt" } }, [
              _vm._v("如果购买"),
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v("-")]),
              _vm._v(
                "税安科技私有化部署产品、网络系统、应用程序的，需由您进行管理和运营，同时您理解并同意如下："
              ),
            ]),
          ]),
        ]
      ),
      _c(
        "p",
        { staticClass: "MsoBodyText", staticStyle: { "line-height": "150%" } },
        [
          _c("b", [
            _c(
              "span",
              {
                staticStyle: { "letter-spacing": "-.85pt" },
                attrs: { lang: "EN-US" },
              },
              [
                _vm._v("4.2.8.1"),
                _c(
                  "span",
                  { staticStyle: { font: '7.0pt "Times New Roman"' } },
                  [_vm._v("   ")]
                ),
              ]
            ),
          ]),
          _c("b", [
            _c("span", { staticStyle: { "letter-spacing": ".55pt" } }, [
              _vm._v(
                "您需要根据自身所处理数据和业务系统的安全要求选择、配置和使用必要的安全防护设备及措施。您知悉并确认，使用安全服务是提升计算机信息系统、"
              ),
            ]),
          ]),
          _c("b", [
            _c("span", { staticStyle: { "letter-spacing": ".8pt" } }, [
              _vm._v("设备"),
            ]),
          ]),
          _c("b", [
            _c("span", { staticStyle: { "letter-spacing": ".75pt" } }, [
              _vm._v("安全性的必要"),
            ]),
          ]),
          _c("b", [
            _c("span", { staticStyle: { "letter-spacing": ".6pt" } }, [
              _vm._v(
                "措施，您可根据业务需要自行购买税安科技提供的安全产品及服务，"
              ),
              _c("a", { attrs: { name: "OLE_LINK3" } }, [_vm._v("税安科技")]),
              _vm._v("将按"),
            ]),
          ]),
          _c("b", [
            _c("span", { staticStyle: { "letter-spacing": ".55pt" } }, [
              _vm._v("照本协议中"),
            ]),
          ]),
          _c("b", [
            _c("span", { staticStyle: {} }, [
              _vm._v(
                "您所购买的安全产品的相关功能向您提供安全保护服务，但您仍需要结合产品"
              ),
            ]),
          ]),
          _c("b", [_c("span", { staticStyle: {} }, [_vm._v("的使用情况")])]),
          _c("b", [
            _c("span", { staticStyle: { "letter-spacing": ".6pt" } }, [
              _vm._v(
                "自行判断安全保护服务是否符合自身数据和业务系统安全性和合规性要求。由"
              ),
            ]),
          ]),
          _c("b", [
            _c("span", { staticStyle: { "letter-spacing": ".55pt" } }, [
              _vm._v("于未进行合"),
            ]),
          ]),
          _c("b", [
            _c("span", { staticStyle: {} }, [_vm._v("理的安全配置导")]),
          ]),
          _c("b", [
            _c("span", { staticStyle: { "letter-spacing": ".45pt" } }, [
              _vm._v("致的网络及数据安全风险由您负责。"),
            ]),
          ]),
        ]
      ),
      _c(
        "p",
        { staticClass: "MsoBodyText", staticStyle: { "line-height": "150%" } },
        [
          _c("b", [
            _c(
              "span",
              {
                staticStyle: { "letter-spacing": "-.85pt" },
                attrs: { lang: "EN-US" },
              },
              [
                _vm._v("4.2.8.2"),
                _c(
                  "span",
                  { staticStyle: { font: '7.0pt "Times New Roman"' } },
                  [_vm._v("   ")]
                ),
              ]
            ),
          ]),
          _c("b", [
            _c("span", { staticStyle: { "letter-spacing": ".4pt" } }, [
              _vm._v(
                "您需要根据国家有关法律、行政法规、行政规章以及监管指令，认真执行网络安全、"
              ),
            ]),
          ]),
          _c("b", [
            _c("span", { staticStyle: { "letter-spacing": ".65pt" } }, [
              _vm._v("数据安全与隐私"),
            ]),
          ]),
          _c("b", [
            _c("span", { staticStyle: {} }, [
              _vm._v(
                "保护的相关管理规定；您有义务采取适当的安全措施，以保护该等产品"
              ),
            ]),
          ]),
          _c("b", [
            _c("span", { staticStyle: {} }, [
              _vm._v("运行环境所在计算机"),
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v("/")]),
              _vm._v("服务器、"),
            ]),
          ]),
          _c("b", [
            _c("span", { staticStyle: { "letter-spacing": ".5pt" } }, [
              _vm._v("网络、数据存储的安全。因此您在"),
            ]),
          ]),
          _c("b", [
            _c("span", { staticStyle: { "letter-spacing": ".45pt" } }, [
              _vm._v("使用该等产品时，需要"),
            ]),
          ]),
          _c("b", [
            _c("span", { staticStyle: {} }, [_vm._v("采用及时更新产品补丁、")]),
          ]),
          _c("b", [
            _c("span", { staticStyle: { "letter-spacing": ".65pt" } }, [
              _vm._v("数据异地备份、"),
            ]),
          ]),
          _c("b", [
            _c("span", { staticStyle: { "letter-spacing": ".6pt" } }, [
              _vm._v("设置强密码、安装杀毒软件和防火墙等必要的安"),
            ]),
          ]),
          _c("b", [
            _vm._v("全措"),
            _c("span", { staticStyle: { "letter-spacing": ".55pt" } }),
            _vm._v("施"),
            _c("span", { staticStyle: { "letter-spacing": ".9pt" } }, [
              _vm._v("。如因您未采取上"),
            ]),
            _c("span", { staticStyle: { "letter-spacing": ".85pt" } }, [
              _vm._v("述措施，导致遭受"),
            ]),
            _c("span", { staticStyle: { "letter-spacing": ".45pt" } }, [
              _vm._v("网络攻击、"),
            ]),
            _c("span", { staticStyle: { "letter-spacing": ".5pt" } }, [
              _vm._v("黑客入侵、中病毒等"),
            ]),
            _c("span", { staticStyle: { "letter-spacing": ".45pt" } }, [
              _vm._v("事件以及产生的任何损失由您承担。"),
            ]),
          ]),
        ]
      ),
      _c(
        "p",
        { staticClass: "MsoBodyText", staticStyle: { "line-height": "150%" } },
        [
          _c("b", [
            _c(
              "span",
              {
                staticStyle: { "letter-spacing": ".5pt" },
                attrs: { lang: "EN-US" },
              },
              [
                _vm._v("4.2.9"),
                _c(
                  "span",
                  { staticStyle: { font: '7.0pt "Times New Roman"' } },
                  [_vm._v("   ")]
                ),
              ]
            ),
          ]),
          _c("b", [
            _c(
              "span",
              {
                staticStyle: { "letter-spacing": ".5pt" },
                attrs: { lang: "EN-US" },
              },
              [_vm._v("SaaS ")]
            ),
          ]),
          _c("b", [
            _c("span", { staticStyle: { "letter-spacing": ".5pt" } }, [
              _vm._v(
                "产品及服务的使用您需要查看其相应配置文档，以了解可自定义的安全配置，并做出符合自身数据和业务系统安全性和合规性要求的配置选择。由于未进行合理的安全配置导致的网络及数据安全风险由您负责。"
              ),
            ]),
          ]),
        ]
      ),
      _c(
        "p",
        { staticClass: "MsoBodyText", staticStyle: { "line-height": "150%" } },
        [
          _c("b", [
            _c(
              "span",
              {
                staticStyle: { "letter-spacing": ".5pt" },
                attrs: { lang: "EN-US" },
              },
              [
                _vm._v("4.2.10"),
                _c(
                  "span",
                  { staticStyle: { font: '7.0pt "Times New Roman"' } },
                  [_vm._v("  ")]
                ),
              ]
            ),
          ]),
          _c("b", [
            _c("span", { staticStyle: { "letter-spacing": ".5pt" } }, [
              _vm._v(
                "您有义务承诺遵守《中华人民共和国网络安全法》、《中华人民共和国著作权法》等相关法律法规的规定，不支持、不参与、不使用盗版产品，包括但不限于未经授权安装或使用产品、破解或修改产品、共享或传播盗版产品、提供盗版产品销售渠道或服务等。盗版产品不享受正版产品的更新和维护服务，可能存在漏洞和病毒等安全风险，数据安全将无法得到保障。税安科技有权不为盗版产品提供服务，并保留追究侵权者的法律责任。"
              ),
            ]),
          ]),
        ]
      ),
      _c("p", { staticClass: "MsoBodyText", staticStyle: {} }, [
        _c("b", [
          _c(
            "span",
            {
              staticStyle: { "letter-spacing": "-1.05pt" },
              attrs: { lang: "EN-US" },
            },
            [
              _vm._v("5"),
              _c("span", { staticStyle: { font: '7.0pt "Times New Roman"' } }, [
                _vm._v("    "),
              ]),
            ]
          ),
        ]),
        _c("b", [
          _c(
            "span",
            {
              staticStyle: { "letter-spacing": ".35pt" },
              attrs: { lang: "EN-US" },
            },
            [_vm._v("业务数据")]
          ),
        ]),
      ]),
      _c(
        "p",
        { staticClass: "MsoBodyText", staticStyle: { "line-height": "150%" } },
        [
          _c(
            "span",
            {
              staticStyle: { "letter-spacing": "-.6pt" },
              attrs: { lang: "EN-US" },
            },
            [
              _vm._v("5.1"),
              _c("span", { staticStyle: { font: '7.0pt "Times New Roman"' } }, [
                _vm._v("   "),
              ]),
            ]
          ),
          _c("span", { staticStyle: { "letter-spacing": ".45pt" } }, [
            _vm._v(
              "税安科技理解并认可，您通过税安科技提供的服务，加工、存储、上传、下载、分发以及通"
            ),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".5pt" } }, [
            _vm._v(
              "过其他方式处理的数据，均为您的用户业务数据，您完全拥有您的用户业"
            ),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".45pt" } }, [
            _vm._v("务数据。"),
          ]),
        ]
      ),
      _c(
        "p",
        { staticClass: "MsoBodyText", staticStyle: { "line-height": "150%" } },
        [
          _c(
            "span",
            {
              staticStyle: { "letter-spacing": "-.6pt" },
              attrs: { lang: "EN-US" },
            },
            [
              _vm._v("5.2"),
              _c("span", { staticStyle: { font: '7.0pt "Times New Roman"' } }, [
                _vm._v("   "),
              ]),
            ]
          ),
          _c("b", [
            _c("span", { staticStyle: { "letter-spacing": ".45pt" } }, [
              _vm._v(
                "对于您的用户业务数据，税安科技除执行您的服务要求外，不进行任何未获授权的使用及"
              ),
            ]),
          ]),
          _c("b", [
            _c("span", { staticStyle: { "letter-spacing": ".5pt" } }, [
              _vm._v("披露"),
            ]),
          ]),
          _c("b", [
            _c("span", { staticStyle: { "letter-spacing": ".45pt" } }, [
              _vm._v("，但以下情形除外："),
            ]),
          ]),
        ]
      ),
      _c(
        "p",
        { staticClass: "MsoBodyText", staticStyle: { "line-height": "150%" } },
        [
          _c("b", [
            _c(
              "span",
              {
                staticStyle: { "letter-spacing": ".5pt" },
                attrs: { lang: "EN-US" },
              },
              [
                _vm._v("5.2.1"),
                _c(
                  "span",
                  { staticStyle: { font: '7.0pt "Times New Roman"' } },
                  [_vm._v("   ")]
                ),
              ]
            ),
          ]),
          _c("b", [
            _c("span", { staticStyle: { "letter-spacing": ".5pt" } }, [
              _vm._v(
                "在获取同意的情况下转让：税安科技在获得您的同意后，向其他方（包括但不限于税安科技的合作伙伴、征信公司等）转让您的用户与业务数据；"
              ),
            ]),
          ]),
        ]
      ),
      _c(
        "p",
        { staticClass: "MsoBodyText", staticStyle: { "line-height": "150%" } },
        [
          _c("b", [
            _c(
              "span",
              {
                staticStyle: { "letter-spacing": ".5pt" },
                attrs: { lang: "EN-US" },
              },
              [
                _vm._v("5.2.2"),
                _c(
                  "span",
                  { staticStyle: { font: '7.0pt "Times New Roman"' } },
                  [_vm._v("   ")]
                ),
              ]
            ),
          ]),
          _c("b", [
            _c("span", { staticStyle: { "letter-spacing": ".5pt" } }, [
              _vm._v("您和税安科技另行协商一致；"),
            ]),
          ]),
        ]
      ),
      _c(
        "p",
        { staticClass: "MsoBodyText", staticStyle: { "line-height": "150%" } },
        [
          _c("b", [
            _c(
              "span",
              {
                staticStyle: { "letter-spacing": ".5pt" },
                attrs: { lang: "EN-US" },
              },
              [
                _vm._v("5.2.3"),
                _c(
                  "span",
                  { staticStyle: { font: '7.0pt "Times New Roman"' } },
                  [_vm._v("   ")]
                ),
              ]
            ),
          ]),
          _c("b", [
            _c("span", { staticStyle: { "letter-spacing": ".5pt" } }, [
              _vm._v("税安科技按照相关法律法规或政策要求提供。"),
            ]),
          ]),
        ]
      ),
      _c(
        "p",
        { staticClass: "MsoBodyText", staticStyle: { "line-height": "150%" } },
        [
          _c(
            "span",
            {
              staticStyle: { "letter-spacing": "-.6pt" },
              attrs: { lang: "EN-US" },
            },
            [
              _vm._v("5.3"),
              _c("span", { staticStyle: { font: '7.0pt "Times New Roman"' } }, [
                _vm._v("   "),
              ]),
            ]
          ),
          _c("span", { staticStyle: { "letter-spacing": ".45pt" } }, [
            _vm._v(
              "您可自行对您的用户业务数据进行修改、删除等操作。如您自行修改或删除数据的，系"
            ),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".6pt" } }, [
            _vm._v("统将自动按照您的指令修改或删除您的数据。数据的修改、"),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".75pt" } }, [
            _vm._v("删除等操作可能"),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".65pt" } }, [
            _vm._v("导致数据变更"),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".5pt" } }, [
            _vm._v("或无法恢复，您应谨慎"),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".45pt" } }, [
            _vm._v("操作。"),
          ]),
        ]
      ),
      _c(
        "p",
        { staticClass: "MsoBodyText", staticStyle: { "line-height": "150%" } },
        [
          _c(
            "span",
            {
              staticStyle: { "letter-spacing": "-.6pt" },
              attrs: { lang: "EN-US" },
            },
            [
              _vm._v("5.4"),
              _c("span", { staticStyle: { font: '7.0pt "Times New Roman"' } }, [
                _vm._v("   "),
              ]),
            ]
          ),
          _c("span", { staticStyle: { "letter-spacing": ".5pt" } }, [
            _vm._v(
              "如服务期限届满且您不再续费致使服务终止的（含由于您违反本协议致使服务提前终止"
            ),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".6pt" } }, [
            _vm._v("等的情形）"),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".65pt" } }, [
            _vm._v("，税安科技建议您提前"),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".35pt" } }),
          _c("span", { staticStyle: {}, attrs: { lang: "EN-US" } }, [
            _vm._v("15"),
          ]),
          _c("span", {
            staticStyle: { "letter-spacing": ".35pt" },
            attrs: { lang: "EN-US" },
          }),
          _c("span", { staticStyle: { "letter-spacing": ".65pt" } }, [
            _vm._v("个自然日将您在使用税安科技产品及服务所形成的相"),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".7pt" } }, [
            _vm._v("关数据迁移到本地。前述期限届满后，您未"),
          ]),
          _c("span", { staticStyle: {} }, [
            _vm._v("将数据迁移到本地的，即视为您不需要上述数"),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".7pt" } }, [
            _vm._v("据，税安科技将有权删除所有您在使用税安科技"),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".6pt" } }, [
            _vm._v("产品及服务过程中所形成的相关数据，包括"),
          ]),
          _c("span", { staticStyle: {} }, [
            _vm._v("但不限于所有系统缓存或者备份的副本，不再保留您的"),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".45pt" } }, [
            _vm._v("任何数据资料，且不承担任何责任。"),
          ]),
        ]
      ),
      _c(
        "p",
        { staticClass: "MsoBodyText", staticStyle: { "line-height": "150%" } },
        [
          _c(
            "span",
            {
              staticStyle: { "letter-spacing": "-.6pt" },
              attrs: { lang: "EN-US" },
            },
            [
              _vm._v("5.5"),
              _c("span", { staticStyle: { font: '7.0pt "Times New Roman"' } }, [
                _vm._v("   "),
              ]),
            ]
          ),
          _c("span", { staticStyle: { "letter-spacing": ".2pt" } }, [
            _vm._v("数据一经删除，即不可恢复，您应承担因上述 "),
          ]),
          _c(
            "span",
            {
              staticStyle: { "letter-spacing": ".25pt" },
              attrs: { lang: "EN-US" },
            },
            [_vm._v("5.3")]
          ),
          _c("span", {
            staticStyle: { "letter-spacing": ".2pt" },
            attrs: { lang: "EN-US" },
          }),
          _c("span", { staticStyle: { "letter-spacing": ".3pt" } }, [
            _vm._v("和"),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".2pt" } }),
          _c("span", { staticStyle: {}, attrs: { lang: "EN-US" } }, [
            _vm._v("5.4"),
          ]),
          _c("span", {
            staticStyle: { "letter-spacing": ".2pt" },
            attrs: { lang: "EN-US" },
          }),
          _c("span", { staticStyle: { "letter-spacing": ".2pt" } }, [
            _vm._v("条款数据删除所引发的所有后"),
          ]),
          _c("span", { staticStyle: {} }, [
            _vm._v("果和责任。您理解并同意，税安科技没有继续保留、"),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".6pt" } }, [
            _vm._v("导出"),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".55pt" } }, [
            _vm._v("或者返还用户业务数据的义务。"),
          ]),
        ]
      ),
      _c(
        "p",
        { staticClass: "MsoBodyText", staticStyle: { "line-height": "150%" } },
        [
          _c(
            "span",
            {
              staticStyle: { "letter-spacing": "-.6pt" },
              attrs: { lang: "EN-US" },
            },
            [
              _vm._v("5.6"),
              _c("span", { staticStyle: { font: '7.0pt "Times New Roman"' } }, [
                _vm._v("   "),
              ]),
            ]
          ),
          _c("b", [
            _c("span", { staticStyle: { "letter-spacing": ".45pt" } }, [
              _vm._v(
                "您理解并认可，根据适用的法律，若税安科技共享、转让经匿名化处理的和采取其他必要"
              ),
            ]),
          ]),
          _c("b", [
            _c("span", { staticStyle: { "letter-spacing": ".6pt" } }, [
              _vm._v(
                "措施进行处理的业务信息，确保数据接收方无法重新识别特定用户且不能复原"
              ),
            ]),
          ]),
          _c("b", [
            _c("span", { staticStyle: { "letter-spacing": ".55pt" } }, [
              _vm._v("（包括并不"),
            ]),
          ]),
          _c("b", [
            _c("span", { staticStyle: {} }, [
              _vm._v("限于模块功能的使用频率、"),
            ]),
          ]),
          _c("b", [
            _c("span", { staticStyle: { "letter-spacing": ".5pt" } }, [
              _vm._v("财务"),
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v("/")]),
            ]),
          ]),
          _c("b", [
            _c("span", { staticStyle: { "letter-spacing": ".45pt" } }, [
              _vm._v("业务等经营指标，以及公开的图像、文字、视频、音乐、"),
            ]),
          ]),
          _c("b", [
            _c("span", { staticStyle: { "letter-spacing": ".55pt" } }, [
              _vm._v("附加程序、"),
            ]),
          ]),
          _c("b", [
            _c("span", { staticStyle: {} }, [
              _vm._v("店铺装修等信息），或税安科技可能会对"),
            ]),
          ]),
          _c("b", [
            _c("span", { staticStyle: { "letter-spacing": ".55pt" } }, [
              _vm._v("收集的业务信息进行匿名化地研究、分"),
            ]),
          ]),
          _c("b", [
            _c("span", { staticStyle: { "letter-spacing": ".5pt" } }, [
              _vm._v("析和预"),
            ]),
          ]),
          _c("b", [
            _c("span", { staticStyle: { "letter-spacing": ".45pt" } }, [
              _vm._v(
                "测，用于改善税安科技产品的内容和布局，为您的商业决策提供产品及"
              ),
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v("/")]),
              _vm._v("或服务支撑，以及改进税安科技的产品及"),
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v("/")]),
              _vm._v("或服务（包括使用匿名数据进行机器学习或模型算法训练、"),
            ]),
          ]),
          _c("b", [
            _c("span", { staticStyle: { "letter-spacing": ".5pt" } }, [
              _vm._v("模拟"),
            ]),
          ]),
          _c("b", [
            _c("span", { staticStyle: { "letter-spacing": ".55pt" } }, [
              _vm._v("重现产品问题并处理）"),
            ]),
          ]),
          _c("b", [
            _c("span", { staticStyle: { "letter-spacing": ".65pt" } }, [
              _vm._v("，使用在税安科技"),
            ]),
          ]),
          _c("b", [
            _c("span", { staticStyle: {} }, [
              _vm._v("产品增值或免费的数据、内容等功能服务中，不属"),
            ]),
          ]),
          _c("b", [
            _c("span", { staticStyle: { "letter-spacing": ".55pt" } }, [
              _vm._v("于个人信息的对外委托处理、"),
            ]),
          ]),
          _c("b", [
            _c("span", { staticStyle: { "letter-spacing": ".65pt" } }, [
              _vm._v("共享、转让以及公"),
            ]),
          ]),
          _c("b", [
            _c("span", { staticStyle: {} }, [
              _vm._v("开披露行为，无需另行再次向您通知并征"),
            ]),
          ]),
          _c("b", [
            _c("span", { staticStyle: { "letter-spacing": ".5pt" } }, [
              _vm._v("得您的"),
            ]),
          ]),
          _c("b", [
            _c("span", { staticStyle: { "letter-spacing": ".4pt" } }, [
              _vm._v("同意。"),
            ]),
          ]),
        ]
      ),
      _c(
        "p",
        { staticClass: "MsoBodyText", staticStyle: { "line-height": "150%" } },
        [
          _c(
            "span",
            {
              staticStyle: { "letter-spacing": "-.6pt" },
              attrs: { lang: "EN-US" },
            },
            [
              _vm._v("5.7"),
              _c("span", { staticStyle: { font: '7.0pt "Times New Roman"' } }, [
                _vm._v("   "),
              ]),
            ]
          ),
          _c("span", { staticStyle: { "letter-spacing": ".45pt" } }, [
            _vm._v(
              "您应对您的用户业务数据的来源及内容负责，税安科技提示您谨慎判断数据来源及内容的"
            ),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".6pt" } }, [
            _vm._v("合法性。您将承担因您的用户业务数据内容违反法律法规、"),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".75pt" } }, [
            _vm._v("部门规章或国家"),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".65pt" } }, [
            _vm._v("政策而造成的"),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".5pt" } }, [
            _vm._v("相应结果"),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".45pt" } }, [
            _vm._v("及责任。"),
          ]),
        ]
      ),
      _c(
        "p",
        { staticClass: "MsoBodyText", staticStyle: { "line-height": "150%" } },
        [
          _c("span", { staticStyle: { "letter-spacing": ".45pt" } }, [
            _vm._v(
              "如您基于自身业务目的需要选择在中华人民共和国境外的数据中心储存并处理业务数据，"
            ),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".5pt" } }, [
            _vm._v("可能会导致您的用户业务数据出境。您作为"),
          ]),
          _c("span", { staticStyle: {} }, [
            _vm._v("用户业务数据的完全控制方应履行相应的数据"),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".5pt" } }, [
            _vm._v(
              "出境合规义务，包括但不限于完成数据出境申报，与境外数据接收方签署"
            ),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".45pt" } }, [
            _vm._v("协议等。"),
          ]),
        ]
      ),
      _c("p", { staticClass: "MsoBodyText", staticStyle: {} }, [
        _c(
          "span",
          {
            staticStyle: { "letter-spacing": "-1.05pt" },
            attrs: { lang: "EN-US" },
          },
          [
            _vm._v("6"),
            _c("span", { staticStyle: { font: '7.0pt "Times New Roman"' } }, [
              _vm._v("     "),
            ]),
          ]
        ),
        _c(
          "span",
          {
            staticStyle: { "letter-spacing": ".35pt" },
            attrs: { lang: "EN-US" },
          },
          [_vm._v("知识产权")]
        ),
      ]),
      _c(
        "p",
        { staticClass: "MsoBodyText", staticStyle: { "line-height": "150%" } },
        [
          _c(
            "span",
            {
              staticStyle: { "letter-spacing": "-.6pt" },
              attrs: { lang: "EN-US" },
            },
            [
              _vm._v("6.1"),
              _c("span", { staticStyle: { font: '7.0pt "Times New Roman"' } }, [
                _vm._v("   "),
              ]),
            ]
          ),
          _c("span", { staticStyle: { "letter-spacing": ".45pt" } }, [
            _vm._v(
              "一方向另一方提供的任何资料、技术或技术支持、软件、服务等的知识产权均属于提供"
            ),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".6pt" } }, [
            _vm._v(
              "一方或相应权利人所有；除提供方或相应权利人明示同意外，另一方无权复制、"
            ),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".95pt" } }, [
            _vm._v("传播"),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".9pt" } }, [
            _vm._v("、转"),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".45pt" } }, [
            _vm._v("让、"),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".55pt" } }, [
            _vm._v("许可或提供他人使用上述知识"),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".45pt" } }, [
            _vm._v("成果，否则应承担相应的责任。"),
          ]),
        ]
      ),
      _c(
        "p",
        { staticClass: "MsoBodyText", staticStyle: { "line-height": "150%" } },
        [
          _c(
            "span",
            {
              staticStyle: { "letter-spacing": "-.6pt" },
              attrs: { lang: "EN-US" },
            },
            [
              _vm._v("6.2"),
              _c("span", { staticStyle: { font: '7.0pt "Times New Roman"' } }, [
                _vm._v("   "),
              ]),
            ]
          ),
          _c("span", { staticStyle: { "letter-spacing": ".45pt" } }, [
            _vm._v(
              "您应保证提交给税安科技的材料、对税安科技服务的使用及使用税安科技服务所产生的成果未"
            ),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".6pt" } }, [
            _vm._v("侵犯任何第三方的合法权益；否则因此引起的索赔、"),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".7pt" } }, [
            _vm._v("诉讼等，您应负责解决"),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".65pt" } }, [
            _vm._v("，并承担相应"),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".5pt" } }, [
            _vm._v("损失"),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".45pt" } }, [
            _vm._v("和费用。"),
          ]),
        ]
      ),
      _c(
        "p",
        { staticClass: "MsoBodyText", staticStyle: { "line-height": "150%" } },
        [
          _c(
            "span",
            {
              staticStyle: { "letter-spacing": "-.6pt" },
              attrs: { lang: "EN-US" },
            },
            [
              _vm._v("6.3"),
              _c("span", { staticStyle: { font: '7.0pt "Times New Roman"' } }, [
                _vm._v("   "),
              ]),
            ]
          ),
          _c("span", { staticStyle: { "letter-spacing": ".45pt" } }, [
            _vm._v(
              "我们尊重知识产权，除非获得了您的同意或本协议另有约定，税安科技不会擅自使用、修改、"
            ),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".55pt" } }, [
            _vm._v("复制、改变或公开发表"),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".45pt" } }, [
            _vm._v("您拥有知识产权的成果。"),
          ]),
        ]
      ),
      _c(
        "p",
        { staticClass: "MsoBodyText", staticStyle: { "line-height": "150%" } },
        [
          _c(
            "span",
            {
              staticStyle: { "letter-spacing": "-.6pt" },
              attrs: { lang: "EN-US" },
            },
            [
              _vm._v("6.4"),
              _c("span", { staticStyle: { font: '7.0pt "Times New Roman"' } }, [
                _vm._v("   "),
              ]),
            ]
          ),
          _c("span", { staticStyle: { "letter-spacing": ".45pt" } }, [
            _vm._v("除非另行说明，税安科技网站"),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".95pt" } }),
          _c("span", { staticStyle: {}, attrs: { lang: "EN-US" } }, [
            _vm._v("Logo"),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".4pt" } }, [
            _vm._v("、"),
            _c("span", { attrs: { lang: "EN-US" } }, [_vm._v("“")]),
            _vm._v("税安科技"),
            _c("span", { attrs: { lang: "EN-US" } }, [_vm._v("”")]),
            _vm._v("等文字、图形及其组合，"),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".65pt" } }, [
            _vm._v("以及税安科技网站的其他标识等的知识产权为税安科技"),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".6pt" } }, [
            _vm._v("所有。"),
            _c("span", { attrs: { lang: "EN-US" } }, [_vm._v("“")]),
            _vm._v("知识产权"),
            _c("span", { attrs: { lang: "EN-US" } }, [_vm._v("”")]),
            _vm._v("包括在专利法、"),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".65pt" } }, [
            _vm._v("著作权法、商标法、反不正当竞争法等法律中规定的所有权"),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".6pt" } }, [
            _vm._v("利和利益，以及其"),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".45pt" } }, [
            _vm._v("中的所有应用、"),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".55pt" } }, [
            _vm._v("更新、"),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".5pt" } }, [
            _vm._v("扩展和恢复。"),
          ]),
        ]
      ),
      _c(
        "p",
        { staticClass: "MsoBodyText", staticStyle: { "line-height": "150%" } },
        [
          _c(
            "span",
            {
              staticStyle: { "letter-spacing": "-.6pt" },
              attrs: { lang: "EN-US" },
            },
            [
              _vm._v("6.5"),
              _c("span", { staticStyle: { font: '7.0pt "Times New Roman"' } }, [
                _vm._v("   "),
              ]),
            ]
          ),
          _c("span", { staticStyle: { "letter-spacing": ".45pt" } }, [
            _vm._v(
              "您不得攻击税安科技官网，修改、改编、翻译云服务所使用的软件、技术、材料等，不得"
            ),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".6pt" } }, [
            _vm._v("通过反向工程、"),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".65pt" } }, [
            _vm._v("反编译、反汇编或其他类似行为获得云服务涉及的源代码，"),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".6pt" } }, [
            _vm._v("否则由此引起"),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".5pt" } }, [
            _vm._v("的一切法律后果由您负责，税安科技有权依法追究您的法"),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".45pt" } }, [
            _vm._v("律责任。"),
          ]),
        ]
      ),
      _c(
        "p",
        { staticClass: "MsoBodyText", staticStyle: { "line-height": "150%" } },
        [
          _c(
            "span",
            {
              staticStyle: { "letter-spacing": "-.6pt" },
              attrs: { lang: "EN-US" },
            },
            [
              _vm._v("6.6"),
              _c("span", { staticStyle: { font: '7.0pt "Times New Roman"' } }, [
                _vm._v("   "),
              ]),
            ]
          ),
          _c("span", { staticStyle: { "letter-spacing": ".6pt" } }, [
            _vm._v("您应尊重税安科技和第三方的知识产权和其他合法权利"),
            _c("span", { attrs: { lang: "EN-US" } }, [_vm._v("/")]),
            _vm._v("权益，并保证在发生侵犯前述权"),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".65pt" } }, [
            _vm._v("益的违法事件时，保护税安科技及其合作伙伴等免于因该等事"),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".6pt" } }, [
            _vm._v("件受到影响或损失，税安科技保"),
          ]),
          _c("span", { staticStyle: {} }, [
            _vm._v("留在您侵犯税安科技及"),
            _c("span", { attrs: { lang: "EN-US" } }, [_vm._v("/")]),
            _vm._v("或其他第三"),
          ]),
          _c("span", { staticStyle: {} }, [
            _vm._v("方的合法权利"),
            _c("span", { attrs: { lang: "EN-US" } }, [_vm._v("/")]),
            _vm._v("权益时终止向您提供服务并不退还任何款"),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".5pt" } }, [
            _vm._v("项的"),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".45pt" } }, [
            _vm._v("权利。"),
          ]),
        ]
      ),
      _c(
        "p",
        { staticClass: "MsoBodyText", staticStyle: { "line-height": "150%" } },
        [
          _c(
            "span",
            {
              staticStyle: { "letter-spacing": "-.6pt" },
              attrs: { lang: "EN-US" },
            },
            [
              _vm._v("6.7"),
              _c("span", { staticStyle: { font: '7.0pt "Times New Roman"' } }, [
                _vm._v("   "),
              ]),
            ]
          ),
          _c("span", { staticStyle: { "letter-spacing": ".6pt" } }, [
            _vm._v("如果第三方机构或个人对您使用税安科技产品及"),
            _c("span", { attrs: { lang: "EN-US" } }, [_vm._v("/")]),
            _vm._v("或服务所涉及的相关素材的知识产权归"),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".65pt" } }, [
            _vm._v("属提出质疑或投诉，或对您使用的税安科技产品或服务的知识"),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".6pt" } }, [
            _vm._v("产权的归属提出质疑或投诉，"),
          ]),
          _c("span", { staticStyle: {} }, [
            _vm._v("您和税安科技均有责任出具相关知识产权证明材料，并配合对"),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".6pt" } }, [
            _vm._v("方的相关投诉处理工作。对于因此引起的索赔、"),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".65pt" } }, [
            _vm._v("诉讼，违约方应负责解决，并承担由此产生的费用和损失，"),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".6pt" } }, [
            _vm._v("以及使另一"),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".5pt" } }, [
            _vm._v("方免"),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".4pt" } }, [
            _vm._v("责。"),
          ]),
        ]
      ),
      _c("p", { staticClass: "MsoBodyText", staticStyle: {} }, [
        _c("b", [
          _c(
            "span",
            {
              staticStyle: { "letter-spacing": "-1.1pt" },
              attrs: { lang: "EN-US" },
            },
            [
              _vm._v("7"),
              _c("span", { staticStyle: { font: '7.0pt "Times New Roman"' } }, [
                _vm._v("    "),
              ]),
            ]
          ),
        ]),
        _c("b", [
          _c(
            "span",
            {
              staticStyle: { "letter-spacing": ".3pt" },
              attrs: { lang: "EN-US" },
            },
            [_vm._v("保密条款")]
          ),
        ]),
      ]),
      _c(
        "p",
        { staticClass: "MsoBodyText", staticStyle: { "line-height": "150%" } },
        [
          _c(
            "span",
            {
              staticStyle: { "letter-spacing": "-.6pt" },
              attrs: { lang: "EN-US" },
            },
            [
              _vm._v("7.1"),
              _c("span", { staticStyle: { font: '7.0pt "Times New Roman"' } }, [
                _vm._v("   "),
              ]),
            ]
          ),
          _c("span", { staticStyle: { "letter-spacing": ".45pt" } }, [
            _vm._v("本协议项下"),
            _c("span", { attrs: { lang: "EN-US" } }, [_vm._v("“")]),
            _vm._v("保密信息"),
            _c("span", { attrs: { lang: "EN-US" } }, [_vm._v("”")]),
            _vm._v("是指一方（以下简称"),
            _c("span", { attrs: { lang: "EN-US" } }, [_vm._v("“")]),
            _vm._v("接受方"),
            _c("span", { attrs: { lang: "EN-US" } }, [_vm._v("”")]),
            _vm._v("）从另一方（以下简称"),
            _c("span", { attrs: { lang: "EN-US" } }, [_vm._v("“")]),
            _vm._v("披露方"),
            _c("span", { attrs: { lang: "EN-US" } }, [_vm._v("”")]),
            _vm._v("）"),
          ]),
          _c("span", { staticStyle: {} }, [
            _vm._v(
              "取得的、获知的、或因双方履行本协议而产生的商业秘密、技术秘密和"
            ),
            _c("span", { attrs: { lang: "EN-US" } }, [_vm._v("/")]),
            _vm._v("或其他"),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".45pt" } }, [
            _vm._v("应予"),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".65pt" } }, [
            _vm._v("保密的信息和资料（包括但不限于产品计划，价格，财务及"),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".6pt" } }, [
            _vm._v("营销规划，业务战略，客户信"),
          ]),
          _c("span", { staticStyle: {} }, [
            _vm._v("息和数据，研发资料，软硬件，"),
            _c("span", { attrs: { lang: "EN-US" } }, [_vm._v("API")]),
          ]),
          _c("span", {
            staticStyle: { "letter-spacing": "1.55pt" },
            attrs: { lang: "EN-US" },
          }),
          _c("span", { staticStyle: { "letter-spacing": ".6pt" } }, [
            _vm._v("应用数据接口，技术说明，设计，特殊公式，特殊算"),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".4pt" } }, [
            _vm._v("法等）"),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".55pt" } }, [
            _vm._v("。"),
          ]),
        ]
      ),
      _c(
        "p",
        { staticClass: "MsoBodyText", staticStyle: { "line-height": "150%" } },
        [
          _c(
            "span",
            {
              staticStyle: { "letter-spacing": "-.6pt" },
              attrs: { lang: "EN-US" },
            },
            [
              _vm._v("7.2"),
              _c("span", { staticStyle: { font: '7.0pt "Times New Roman"' } }, [
                _vm._v("   "),
              ]),
            ]
          ),
          _c("span", { staticStyle: { "letter-spacing": ".45pt" } }, [
            _vm._v(
              "接受方同意对披露方之上述保密信息予以保密，并严格限制接触上述保密信息的员工遵"
            ),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".5pt" } }, [
            _vm._v("守本条之保密义务，但不适用于以下"),
          ]),
          _c("span", { staticStyle: {} }, [_vm._v("情况：")]),
        ]
      ),
      _c("p", { staticClass: "MsoBodyText", staticStyle: {} }, [
        _c(
          "span",
          {
            staticStyle: { "font-family": '"Times New Roman",serif' },
            attrs: { lang: "EN-US" },
          },
          [
            _vm._v("(1)"),
            _c("span", { staticStyle: { font: '7.0pt "Times New Roman"' } }, [
              _vm._v("     "),
            ]),
          ]
        ),
        _c("span", { staticStyle: { "letter-spacing": ".45pt" } }, [
          _vm._v(
            "在签署本协议之时或之前，接受方已合法知悉且可不受限制地使用该等保密信息；"
          ),
        ]),
      ]),
      _c("p", { staticClass: "MsoBodyText", staticStyle: {} }, [
        _c(
          "span",
          {
            staticStyle: { "font-family": '"Times New Roman",serif' },
            attrs: { lang: "EN-US" },
          },
          [
            _vm._v("(2)"),
            _c("span", { staticStyle: { font: '7.0pt "Times New Roman"' } }, [
              _vm._v("     "),
            ]),
          ]
        ),
        _c("span", { staticStyle: { "letter-spacing": ".45pt" } }, [
          _vm._v("非因接受方违反本协议约定而被合法公开的信息；"),
        ]),
      ]),
      _c("p", { staticClass: "MsoBodyText", staticStyle: {} }, [
        _c(
          "span",
          {
            staticStyle: { "font-family": '"Times New Roman",serif' },
            attrs: { lang: "EN-US" },
          },
          [
            _vm._v("(3)"),
            _c("span", { staticStyle: { font: '7.0pt "Times New Roman"' } }, [
              _vm._v("     "),
            ]),
          ]
        ),
        _c("span", { staticStyle: { "letter-spacing": ".45pt" } }, [
          _vm._v("保密信息是接受方从没有保密义务的第三方获得的；"),
        ]),
      ]),
      _c("p", { staticClass: "MsoBodyText", staticStyle: {} }, [
        _c(
          "span",
          {
            staticStyle: { "font-family": '"Times New Roman",serif' },
            attrs: { lang: "EN-US" },
          },
          [
            _vm._v("(4)"),
            _c("span", { staticStyle: { font: '7.0pt "Times New Roman"' } }, [
              _vm._v("     "),
            ]),
          ]
        ),
        _c("span", { staticStyle: { "letter-spacing": ".45pt" } }, [
          _vm._v("保密信息是由接受方独立开发取得的信息；"),
        ]),
      ]),
      _c("p", { staticClass: "MsoBodyText", staticStyle: {} }, [
        _c(
          "span",
          {
            staticStyle: { "font-family": '"Times New Roman",serif' },
            attrs: { lang: "EN-US" },
          },
          [
            _vm._v("(5)"),
            _c("span", { staticStyle: { font: '7.0pt "Times New Roman"' } }, [
              _vm._v("     "),
            ]),
          ]
        ),
        _c("span", { staticStyle: { "letter-spacing": ".6pt" } }, [
          _vm._v(
            "接受方应法院、证券交易所或其他法律、行政管理部门要求（通过口头提问、询问、"
          ),
        ]),
        _c("span", { staticStyle: { "letter-spacing": ".45pt" } }, [
          _vm._v("要求资料或文件、"),
        ]),
        _c("span", { staticStyle: { "letter-spacing": ".55pt" } }, [
          _vm._v("传唤、民事"),
        ]),
        _c("span", { staticStyle: { "letter-spacing": ".5pt" } }, [
          _vm._v("或刑事调查或其他程序）因而透露保密信息；"),
        ]),
      ]),
      _c("p", { staticClass: "MsoBodyText", staticStyle: {} }, [
        _c(
          "span",
          {
            staticStyle: { "letter-spacing": ".15pt" },
            attrs: { lang: "EN-US" },
          },
          [_vm._v("(6)")]
        ),
        _c("span", {
          staticStyle: { "letter-spacing": ".6pt" },
          attrs: { lang: "EN-US" },
        }),
        _c("span", { staticStyle: { "letter-spacing": ".6pt" } }, [
          _vm._v(
            "接受方为向行政管理部门、行业协会、银行等机构申请某项业务资质、获得某项认定、或符合国"
          ),
        ]),
        _c("span", { staticStyle: { "letter-spacing": ".5pt" } }, [
          _vm._v("家、"),
        ]),
        _c("span", { staticStyle: { "letter-spacing": ".55pt" } }, [
          _vm._v("行业"),
        ]),
        _c("span", { staticStyle: { "letter-spacing": ".5pt" } }, [
          _vm._v("标准"),
          _c("span", { attrs: { lang: "EN-US" } }, [_vm._v("/")]),
          _vm._v("认证，需结合对方情况向前述机构提交材料或进行说明的而披"),
        ]),
        _c("span", { staticStyle: { "letter-spacing": ".7pt" } }, [
          _vm._v("露"),
        ]),
        _c("span", { staticStyle: { "letter-spacing": ".65pt" } }, [
          _vm._v(
            "的信息，在该等情况下，接受方应秉持必要情况下最少披露原则及要求因此获知保"
          ),
        ]),
        _c("span", { staticStyle: { "letter-spacing": ".5pt" } }, [
          _vm._v("密信息的机构按不低于本协议的标准予以"),
        ]),
        _c("span", { staticStyle: {} }, [_vm._v("保密。")]),
      ]),
      _c(
        "p",
        { staticClass: "MsoBodyText", staticStyle: { "line-height": "150%" } },
        [
          _c(
            "span",
            {
              staticStyle: { "letter-spacing": "-.6pt" },
              attrs: { lang: "EN-US" },
            },
            [
              _vm._v("7.3"),
              _c("span", { staticStyle: { font: '7.0pt "Times New Roman"' } }, [
                _vm._v("   "),
              ]),
            ]
          ),
          _c("span", { staticStyle: { "letter-spacing": ".45pt" } }, [
            _vm._v(
              "本协议双方明确认可保密信息是双方的重要资产，双方同意尽最大的努力保护上述保密"
            ),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".65pt" } }, [
            _vm._v("信息不被披露。一旦发现有上述保密信息泄露事件，双方应"),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".6pt" } }, [
            _vm._v("合作采取一切合理措施避免或"),
          ]),
          _c("span", { staticStyle: {} }, [_vm._v("者减轻损害后果的")]),
          _c("span", { staticStyle: { "letter-spacing": ".45pt" } }, [
            _vm._v("产生。"),
          ]),
        ]
      ),
      _c("p", { staticClass: "MsoBodyText", staticStyle: {} }, [
        _c("b", [
          _c(
            "span",
            {
              staticStyle: { "letter-spacing": "-1.05pt" },
              attrs: { lang: "EN-US" },
            },
            [
              _vm._v("8"),
              _c("span", { staticStyle: { font: '7.0pt "Times New Roman"' } }, [
                _vm._v("    "),
              ]),
            ]
          ),
        ]),
        _c("b", [
          _c("span", { staticStyle: { "letter-spacing": ".4pt" } }, [
            _vm._v("服务期限与变更、中断、终止"),
          ]),
        ]),
      ]),
      _c("p", { staticClass: "MsoBodyText", staticStyle: {} }, [
        _c(
          "span",
          {
            staticStyle: { "letter-spacing": "-.6pt" },
            attrs: { lang: "EN-US" },
          },
          [
            _vm._v("8.1"),
            _c("span", { staticStyle: { font: '7.0pt "Times New Roman"' } }, [
              _vm._v("   "),
            ]),
          ]
        ),
        _c("span", { staticStyle: { "letter-spacing": ".45pt" } }, [
          _vm._v(
            "服务期限以订单支付日期为起始日，若该订单无需支付，则以订单提交日期为起始日。"
          ),
        ]),
      ]),
      _c("p", { staticClass: "MsoBodyText", staticStyle: {} }, [
        _c(
          "span",
          {
            staticStyle: { "letter-spacing": "-.6pt" },
            attrs: { lang: "EN-US" },
          },
          [
            _vm._v("8.2"),
            _c("span", { staticStyle: { font: '7.0pt "Times New Roman"' } }, [
              _vm._v("   "),
            ]),
          ]
        ),
        _c("span", { staticStyle: { "letter-spacing": ".45pt" } }, [
          _vm._v("发生下列情形，服务期限提前终止："),
        ]),
      ]),
      _c(
        "p",
        { staticClass: "MsoBodyText", staticStyle: { "line-height": "150%" } },
        [
          _c(
            "span",
            {
              staticStyle: { "letter-spacing": ".5pt" },
              attrs: { lang: "EN-US" },
            },
            [
              _vm._v("8.2.1"),
              _c("span", { staticStyle: { font: '7.0pt "Times New Roman"' } }, [
                _vm._v("   "),
              ]),
            ]
          ),
          _c("span", { staticStyle: { "letter-spacing": ".5pt" } }, [
            _vm._v("双方协商一致提前终止的；"),
          ]),
        ]
      ),
      _c(
        "p",
        { staticClass: "MsoBodyText", staticStyle: { "line-height": "150%" } },
        [
          _c(
            "span",
            {
              staticStyle: { "letter-spacing": ".5pt" },
              attrs: { lang: "EN-US" },
            },
            [
              _vm._v("8.2.2"),
              _c("span", { staticStyle: { font: '7.0pt "Times New Roman"' } }, [
                _vm._v("   "),
              ]),
            ]
          ),
          _c("span", { staticStyle: { "letter-spacing": ".5pt" } }, [
            _vm._v("您严重违反本协议（包括但不限于："),
            _c("span", { attrs: { lang: "EN-US" } }, [_vm._v("a.")]),
            _vm._v("您未按照协议约定履行付款义务，及"),
            _c("span", { attrs: { lang: "EN-US" } }, [_vm._v("/")]),
            _vm._v("或"),
            _c("span", { attrs: { lang: "EN-US" } }, [_vm._v(" b.")]),
            _vm._v(
              "您严重违反相关法律法规规定或您严重违反本协议项下之任一承诺内容等），税安科技有权提前终止服务，并不退还您已经支付的费用；"
            ),
          ]),
        ]
      ),
      _c(
        "p",
        { staticClass: "MsoBodyText", staticStyle: { "line-height": "150%" } },
        [
          _c("b", [
            _c(
              "span",
              {
                staticStyle: { "letter-spacing": ".5pt" },
                attrs: { lang: "EN-US" },
              },
              [
                _vm._v("8.2.3"),
                _c(
                  "span",
                  { staticStyle: { font: '7.0pt "Times New Roman"' } },
                  [_vm._v("   ")]
                ),
              ]
            ),
          ]),
          _c("b", [
            _c("span", { staticStyle: { "letter-spacing": ".5pt" } }, [
              _vm._v(
                "您理解并充分认可，虽然税安科技已经建立（并将根据技术的发展不断完善）必要的技术措施来防御包括计算机病毒、网络入侵和攻击破坏（包括但不限于"
              ),
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v(" DDOS")]),
              _vm._v("）等危害网络安全的事项或行为（以下统称"),
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v("“")]),
              _vm._v("该等行为"),
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v("”")]),
              _vm._v(
                "），但鉴于网络安全技术的局限性、相对性以及该等行为的不可预见性，因此如因您遭遇该等行为而给税安科技或者税安科技的其他的网络或服务器（包括但不限于本地及外地和国际的网络、服务器等）带来危害，或影响税安科技与国际互联网或者税安科技与特定网络、服务器及税安科技内部的通畅联系，税安科技可决定暂停或终止服务，如果终止服务的，"
              ),
              _c("a", { attrs: { name: "OLE_LINK4" } }, [
                _vm._v(
                  "将按照实际提供服务月份计算（不足一个月的按天计）服务费用"
                ),
              ]),
              _vm._v("，将剩余款项退回，除此以外税安科技不承担其他责任。"),
            ]),
          ]),
        ]
      ),
      _c(
        "p",
        { staticClass: "MsoBodyText", staticStyle: { "line-height": "150%" } },
        [
          _c(
            "span",
            {
              staticStyle: { "letter-spacing": ".5pt" },
              attrs: { lang: "EN-US" },
            },
            [
              _vm._v("8.2.4"),
              _c("span", { staticStyle: { font: '7.0pt "Times New Roman"' } }, [
                _vm._v("   "),
              ]),
            ]
          ),
          _c("span", { staticStyle: { "letter-spacing": ".5pt" } }, [
            _vm._v(
              "服务因税安科技无法控制的事由而无法提供（包括但不限于不可抗力），或继续提供服务将导致您或税安科技违反适用的法律、法规或规章。"
            ),
          ]),
        ]
      ),
      _c(
        "p",
        { staticClass: "MsoBodyText", staticStyle: { "line-height": "150%" } },
        [
          _c(
            "span",
            {
              staticStyle: { "letter-spacing": "-.6pt" },
              attrs: { lang: "EN-US" },
            },
            [
              _vm._v("8.3"),
              _c("span", { staticStyle: { font: '7.0pt "Times New Roman"' } }, [
                _vm._v("   "),
              ]),
            ]
          ),
          _c("b", [
            _c("span", { staticStyle: { "letter-spacing": ".45pt" } }, [
              _vm._v(
                "税安科技可不时修改、终止服务，在我们终止提供您正在使用的任何服务或任何服务功能"
              ),
            ]),
          ]),
          _c("b", [
            _c("span", { staticStyle: { "letter-spacing": ".55pt" } }, [
              _vm._v("之前，我们将提前"),
            ]),
          ]),
          _c("b", [_c("span", { staticStyle: {} })]),
          _c("b", [
            _c(
              "span",
              {
                staticStyle: { "letter-spacing": ".45pt" },
                attrs: { lang: "EN-US" },
              },
              [_vm._v("30")]
            ),
          ]),
          _c("b", [
            _c("span", {
              staticStyle: { "letter-spacing": ".95pt" },
              attrs: { lang: "EN-US" },
            }),
          ]),
          _c("b", [
            _c("span", { staticStyle: {} }, [
              _vm._v(
                "日在税安科技官网上通告或给您发网站内通知或其他书面通知的方式"
              ),
            ]),
          ]),
          _c("b", [
            _c("span", { staticStyle: {} }, [
              _vm._v(
                "通知您，届时税安科技应将您已预付但未消费的款项退还给您。您知悉"
              ),
            ]),
          ]),
          _c("b", [
            _c("span", { staticStyle: {} }, [
              _vm._v(
                "并确认，部分产品服务或产品服务中的部分功能的终止并不影响您在使用其他"
              ),
            ]),
          ]),
          _c("b", [_c("span", { staticStyle: {} }, [_vm._v("产品服务时")])]),
          _c("b", [
            _c("span", { staticStyle: { "letter-spacing": ".5pt" } }, [
              _vm._v("依"),
            ]),
          ]),
          _c("b", [
            _c("span", { staticStyle: { "letter-spacing": ".45pt" } }, [
              _vm._v("据对本协议的遵守。"),
            ]),
          ]),
        ]
      ),
      _c(
        "p",
        { staticClass: "MsoBodyText", staticStyle: { "line-height": "150%" } },
        [
          _c(
            "span",
            {
              staticStyle: { "letter-spacing": "-.6pt" },
              attrs: { lang: "EN-US" },
            },
            [
              _vm._v("8.4"),
              _c("span", { staticStyle: { font: '7.0pt "Times New Roman"' } }, [
                _vm._v("   "),
              ]),
            ]
          ),
          _c("span", { staticStyle: { "letter-spacing": ".45pt" } }, [
            _vm._v(
              "在法律允许的范围内，如发生不可抗力时，税安科技有权不经通知而中断、中止或终止服"
            ),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".65pt" } }, [
            _vm._v("务，服务变更或中断或终止后税安科技会尽最大努力及时恢复"),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".6pt" } }, [
            _vm._v("服务或通过一种或多种方式发"),
          ]),
          _c("span", { staticStyle: {} }, [
            _vm._v("出通知，或受您的信息因素或其他未知因素影响不能保证您"),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".6pt" } }, [
            _vm._v("一定获得到通知，遇到故障请"),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".5pt" } }, [
            _vm._v("您及时致电税安科技在线客服或客服电"),
          ]),
          _c("span", { staticStyle: {} }, [_vm._v("话反馈。")]),
        ]
      ),
      _c(
        "p",
        { staticClass: "MsoBodyText", staticStyle: { "line-height": "150%" } },
        [
          _c(
            "span",
            {
              staticStyle: { "letter-spacing": "-.6pt" },
              attrs: { lang: "EN-US" },
            },
            [
              _vm._v("8.5"),
              _c("span", { staticStyle: { font: '7.0pt "Times New Roman"' } }, [
                _vm._v("   "),
              ]),
            ]
          ),
          _c("span", { staticStyle: { "letter-spacing": ".3pt" } }, [
            _vm._v("税安科技存在免费及"),
            _c("span", { attrs: { lang: "EN-US" } }, [_vm._v("/")]),
            _vm._v("或付费产品及"),
            _c("span", { attrs: { lang: "EN-US" } }, [_vm._v("/")]),
            _vm._v("或服务。若您选择了需要付费的产品及"),
            _c("span", { attrs: { lang: "EN-US" } }, [_vm._v("/")]),
            _vm._v("或服务，税安科技"),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".5pt" } }, [
            _vm._v("根据您自主选择的产品及"),
            _c("span", { attrs: { lang: "EN-US" } }, [_vm._v("/")]),
            _vm._v("或服务内容向您收取相应的费用。若您未按时足额"),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".45pt" } }, [
            _vm._v("付费，税安科技有权中断、"),
          ]),
          _c("span", { staticStyle: {} }, [_vm._v("中止")]),
          _c("span", { staticStyle: { "letter-spacing": ".5pt" } }, [
            _vm._v("提供服务，并有权提前终止本协议。"),
          ]),
        ]
      ),
      _c("p", { staticClass: "MsoBodyText", staticStyle: {} }, [
        _c("b", [
          _c(
            "span",
            {
              staticStyle: { "letter-spacing": "-.6pt" },
              attrs: { lang: "EN-US" },
            },
            [
              _vm._v("9"),
              _c("span", { staticStyle: { font: '7.0pt "Times New Roman"' } }, [
                _vm._v("   "),
              ]),
            ]
          ),
        ]),
        _c("b", [
          _c(
            "span",
            {
              staticStyle: { "letter-spacing": ".4pt" },
              attrs: { lang: "EN-US" },
            },
            [_vm._v("责任范围及责任限制")]
          ),
        ]),
      ]),
      _c("p", { staticClass: "MsoBodyText", staticStyle: {} }, [
        _c(
          "span",
          {
            staticStyle: { "letter-spacing": "-.4pt" },
            attrs: { lang: "EN-US" },
          },
          [
            _vm._v("9.1"),
            _c("span", { staticStyle: { font: '7.0pt "Times New Roman"' } }, [
              _vm._v("  "),
            ]),
          ]
        ),
        _c("span", { staticStyle: { "letter-spacing": ".45pt" } }, [
          _vm._v("本协议任何一方违约均须依法承担违约责任。"),
        ]),
      ]),
      _c("p", { staticClass: "MsoBodyText", staticStyle: {} }, [
        _c(
          "span",
          {
            staticStyle: { "letter-spacing": "-.4pt" },
            attrs: { lang: "EN-US" },
          },
          [
            _vm._v("9.2"),
            _c("span", { staticStyle: { font: '7.0pt "Times New Roman"' } }, [
              _vm._v("  "),
            ]),
          ]
        ),
        _c("span", { staticStyle: { "letter-spacing": ".45pt" } }, [
          _vm._v(
            "您理解，鉴于计算机、互联网的特殊性，下述情况不属于税安科技违约："
          ),
        ]),
      ]),
      _c(
        "p",
        { staticClass: "MsoBodyText", staticStyle: { "line-height": "150%" } },
        [
          _c(
            "span",
            {
              staticStyle: { "letter-spacing": ".5pt" },
              attrs: { lang: "EN-US" },
            },
            [
              _vm._v("9.2.1"),
              _c("span", { staticStyle: { font: '7.0pt "Times New Roman"' } }, [
                _vm._v("   "),
              ]),
            ]
          ),
          _c("span", { staticStyle: { "letter-spacing": ".5pt" } }, [
            _vm._v("税安科技在进行服务器配置、维护时，需要短时间中断服务；"),
          ]),
        ]
      ),
      _c(
        "p",
        { staticClass: "MsoBodyText", staticStyle: { "line-height": "150%" } },
        [
          _c(
            "span",
            {
              staticStyle: { "letter-spacing": ".5pt" },
              attrs: { lang: "EN-US" },
            },
            [
              _vm._v("9.2.2"),
              _c("span", { staticStyle: { font: '7.0pt "Times New Roman"' } }, [
                _vm._v("   "),
              ]),
            ]
          ),
          _c("span", { staticStyle: { "letter-spacing": ".5pt" } }, [
            _vm._v("由于"),
            _c("span", { attrs: { lang: "EN-US" } }, [_vm._v(" Internet ")]),
            _vm._v("上的通路阻塞造成您网站访问速度下降；"),
          ]),
        ]
      ),
      _c(
        "p",
        { staticClass: "MsoBodyText", staticStyle: { "line-height": "150%" } },
        [
          _c(
            "span",
            {
              staticStyle: { "letter-spacing": ".5pt" },
              attrs: { lang: "EN-US" },
            },
            [
              _vm._v("9.2.3"),
              _c("span", { staticStyle: { font: '7.0pt "Times New Roman"' } }, [
                _vm._v("   "),
              ]),
            ]
          ),
          _c("span", { staticStyle: { "letter-spacing": ".5pt" } }, [
            _vm._v(
              "由于有关国家机关下达涉及有关禁令或类似通知、要求，税安科技将在法律和国家机关允许的情况下尽可能及时地通知您。"
            ),
          ]),
        ]
      ),
      _c(
        "p",
        { staticClass: "MsoBodyText", staticStyle: { "line-height": "150%" } },
        [
          _c(
            "span",
            {
              staticStyle: { "letter-spacing": "-.4pt" },
              attrs: { lang: "EN-US" },
            },
            [
              _vm._v("9.3"),
              _c("span", { staticStyle: { font: '7.0pt "Times New Roman"' } }, [
                _vm._v("    "),
              ]),
            ]
          ),
          _c("span", { staticStyle: { "letter-spacing": ".6pt" } }, [
            _vm._v(
              "您理解并同意，在使用本服务的过程中，可能会遇到网络信息或其他用户行为带来的风险，税安科技不对任何信息的真实性、"
            ),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".7pt" } }, [
            _vm._v("适用性、"),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".65pt" } }, [
            _vm._v("合法性承担责任，也不对由于使用服务致"),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".6pt" } }, [
            _vm._v("使您可能收到的任何骚扰性、"),
          ]),
          _c("span", { staticStyle: {} }, [
            _vm._v("威胁性、诽谤性、冒犯性、侵"),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".6pt" } }, [
            _vm._v("权或非法信息或传播承担任何"),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".5pt" } }, [
            _vm._v("责任。这些风险包括但"),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".45pt" } }, [
            _vm._v("不限于："),
          ]),
        ]
      ),
      _c(
        "p",
        { staticClass: "MsoBodyText", staticStyle: { "line-height": "150%" } },
        [
          _c(
            "span",
            {
              staticStyle: { "letter-spacing": ".5pt" },
              attrs: { lang: "EN-US" },
            },
            [
              _vm._v("9.3.1"),
              _c("span", { staticStyle: { font: '7.0pt "Times New Roman"' } }, [
                _vm._v("   "),
              ]),
            ]
          ),
          _c("span", { staticStyle: { "letter-spacing": ".5pt" } }, [
            _vm._v(
              "因使用本协议项下的服务，遭受他人误导、欺骗或其他导致或可能导致的任何心理、生理上的伤害以及经济上的损失。"
            ),
          ]),
        ]
      ),
      _c(
        "p",
        { staticClass: "MsoBodyText", staticStyle: { "line-height": "150%" } },
        [
          _c("a", { attrs: { name: "OLE_LINK5" } }, [
            _c(
              "span",
              {
                staticStyle: { "letter-spacing": ".5pt" },
                attrs: { lang: "EN-US" },
              },
              [
                _vm._v("9.3.2"),
                _c(
                  "span",
                  { staticStyle: { font: '7.0pt "Times New Roman"' } },
                  [_vm._v("   ")]
                ),
              ]
            ),
            _c("span", { staticStyle: { "letter-spacing": ".5pt" } }, [
              _vm._v("税安科技"),
            ]),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".5pt" } }, [
            _vm._v(
              "依照法律的规定对广告商履行相关义务，您应当自行判断广告信息的真实性并为自己的判断行为负责，除法律明确规定外，您因依该广告信息进行的交易或前述广告商提供的内容而遭受的损失或损害，税安科技不承担责任。"
            ),
          ]),
        ]
      ),
      _c(
        "p",
        { staticClass: "MsoBodyText", staticStyle: { "line-height": "150%" } },
        [
          _c(
            "span",
            {
              staticStyle: { "letter-spacing": ".5pt" },
              attrs: { lang: "EN-US" },
            },
            [
              _vm._v("9.3.3"),
              _c("span", { staticStyle: { font: '7.0pt "Times New Roman"' } }, [
                _vm._v("   "),
              ]),
            ]
          ),
          _c("span", { staticStyle: { "letter-spacing": ".5pt" } }, [
            _vm._v(
              "产品内可能嵌入第三方网站的链接，是否访问这些链接由您自行决定，税安科技并不保证这些链接上所提供的任何信息、数据、观点、图片、陈述或建议的准确性、完整性、充分性和可靠性。税安科技产品内嵌入的第三方网站链接仅在于为用户提供方便，并不表示税安科技对这些信息的认可和推荐，也非用于宣传或广告目的。"
            ),
          ]),
        ]
      ),
      _c(
        "p",
        { staticClass: "MsoBodyText", staticStyle: { "line-height": "150%" } },
        [
          _c(
            "span",
            {
              staticStyle: { "letter-spacing": "-.4pt" },
              attrs: { lang: "EN-US" },
            },
            [
              _vm._v("9.4"),
              _c("span", { staticStyle: { font: '7.0pt "Times New Roman"' } }, [
                _vm._v("    "),
              ]),
            ]
          ),
          _c("span", { staticStyle: { "letter-spacing": ".6pt" } }, [
            _vm._v(
              "一方违反本协议的约定，给另一方造成损失的，应当赔偿另一方的损失，赔偿金额以"
            ),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".5pt" } }, [
            _vm._v("违约方履行本协议已获得的直接经济利益"),
          ]),
          _c("span", { staticStyle: {} }, [_vm._v("为限。")]),
        ]
      ),
      _c(
        "p",
        { staticClass: "MsoBodyText", staticStyle: { "line-height": "150%" } },
        [
          _c(
            "span",
            {
              staticStyle: { "letter-spacing": "-.4pt" },
              attrs: { lang: "EN-US" },
            },
            [
              _vm._v("9.5"),
              _c("span", { staticStyle: { font: '7.0pt "Times New Roman"' } }, [
                _vm._v("    "),
              ]),
            ]
          ),
          _c("span", { staticStyle: { "letter-spacing": ".45pt" } }, [
            _vm._v(
              "在任何情况下，税安科技均不对任何间接性、后果性、惩戒性、偶然性、特殊性的损害，"
            ),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".7pt" } }, [
            _vm._v("包括不对您使用税安科技服务而遭受的利润损"),
          ]),
          _c("span", { staticStyle: {} }, [
            _vm._v("失承担责任（即使您已被告知该等损失的可能"),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".4pt" } }, [
            _vm._v("性）"),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".5pt" } }, [
            _vm._v("。"),
          ]),
        ]
      ),
      _c("p", { staticClass: "MsoBodyText", staticStyle: {} }, [
        _c("b", [
          _c(
            "span",
            {
              staticStyle: { "letter-spacing": "-.6pt" },
              attrs: { lang: "EN-US" },
            },
            [
              _vm._v("10"),
              _c("span", { staticStyle: { font: '7.0pt "Times New Roman"' } }, [
                _vm._v("  "),
              ]),
            ]
          ),
        ]),
        _c("b", [
          _c(
            "span",
            {
              staticStyle: { "letter-spacing": ".35pt" },
              attrs: { lang: "EN-US" },
            },
            [_vm._v("不可抗力")]
          ),
        ]),
      ]),
      _c(
        "p",
        { staticClass: "MsoBodyText", staticStyle: { "line-height": "150%" } },
        [
          _c(
            "span",
            {
              staticStyle: { "letter-spacing": "-.4pt" },
              attrs: { lang: "EN-US" },
            },
            [
              _vm._v("10.1"),
              _c("span", { staticStyle: { font: '7.0pt "Times New Roman"' } }, [
                _vm._v("   "),
              ]),
            ]
          ),
          _c("span", { staticStyle: { "letter-spacing": ".6pt" } }, [
            _vm._v(
              "因不可抗力或者其他意外事件，使得本协议的履行不可能、不必要或者无意义的，遭"
            ),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".45pt" } }, [
            _vm._v("受不可抗力、"),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".55pt" } }, [
            _vm._v("意外事"),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".5pt" } }, [
            _vm._v("件的一方不承担责任。"),
          ]),
        ]
      ),
      _c(
        "p",
        { staticClass: "MsoBodyText", staticStyle: { "line-height": "150%" } },
        [
          _c(
            "span",
            {
              staticStyle: { "letter-spacing": "-.4pt" },
              attrs: { lang: "EN-US" },
            },
            [
              _vm._v("10.2"),
              _c("span", { staticStyle: { font: '7.0pt "Times New Roman"' } }, [
                _vm._v("   "),
              ]),
            ]
          ),
          _c("span", { staticStyle: { "letter-spacing": ".6pt" } }, [
            _vm._v(
              "不可抗力、意外事件是指不能预见、不能克服并不能避免且对一方或双方当事人造成"
            ),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".4pt" } }, [
            _vm._v("重大影响的客观事件，包括但不限于自然灾害（如洪水、"),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".55pt" } }, [
            _vm._v("地震、瘟疫流行等）以"),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".5pt" } }, [
            _vm._v("及社会事"),
          ]),
        ]
      ),
      _c(
        "p",
        { staticClass: "MsoBodyText", staticStyle: { "line-height": "150%" } },
        [
          _c("span", { staticStyle: { "letter-spacing": ".6pt" } }, [
            _vm._v("件（如战争、"),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".65pt" } }, [
            _vm._v("动乱、政府行为、电信主干线路中断、黑客、网路堵塞、电"),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".6pt" } }, [
            _vm._v("信部门技术调整"),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".45pt" } }, [
            _vm._v("和政府管制等）"),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".55pt" } }, [
            _vm._v("等。"),
          ]),
        ]
      ),
      _c(
        "p",
        { staticClass: "MsoBodyText", staticStyle: { "line-height": "150%" } },
        [
          _c(
            "span",
            {
              staticStyle: { "letter-spacing": "-.4pt" },
              attrs: { lang: "EN-US" },
            },
            [
              _vm._v("10.3"),
              _c("span", { staticStyle: { font: '7.0pt "Times New Roman"' } }, [
                _vm._v("   "),
              ]),
            ]
          ),
          _c("span", { staticStyle: { "letter-spacing": ".6pt" } }, [
            _vm._v(
              "遭遇不可抗力后，受影响方应采取适当措施减少或消除不可抗力产生的影响，并应在"
            ),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".65pt" } }, [
            _vm._v("可能的范围内尽快重新履行受不可抗力影响的义务；同时，"),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".6pt" } }, [
            _vm._v("受影响方应立即通知相对方，"),
          ]),
          _c("span", { staticStyle: {} }, [_vm._v("并应于不可抗力发生后")]),
          _c("span", { staticStyle: { "letter-spacing": ".65pt" } }),
          _c(
            "span",
            {
              staticStyle: { "letter-spacing": ".45pt" },
              attrs: { lang: "EN-US" },
            },
            [_vm._v("15")]
          ),
          _c("span", {
            staticStyle: { "letter-spacing": ".7pt" },
            attrs: { lang: "EN-US" },
          }),
          _c("span", { staticStyle: { "letter-spacing": ".45pt" } }, [
            _vm._v("日内，向相对方提供必要、合理的证明文件。"),
          ]),
        ]
      ),
      _c(
        "p",
        { staticClass: "MsoBodyText", staticStyle: { "line-height": "150%" } },
        [
          _c(
            "span",
            {
              staticStyle: { "letter-spacing": "-.4pt" },
              attrs: { lang: "EN-US" },
            },
            [
              _vm._v("10.4"),
              _c("span", { staticStyle: { font: '7.0pt "Times New Roman"' } }, [
                _vm._v("   "),
              ]),
            ]
          ),
          _c("span", { staticStyle: { "letter-spacing": ".6pt" } }, [
            _vm._v(
              "若不可抗力导致受影响方无法履行本协议下全部或部分义务持续达到"
            ),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".3pt" } }),
          _c("span", { staticStyle: {}, attrs: { lang: "EN-US" } }, [
            _vm._v("60"),
          ]),
          _c("span", {
            staticStyle: { "letter-spacing": ".35pt" },
            attrs: { lang: "EN-US" },
          }),
          _c("span", { staticStyle: { "letter-spacing": ".65pt" } }, [
            _vm._v("日以上，则"),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".6pt" } }, [
            _vm._v(
              "双方应当进行协商，确定继续履行本协议的条件或终止本协议。若各方经协商后"
            ),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".35pt" } }),
          _c("span", { staticStyle: {}, attrs: { lang: "EN-US" } }, [
            _vm._v("30"),
          ]),
          _c("span", {
            staticStyle: { "letter-spacing": ".35pt" },
            attrs: { lang: "EN-US" },
          }),
          _c("span", { staticStyle: { "letter-spacing": ".6pt" } }, [
            _vm._v("日内"),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".5pt" } }, [
            _vm._v(
              "无法就继续履行本协议的条件达成一致意见，则任何一方可立即向对方发出终"
            ),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".45pt" } }, [
            _vm._v("止通知。"),
          ]),
        ]
      ),
      _c("p", { staticClass: "MsoBodyText", staticStyle: {} }, [
        _c("b", [
          _c(
            "span",
            {
              staticStyle: { "letter-spacing": "-.6pt" },
              attrs: { lang: "EN-US" },
            },
            [
              _vm._v("11"),
              _c("span", { staticStyle: { font: '7.0pt "Times New Roman"' } }, [
                _vm._v("  "),
              ]),
            ]
          ),
        ]),
        _c("b", [
          _c(
            "span",
            {
              staticStyle: { "letter-spacing": ".4pt" },
              attrs: { lang: "EN-US" },
            },
            [_vm._v("法律适用及争议解决")]
          ),
        ]),
      ]),
      _c("p", { staticClass: "MsoBodyText", staticStyle: {} }, [
        _c(
          "span",
          {
            staticStyle: { "letter-spacing": "-.4pt" },
            attrs: { lang: "EN-US" },
          },
          [
            _vm._v("11.1"),
            _c("span", { staticStyle: { font: '7.0pt "Times New Roman"' } }),
          ]
        ),
        _c("span", {
          staticStyle: { "letter-spacing": ".45pt" },
          attrs: { lang: "EN-US" },
        }),
        _c("span", { staticStyle: { "letter-spacing": ".45pt" } }, [
          _vm._v("本协议条款受中华人民共和国法律管辖。"),
        ]),
      ]),
      _c(
        "p",
        { staticClass: "MsoBodyText", staticStyle: { "line-height": "150%" } },
        [
          _c(
            "span",
            {
              staticStyle: { "letter-spacing": "-.4pt" },
              attrs: { lang: "EN-US" },
            },
            [
              _vm._v("11.2"),
              _c("span", { staticStyle: { font: '7.0pt "Times New Roman"' } }, [
                _vm._v("   "),
              ]),
            ]
          ),
          _c("span", { staticStyle: { "letter-spacing": ".6pt" } }, [
            _vm._v(
              "在执行本协议过程中如发生纠纷，双方应及时协商解决。协商不成时，任何一方有权"
            ),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".5pt" } }, [
            _vm._v("向浙江省杭州市滨江区人民法院提"),
          ]),
          _c("span", { staticStyle: {} }, [_vm._v("起诉讼。")]),
        ]
      ),
      _c("p", { staticClass: "MsoBodyText", staticStyle: {} }, [
        _c("b", [
          _c(
            "span",
            {
              staticStyle: { "letter-spacing": "-.6pt" },
              attrs: { lang: "EN-US" },
            },
            [
              _vm._v("12"),
              _c("span", { staticStyle: { font: '7.0pt "Times New Roman"' } }, [
                _vm._v("  "),
              ]),
            ]
          ),
        ]),
        _c("b", [
          _c(
            "span",
            {
              staticStyle: { "letter-spacing": ".25pt" },
              attrs: { lang: "EN-US" },
            },
            [_vm._v("附则")]
          ),
        ]),
      ]),
      _c(
        "p",
        { staticClass: "MsoBodyText", staticStyle: { "line-height": "150%" } },
        [
          _c(
            "span",
            {
              staticStyle: { "letter-spacing": "-.4pt" },
              attrs: { lang: "EN-US" },
            },
            [
              _vm._v("12.1"),
              _c("span", { staticStyle: { font: '7.0pt "Times New Roman"' } }, [
                _vm._v("   "),
              ]),
            ]
          ),
          _c("b", [
            _c("span", { staticStyle: { "letter-spacing": ".55pt" } }, [
              _vm._v(
                "税安科技在交易平台相关页面上的服务说明、价格说明和您确认同意的订购页面是本协议不可分割的一部分。如果交易平台相关页面上的服务说明、"
              ),
            ]),
          ]),
          _c("b", [
            _c("span", { staticStyle: { "letter-spacing": ".7pt" } }, [
              _vm._v("价格说明和您确认同意"),
            ]),
          ]),
          _c("b", [_c("span", { staticStyle: {} }, [_vm._v("的订")])]),
          _c("b", [
            _c("span", { staticStyle: { "letter-spacing": ".5pt" } }, [
              _vm._v("购页面与本协"),
            ]),
          ]),
          _c("b", [
            _c("span", { staticStyle: { "letter-spacing": ".45pt" } }, [
              _vm._v("议有不一致之处，以本协议为准。"),
            ]),
          ]),
        ]
      ),
      _c(
        "p",
        { staticClass: "MsoBodyText", staticStyle: { "line-height": "150%" } },
        [
          _c(
            "span",
            {
              staticStyle: { "letter-spacing": "-.4pt" },
              attrs: { lang: "EN-US" },
            },
            [
              _vm._v("12.2"),
              _c("span", { staticStyle: { font: '7.0pt "Times New Roman"' } }, [
                _vm._v("   "),
              ]),
            ]
          ),
          _c("span", { staticStyle: { "letter-spacing": ".6pt" } }, [
            _vm._v(
              "税安科技有权在不影响您使用本协议所订购税安科技产品的情况下，提前"
            ),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".4pt" } }),
          _c("span", { staticStyle: {}, attrs: { lang: "EN-US" } }, [
            _vm._v("30"),
          ]),
          _c("span", {
            staticStyle: { "letter-spacing": ".4pt" },
            attrs: { lang: "EN-US" },
          }),
          _c("span", { staticStyle: { "letter-spacing": ".6pt" } }, [
            _vm._v("日在交易"),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".65pt" } }, [
            _vm._v("平台上公布或给您发网站内通知或书面通知的方式将本协议"),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".6pt" } }, [
            _vm._v("的权利义务全部或者部分转移"),
          ]),
          _c("span", { staticStyle: {} }, [_vm._v("给税安科技的关")]),
          _c("span", { staticStyle: { "letter-spacing": ".45pt" } }, [
            _vm._v("联公司。"),
          ]),
        ]
      ),
      _c(
        "p",
        { staticClass: "MsoBodyText", staticStyle: { "line-height": "150%" } },
        [
          _c(
            "span",
            {
              staticStyle: { "letter-spacing": "-.4pt" },
              attrs: { lang: "EN-US" },
            },
            [
              _vm._v("12.3"),
              _c("span", { staticStyle: { font: '7.0pt "Times New Roman"' } }, [
                _vm._v("   "),
              ]),
            ]
          ),
          _c("span", { staticStyle: { "letter-spacing": ".6pt" } }, [
            _vm._v(
              "如果任何条款在性质上或其他方面理应地在本协议终止时继续存在，那么应视为继续存在的条款，这些条款包括但不局限于保证条款、"
            ),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".7pt" } }, [
            _vm._v("知识产权条款、保密"),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".65pt" } }, [
            _vm._v("条款、法律适用及"),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".5pt" } }, [
            _vm._v("争议解决"),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".45pt" } }, [
            _vm._v("条款。"),
          ]),
        ]
      ),
      _c(
        "p",
        { staticClass: "MsoBodyText", staticStyle: { "line-height": "150%" } },
        [
          _c(
            "span",
            {
              staticStyle: { "letter-spacing": "-.4pt" },
              attrs: { lang: "EN-US" },
            },
            [
              _vm._v("12.4"),
              _c("span", { staticStyle: { font: '7.0pt "Times New Roman"' } }, [
                _vm._v("   "),
              ]),
            ]
          ),
          _c("span", { staticStyle: { "letter-spacing": ".6pt" } }, [
            _vm._v(
              "本协议中条款前所列索引关键词仅为帮助您理解该条款表达的主旨之用，不影响或限"
            ),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".5pt" } }, [
            _vm._v(
              "制本协议条款的含义或解释。为维护您自身权益，建议您仔细阅读各条款具体"
            ),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".45pt" } }, [
            _vm._v("表述。"),
          ]),
        ]
      ),
      _c(
        "p",
        { staticClass: "MsoBodyText", staticStyle: { "line-height": "150%" } },
        [
          _c("span", { attrs: { lang: "EN-US" } }, [
            _vm._v("12.5"),
            _c("span", { staticStyle: { font: '7.0pt "Times New Roman"' } }, [
              _vm._v("  "),
            ]),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".6pt" } }, [
            _vm._v(
              "本协议会在交易平台订购页面在您支付相应款项前通过数据电文方式生成，您可以在"
            ),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".4pt" } }, [
            _vm._v("付款前详细阅读、"),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".5pt" } }, [
            _vm._v("下载、保存、打"),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".45pt" } }, [
            _vm._v("印本协议的全部内容；如果您不接受本协议，请停止付"),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".5pt" } }, [
            _vm._v("款，系统所生成的订单及本协议将自"),
          ]),
          _c("span", { staticStyle: {} }, [_vm._v("动失效。")]),
        ]
      ),
      _c(
        "p",
        { staticClass: "MsoBodyText", staticStyle: { "line-height": "150%" } },
        [
          _c("a", { attrs: { name: "OLE_LINK1" } }, [
            _c(
              "span",
              {
                staticStyle: { "letter-spacing": ".6pt" },
                attrs: { lang: "EN-US" },
              },
              [
                _vm._v("12.6"),
                _c(
                  "span",
                  { staticStyle: { font: '7.0pt "Times New Roman"' } },
                  [_vm._v("  ")]
                ),
              ]
            ),
            _c("span", { staticStyle: { "letter-spacing": ".6pt" } }, [
              _vm._v(
                "双方均为独立缔约方。本协议不为双方创立合伙、合资、代理或雇佣关系"
              ),
            ]),
          ]),
          _c("span", { staticStyle: { "letter-spacing": ".6pt" } }, [
            _vm._v("。"),
          ]),
        ]
      ),
      _c(
        "p",
        { staticClass: "MsoBodyText", staticStyle: { "line-height": "150%" } },
        [
          _c(
            "span",
            {
              staticStyle: { "letter-spacing": ".6pt" },
              attrs: { lang: "EN-US" },
            },
            [
              _vm._v("12.7"),
              _c("span", { staticStyle: { font: '7.0pt "Times New Roman"' } }, [
                _vm._v("  "),
              ]),
            ]
          ),
          _c("span", { staticStyle: { "letter-spacing": ".6pt" } }, [
            _vm._v(
              "本协议自您提交订单并税安科技收到您支付的相应服务费之日起生效。"
            ),
          ]),
        ]
      ),
      _c(
        "p",
        { staticClass: "MsoBodyText", staticStyle: { "line-height": "150%" } },
        [
          _c(
            "span",
            {
              staticStyle: { "letter-spacing": ".6pt" },
              attrs: { lang: "EN-US" },
            },
            [
              _vm._v("12.8"),
              _c("span", { staticStyle: { font: '7.0pt "Times New Roman"' } }, [
                _vm._v("  "),
              ]),
            ]
          ),
          _c("span", { staticStyle: { "letter-spacing": ".6pt" } }, [
            _vm._v("联系我们：如您对本协议有任何疑问，请联系我们："),
            _c("span", { attrs: { lang: "EN-US" } }, [
              _vm._v("0571-85055930 "),
            ]),
            _vm._v("。"),
          ]),
        ]
      ),
      _c(
        "p",
        { staticClass: "MsoNormal", staticStyle: { "line-height": "150%" } },
        [_c("span", { attrs: { lang: "EN-US" } }, [_vm._v(" ")])]
      ),
      _c("p", { staticClass: "MsoBodyText", staticStyle: {} }, [
        _c("span", { staticStyle: { "letter-spacing": ".65pt" } }, [
          _vm._v(
            "您再次确认并声明：您已经仔细阅读并完全理解本协议条款并愿意接"
          ),
        ]),
        _c("span", { staticStyle: { "letter-spacing": ".6pt" } }, [
          _vm._v("受上述所有条款"),
        ]),
        _c("span", { staticStyle: { "letter-spacing": ".5pt" } }, [
          _vm._v("的约"),
        ]),
        _c("span", { staticStyle: { "letter-spacing": ".4pt" } }, [
          _vm._v("束。"),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }