var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "whiteStyle" }),
    _c("div", [
      _c("div", { staticClass: "h5-wrapper h5" }, [
        _c("div", { staticClass: "card-wrapper" }, [
          _c("div", { staticClass: "info-card" }, [
            _c("div", { staticClass: "text0" }, [
              _vm._v(_vm._s(_vm.keyFinancialData.industry)),
            ]),
            _c("div", { staticClass: "text1" }, [_vm._v("所属行业")]),
          ]),
          _c("div", { staticClass: "info-card" }, [
            _c("div", { staticClass: "text0" }, [
              _vm._v(_vm._s(_vm.keyFinancialData.area)),
            ]),
            _c("div", { staticClass: "text1" }, [_vm._v("所属地区")]),
          ]),
        ]),
        _vm.keyFinancialData
          ? _c(
              "div",
              { staticClass: "content-section" },
              [
                _vm._l(_vm.keyFinancialData.data, function (chart, index) {
                  return [
                    _c("bossTaxBearingRate", {
                      key: index,
                      staticClass: "chart",
                      attrs: { data: chart, height: "300px", width: "300px" },
                    }),
                    _c("div", { staticClass: "line" }),
                  ]
                }),
              ],
              2
            )
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }