var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "order-container" },
    [
      _c("payItem", {
        staticClass: "good",
        attrs: {
          type: _vm.order,
          goodsCount: _vm.goodsCount,
          item: _vm.goodInfo,
        },
        on: { changeNum: _vm.changeNum },
      }),
      _c("goodInfos", {
        staticClass: "info",
        staticStyle: { display: "none" },
        attrs: { goodInfo: _vm.goodInfo.productConfigJson },
      }),
      _vm.goodInfo.templateId != 6
        ? _c(
            "div",
            { staticClass: "submit-account", staticStyle: { display: "none" } },
            [
              _c("div", { staticClass: "title" }, [_vm._v("系统账号")]),
              _vm.goodInfo.prompt
                ? _c("div", { staticClass: "pay-bottom-text" }, [
                    _c("i", { staticClass: "el-icon-warning-outline" }),
                    _c("div", [
                      _vm._v(" " + _vm._s(_vm.goodInfo.prompt) + " "),
                    ]),
                  ])
                : _vm._e(),
              _c("div", { staticClass: "item" }, [
                _c("div", [_vm._v("企业名称")]),
                _c(
                  "div",
                  [
                    _c("el-input", {
                      attrs: {
                        disabled: _vm.selectCompany,
                        placeholder: "请输入公司名称",
                      },
                      model: {
                        value: _vm.companyName,
                        callback: function ($$v) {
                          _vm.companyName =
                            typeof $$v === "string" ? $$v.trim() : $$v
                        },
                        expression: "companyName",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "item item-botom" }, [
                _c("div", [_vm._v("手机号")]),
                _c(
                  "div",
                  [
                    _c("el-input", {
                      attrs: { placeholder: "请输入手机号" },
                      model: {
                        value: _vm.mobile,
                        callback: function ($$v) {
                          _vm.mobile =
                            typeof $$v === "string" ? $$v.trim() : $$v
                        },
                        expression: "mobile",
                      },
                    }),
                  ],
                  1
                ),
                _c("i", {
                  staticClass: "el-icon-mobile-phone item-phone",
                  on: { click: _vm.showPhoneList },
                }),
                _vm.mobile
                  ? _c("i", {
                      staticClass: "el-icon-circle-close item-close",
                      on: { click: _vm.cancelPhoneList },
                    })
                  : _vm._e(),
              ]),
            ]
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "submit-account" },
        _vm._l(_vm.goodInfo.attrs, function (item) {
          return _c(
            "div",
            { key: item.groupId },
            [
              _c("div", { staticClass: "title" }, [
                _vm._v(_vm._s(item.groupName)),
              ]),
              _c("orderRadio", {
                attrs: { data: item.attrKey, options: [item] },
                on: {
                  "update:data": function ($event) {
                    return _vm.$set(item, "attrKey", $event)
                  },
                },
              }),
            ],
            1
          )
        }),
        0
      ),
      _c("img", {
        staticClass: "haibao",
        attrs: { src: _vm.equityImages, alt: "" },
      }),
      _c("div", { staticClass: "submit-account" }, [
        _vm.goodInfo.prompt
          ? _c("div", { staticClass: "pay-bottom-text" }, [
              _c("i", { staticClass: "el-icon-warning-outline" }),
              _c("div", [_vm._v(" " + _vm._s(_vm.goodInfo.prompt) + " ")]),
            ])
          : _vm._e(),
        _c("div", { staticClass: "itemDetail" }, [
          _c("div", [_vm._v("服务商名称")]),
          _vm._v(" " + _vm._s(_vm.goodInfo.channelName) + " "),
        ]),
        _c("div", { staticClass: "itemDetail" }, [
          _c("div", [_vm._v("服务热线")]),
          _vm._v(
            " " +
              _vm._s(_vm.goodInfo.orderPhone ? _vm.goodInfo.orderPhone : "-") +
              " "
          ),
        ]),
        _vm._m(0),
      ]),
      _vm.goodInfo
        ? _c("div", { staticClass: "footer-toolbar" }, [
            _c(
              "div",
              {
                staticStyle: {
                  "font-size": ".28rem",
                  display: "flex",
                  "align-items": "center",
                  "line-height": "1.5",
                  "margin-bottom": ".32rem",
                },
              },
              [
                _c(
                  "van-checkbox",
                  {
                    staticStyle: { "flex-shrink": "0" },
                    attrs: { shape: "square" },
                    model: {
                      value: _vm.agreeCheck,
                      callback: function ($$v) {
                        _vm.agreeCheck = $$v
                      },
                      expression: "agreeCheck",
                    },
                  },
                  [_vm._v("已阅读并同意签署 ")]
                ),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-link",
                    staticStyle: {
                      padding: "0",
                      color: "#165DFF",
                      height: "auto",
                    },
                    on: {
                      click: function ($event) {
                        _vm.isShowAgreement = true
                      },
                    },
                  },
                  [_vm._v("《智能财税服务平台服务协议》 ")]
                ),
              ],
              1
            ),
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                staticStyle: { "font-size": ".32rem" },
                on: { click: _vm.submit },
              },
              [
                _vm._v(
                  " 立即支付 ￥" +
                    _vm._s(
                      _vm.formatToDecimal(
                        Number(_vm.goodsCount) *
                          Number(_vm.goodInfo.currentPrice)
                      )
                    ) +
                    " "
                ),
              ]
            ),
          ])
        : _vm._e(),
      _c(
        "van-popup",
        {
          attrs: { position: "bottom", closeable: "" },
          model: {
            value: _vm.isShowAgreement,
            callback: function ($$v) {
              _vm.isShowAgreement = $$v
            },
            expression: "isShowAgreement",
          },
        },
        [_c("Agreement", { on: { agree: _vm.handleAgree } })],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "itemDetail" }, [
      _c("div", [_vm._v("购买须知")]),
      _vm._v(" 本产品不支持退款 "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }