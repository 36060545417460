var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "table-wrapper" },
    [
      _vm.loaded
        ? _c("BigPowerTable", {
            staticClass: "table",
            attrs: { data: _vm.data, loading: true },
          })
        : _c(
            "div",
            { staticClass: "table-wrapper loading" },
            [
              _c("van-loading", { attrs: { size: "24px" } }, [
                _vm._v("载入中"),
              ]),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }