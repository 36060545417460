var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticClass: "good-info",
          staticStyle: {
            background: "#fff",
            width: "7.1rem",
            position: "relative",
            margin: "0.2rem auto 0",
            "border-radius": "0.2rem",
            padding: "0.2rem",
            "box-sizing": "border-box",
          },
        },
        [
          _c("div", { staticStyle: { display: "flex" } }, [
            _c("div", { staticClass: "haibaoPic" }, [
              _c("img", {
                staticClass: "backPic",
                attrs: { src: _vm.orderInfo.showImageUrl, alt: "" },
              }),
              _c("img", {
                staticClass: "version-logo",
                attrs: { src: _vm.getCornerPic(_vm.orderInfo.attrs), alt: "" },
              }),
            ]),
            _c(
              "div",
              { staticStyle: { "margin-left": "0.3rem", "flex-grow": "1" } },
              [
                _c(
                  "span",
                  {
                    staticClass: "pay-titles",
                    staticStyle: { "margin-top": "0.1rem" },
                  },
                  [_vm._v(_vm._s(_vm.orderInfo.productName))]
                ),
                _c(
                  "div",
                  {
                    staticClass: "pay-price",
                    staticStyle: {
                      display: "flex",
                      width: "100%",
                      "justify-content": "space-between",
                      "margin-top": "0.48rem",
                    },
                  },
                  [
                    _c("FancyDecimal", {
                      attrs: { value: _vm.orderInfo.price },
                    }),
                    _c("span", { staticClass: "goodsCount" }, [
                      _vm._v(" x" + _vm._s(_vm.orderInfo.goodsCount)),
                    ]),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "div",
              {
                staticStyle: {
                  position: "absolute",
                  right: "0.4rem",
                  top: "0.2rem",
                  width: "1.14rem",
                },
              },
              [
                _vm.orderInfo.orderStatus == -1
                  ? _c("img", {
                      staticClass: "yizhifu",
                      attrs: { src: require("@/assets/img_jygb.svg"), alt: "" },
                    })
                  : _vm._e(),
                _vm.orderInfo.orderStatus == 1
                  ? _c("img", {
                      staticClass: "yizhifu",
                      attrs: { src: require("@/assets/img_dzf.svg"), alt: "" },
                    })
                  : _vm._e(),
                _vm.orderInfo.orderStatus == 2
                  ? _c("img", {
                      staticClass: "yizhifu",
                      attrs: { src: require("@/assets/img_jywc.svg"), alt: "" },
                    })
                  : _vm._e(),
              ]
            ),
          ]),
          _c(
            "div",
            { staticClass: "good-attr" },
            _vm._l(_vm.orderInfo.attrs, function (item, index) {
              return _c("p", { key: index, staticClass: "mx-line" }, [
                _c("span", [_vm._v(_vm._s(item.groupName))]),
                _c("span", [_vm._v(_vm._s(item.attrVal))]),
              ])
            }),
            0
          ),
          _c("div", { staticClass: "pay-total" }, [
            _c("div", { staticClass: "txt" }, [_vm._v("应付金额")]),
            _c(
              "div",
              {
                staticClass: "txtMoney",
                staticStyle: {
                  color: "#165dff !important",
                  display: "flex",
                  "align-items": "flex-end",
                },
              },
              [_c("FancyDecimal", { attrs: { value: _vm.orderInfo.price } })],
              1
            ),
          ]),
        ]
      ),
      _c("goodInfo", {
        staticClass: "info",
        staticStyle: { display: "none" },
        attrs: { goodInfo: _vm.orderInfo.productConfigJson },
      }),
      _c("div", { staticClass: "submit-account" }, [
        _vm.orderInfo.templateId != 6
          ? _c("div", { staticClass: "title" }, [_vm._v("基本信息")])
          : _vm._e(),
        _c("div", { staticClass: "item items" }, [
          _c("div", [_vm._v("订单编号")]),
          _c("div", [_vm._v(_vm._s(_vm.orderInfo.orderNo))]),
        ]),
        _c("div", { staticClass: "item items" }, [
          _c("div", [_vm._v("订单创建时间")]),
          _c("div", [_vm._v(_vm._s(_vm.orderInfo.createTime))]),
        ]),
        _c("div", { staticClass: "divider" }),
        _vm.orderInfo.templateId != 6
          ? _c("div", { staticClass: "item items" }, [
              _c("div", [_vm._v("服务商名称")]),
              _c("div", [_vm._v(_vm._s(_vm.orderInfo.channelName))]),
            ])
          : _vm._e(),
        _vm.orderInfo.templateId != 6
          ? _c("div", { staticClass: "item items" }, [
              _c("div", [_vm._v("服务热线")]),
              _c("div", [_vm._v(_vm._s(_vm.orderInfo.orderPhone))]),
            ])
          : _vm._e(),
        _vm.orderInfo.templateId != 6
          ? _c("div", { staticClass: "item items" }, [
              _c("div", [_vm._v("购买须知")]),
              _c("div", [_vm._v("本产品不支持退款")]),
            ])
          : _vm._e(),
        _c("div", { staticClass: "divider" }),
        _vm.orderInfo.orderStatus == 2
          ? _c("div", [
              _c("div", { staticClass: "item items" }, [
                _c("div", [_vm._v("交易单号")]),
                _c("div", [_vm._v(_vm._s(_vm.orderInfo.bizPayNo))]),
              ]),
              _c("div", { staticClass: "item items" }, [
                _c("div", [_vm._v("支付类型")]),
                _c("div", [
                  _vm._v(
                    _vm._s(_vm.orderInfo.payType == 1 ? "微信支付" : "其他支付")
                  ),
                ]),
              ]),
              _c("div", { staticClass: "item items" }, [
                _c("div", [_vm._v("交易完成时间")]),
                _c("div", [_vm._v(_vm._s(_vm.orderInfo.payTime))]),
              ]),
              _c("div", { staticClass: "divider" }),
            ])
          : _vm._e(),
        _c("div", { staticClass: "item items" }, [
          _c("div", { staticStyle: { "font-weight": "bold" } }, [
            _vm._v("实付金额"),
          ]),
          _c(
            "div",
            {
              staticStyle: {
                color: "#165DFF",
                "font-size": "0.28rem",
                "font-weight": "bold",
              },
            },
            [
              _vm._v(
                "¥ " +
                  _vm._s(_vm.formatToDecimal(_vm.orderInfo.totalAmount)) +
                  " "
              ),
            ]
          ),
        ]),
      ]),
      _vm.orderInfo.orderStatus == 1
        ? _c(
            "div",
            {
              staticClass: "footer-toolbar",
              staticStyle: {
                display: "flex",
                "justify-content": "space-between",
              },
            },
            [
              _c(
                "button",
                {
                  staticClass: "btn btn-link",
                  staticStyle: { width: "auto", padding: "0" },
                  on: { click: _vm.handleBtnCancelOrder },
                },
                [_vm._v(" 取消订单 ")]
              ),
              _c(
                "router-link",
                {
                  staticClass: "btn btn-primary",
                  staticStyle: {
                    width: "5.40rem",
                    "flex-shrink": "0",
                    "line-height": "1.5",
                  },
                  attrs: {
                    to: {
                      name: "pay",
                      params: {
                        orderId: _vm.orderInfo.orderNo,
                      },
                    },
                  },
                },
                [
                  _c(
                    "div",
                    { staticStyle: { color: "#fff", "font-size": ".32rem" } },
                    [
                      _c(
                        "span",
                        { staticStyle: { "margin-right": "0.2rem" } },
                        [_vm._v("立即支付")]
                      ),
                      _c("span", [
                        _vm._v(
                          "¥ " +
                            _vm._s(
                              _vm.formatToDecimal(_vm.orderInfo.totalAmount)
                            )
                        ),
                      ]),
                    ]
                  ),
                  _vm.orderInfo.orderCountDown != null
                    ? _c(
                        "div",
                        {
                          staticStyle: {
                            "font-size": ".24rem",
                            color: "#BACFFF",
                          },
                        },
                        [
                          _vm._v("还剩 "),
                          _c("count-down", {
                            attrs: {
                              milliseconds: parseInt(
                                _vm.orderInfo.orderCountDown
                              ),
                            },
                            on: { timeout: _vm.handleCountDownTimeout },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }