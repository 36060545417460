var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "box" }, [
    _c("div", { staticClass: "invoicMain" }, [
      _c("div", { staticClass: "invoiceHeader" }, [
        _c("div", { staticClass: "invoiceHeader-left" }, [
          _c("div", { staticClass: "headerMiddle" }, [
            _c("h3", [_vm._v(_vm._s(_vm.data.invoiceType))]),
            _c("div", { staticClass: "line" }),
          ]),
        ]),
        _c("ul", { staticClass: "headerRight" }, [
          _c("li", [
            _c("label", [_vm._v(" 发票代码： ")]),
            _c("span", [_vm._v(_vm._s(_vm.data.invoiceCode))]),
          ]),
          _c("li", [
            _c("label", [_vm._v(" 发票号码： ")]),
            _c("span", [_vm._v(_vm._s(_vm.data.invoiceNumber))]),
          ]),
          _c("li", [
            _c("label", [_vm._v(" 开票日期： ")]),
            _c("span", [_vm._v(_vm._s(_vm.riqi))]),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "invoiceBody" }, [
        _c("div", { staticClass: "userInfo" }, [
          _c("div", { staticClass: "buy" }, [_vm._v("购买方")]),
          _c("ul", [
            _c("li", [
              _c("label", [_vm._v("名              称：")]),
              _c("span", [_vm._v(_vm._s(_vm.data.purchaserName))]),
            ]),
            _c("li", [
              _c("label", [_vm._v("地址、   电话：")]),
              _c("span", [_vm._v(_vm._s(_vm.data.purchaserAddressPhone))]),
            ]),
            _c("li", [
              _c("label", [_vm._v("开户行及账号：")]),
              _c("span", [_vm._v(_vm._s(_vm.data.purchaserBank))]),
            ]),
          ]),
        ]),
        _c("div", [
          _c(
            "table",
            {
              staticClass: "GoodsTable",
              attrs: { cellpadding: "0", cellspacing: "0" },
            },
            [
              _vm._m(0),
              _vm._l(_vm.data.sph, function (v, k) {
                return _c("tr", { key: k, staticClass: "invoice-list" }, [
                  _c("td", [_vm._v(_vm._s(v.productName))]),
                  _c("td", [_vm._v(_vm._s(v.productModel))]),
                  _c("td", [_vm._v(_vm._s(v.productUnit))]),
                  _c("td", { staticClass: "textright" }, [
                    _vm._v(_vm._s(v.productCount)),
                  ]),
                  _c("td", { staticClass: "textright" }, [
                    _vm._v(_vm._s(v.productPrice)),
                  ]),
                  _c("td", { staticClass: "textright" }, [
                    _vm._v(_vm._s(v.productTotalAmount)),
                  ]),
                  _c("td", { staticClass: "textright" }, [
                    _vm._v(_vm._s(v.productTaxRate)),
                  ]),
                  _c("td", { staticClass: "textright" }, [
                    _vm._v(_vm._s(v.productTaxAmount)),
                  ]),
                ])
              }),
              _c("tr", { staticClass: "total" }, [
                _c("td", [_vm._v("合计")]),
                _c("td"),
                _c("td"),
                _c("td"),
                _c("td"),
                _c("td", { staticClass: "textright" }, [
                  _vm._v(_vm._s(_vm.data.totalAmount)),
                ]),
                _c("td"),
                _c("td", { staticClass: "textright" }, [
                  _vm._v(_vm._s(_vm.data.taxAmount)),
                ]),
              ]),
              _c("tr", { staticClass: "GoodsTotal" }, [
                _c("td", [_vm._v("价税合计(大写)")]),
                _c("td", { attrs: { colspan: "7" } }, [
                  _c(
                    "div",
                    { staticStyle: { width: "100%", display: "flex" } },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: { width: "60%" },
                          attrs: { type: "text" },
                        },
                        [_vm._v(_vm._s(_vm.daxie))]
                      ),
                      _c(
                        "div",
                        {
                          staticStyle: { width: "30%" },
                          attrs: { type: "text" },
                        },
                        [
                          _vm._v(
                            " (小写)   ￥  " +
                              _vm._s(_vm.data.amount.toFixed(2))
                          ),
                        ]
                      ),
                    ]
                  ),
                ]),
              ]),
            ],
            2
          ),
          _c("div", { staticClass: "userInfo" }, [
            _c("div", { staticClass: "buy" }, [_vm._v("购买方")]),
            _c("ul", [
              _c("li", [
                _c("label", [_vm._v("名称：")]),
                _c("span", [_vm._v(_vm._s(_vm.data.purchaserName))]),
              ]),
              _c("li", [
                _c("label", [_vm._v("纳税人识别号：")]),
                _c("span", [_vm._v(_vm._s(_vm.data.purchaserTaxNo))]),
              ]),
              _c("li", [
                _c("label", [_vm._v("地址、   电话：")]),
                _c("span", [_vm._v(_vm._s(_vm.data.purchaserAddressPhone))]),
              ]),
              _c("li", [
                _c("label", [_vm._v("开户行及账号：")]),
                _c("span", [_vm._v(_vm._s(_vm.data.purchaserBank))]),
              ]),
            ]),
            _c("div", { staticClass: "password" }, [_vm._v("备注")]),
            _c("div", { staticClass: "pwdInfo" }, [
              _vm._v(_vm._s(_vm.data.remark)),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", { staticStyle: { width: "24%" } }, [
        _vm._v("货物或应税劳务、服务名称"),
      ]),
      _c("td", { staticStyle: { width: "10%" } }, [_vm._v("规格型号")]),
      _c("td", { staticStyle: { width: "7%" } }, [_vm._v("单位")]),
      _c("td", { staticStyle: { width: "10%" } }, [_vm._v("数量")]),
      _c("td", { staticStyle: { width: "10%" } }, [_vm._v("单价")]),
      _c("td", { staticStyle: { width: "16%" } }, [_vm._v("金额")]),
      _c("td", { staticStyle: { width: "7%" } }, [_vm._v("税率")]),
      _c("td", { staticStyle: { width: "16%", "border-right": "none" } }, [
        _vm._v("税额"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }