var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticStyle: { background: "#f5parts-headerf7fa" } }, [
    _c("div", { staticClass: "whiteStyle" }),
    _c(
      "div",
      {
        staticClass: "h5-wrapper",
        staticStyle: { padding: "12px", "font-size": "14px", display: "none" },
      },
      _vm._l(_vm.detailsList, function (v, k) {
        return _c(
          "div",
          { key: k },
          _vm._l(v.children, function (a, b) {
            return _c(
              "div",
              { key: b },
              _vm._l(a.children, function (value, index) {
                return _c(
                  "div",
                  { key: index, staticClass: "single" },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "title",
                        attrs: { id: "title" + value.id },
                      },
                      [_vm._v(_vm._s(value.titleName))]
                    ),
                    value.type == 1
                      ? _c("div", { staticClass: "shuoming" }, [
                          _vm._v("分析结果"),
                        ])
                      : _vm._e(),
                    value.type == 1
                      ? _c("div", { staticClass: "fontStyle" }, [
                          _vm._v(_vm._s(value.dataText)),
                        ])
                      : _vm._e(),
                    value.type == 1
                      ? _c("GenericTable", {
                          staticClass: "table",
                          attrs: { data: value.table },
                        })
                      : _vm._e(),
                    value.type == 2 && value.pdfDatas.ruleParamsTemplete
                      ? _c("ColumnChart", {
                          attrs: {
                            data: JSON.parse(value.pdfDatas.ruleParamsTemplete),
                          },
                        })
                      : _vm._e(),
                    value.type == 2
                      ? _c("div", { staticClass: "shuoming" }, [
                          _vm._v("风险说明"),
                        ])
                      : _vm._e(),
                    value.type == 2
                      ? _c("div", { staticClass: "fontStyle" }, [
                          _vm._v(
                            " " + _vm._s(value.pdfDatas.ruleRiskStatement) + " "
                          ),
                        ])
                      : _vm._e(),
                    value.type == 2
                      ? _c("div", { staticClass: "fangan green" }, [
                          _vm._v("应对方案"),
                        ])
                      : _vm._e(),
                    value.type == 2
                      ? _c("div", { staticClass: "fontStyle" }, [
                          _vm._v(_vm._s(value.pdfDatas.ruleAdvise)),
                        ])
                      : _vm._e(),
                    value.type == 2 && value.pdfDatas.caseText != ""
                      ? _c("div", { staticClass: "fangan" }, [
                          _vm._v(" 法规案例 "),
                        ])
                      : _vm._e(),
                    value.type == 2
                      ? _c("div", { staticClass: "fontStyle" }, [
                          _vm._v(_vm._s(value.pdfDatas.caseText)),
                        ])
                      : _vm._e(),
                  ],
                  1
                )
              }),
              0
            )
          }),
          0
        )
      }),
      0
    ),
    _c(
      "div",
      { staticClass: "parts" },
      _vm._l(_vm.parts, function (item, index) {
        return _c(
          "div",
          { key: item.index, staticClass: "parts-content" },
          [
            _c("div", { staticClass: "parts-header" }, [
              _vm._v(_vm._s(index + 1) + " " + _vm._s(item.name)),
            ]),
            _c("div", { staticClass: "parts-header-line" }),
            _vm._l(item.children, function (item2, index) {
              return _c(
                "div",
                { key: item2.index, staticClass: "parts-header-two" },
                [
                  _c("div", { staticClass: "left-line" }),
                  _c("div", { staticClass: "title" }, [
                    _vm._v(
                      _vm._s(index + 1 + "." + item2.orders) +
                        " " +
                        _vm._s(item2.name)
                    ),
                  ]),
                ]
              )
            }),
            _vm._l(item.children, function (item2, index) {
              return _c(
                "div",
                {
                  key: item2.index,
                  staticClass: "onAnalysis",
                  class: _vm.riskLevel == 1 ? "redOne" : "yellowOne",
                },
                [
                  _vm.riskLevel != 0
                    ? _c(
                        "div",
                        {
                          staticClass: "onAnalysis-icon",
                          class: _vm.riskLevel == 1 ? "redTwo" : "yellowOne",
                        },
                        [
                          _c(
                            "span",
                            {
                              class:
                                _vm.riskLevel == 1 ? "redThree" : "yellowOne",
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.riskLevel == 1
                                    ? "高"
                                    : _vm.riskLevel == 2
                                    ? "中"
                                    : _vm.riskLevel == 3
                                    ? "低"
                                    : ""
                                ) + _vm._s(item2.data.riskLevel)
                              ),
                            ]
                          ),
                        ]
                      )
                    : _vm._e(),
                  _c("div", { staticClass: "onAnalysis-body" }, [
                    _c("div", { staticClass: "title" }, [_vm._v("分析结果")]),
                    _c("div", { staticClass: "onAnalysis-content" }, [
                      _c("span", [_vm._v(" " + _vm._s(item2.data))]),
                    ]),
                    _vm.riskLevel == 0
                      ? _c("img", {
                          attrs: {
                            src: require("../../../../assets/img_gfx@2x.png"),
                            alt: "",
                          },
                        })
                      : _vm._e(),
                    _vm.riskLevel == 1
                      ? _c("img", {
                          attrs: {
                            src: require("../../../../assets/img_gfx@2x (1).png"),
                            alt: "",
                          },
                        })
                      : _vm._e(),
                    _vm.riskLevel == 2
                      ? _c("img", {
                          attrs: {
                            src: require("../../../../assets/img_gfx@2x (2).png"),
                            alt: "",
                          },
                        })
                      : _vm._e(),
                    _vm.riskLevel == 3
                      ? _c("img", {
                          attrs: {
                            src: require("../../../../assets/img_gfx@2x (3).png"),
                            alt: "",
                          },
                        })
                      : _vm._e(),
                  ]),
                ]
              )
            }),
            _vm._l(item.children, function (item2, index) {
              return _c(
                "div",
                { key: item2.index, staticClass: "verificationIdeas" },
                [
                  _c("div", { staticClass: "title" }, [_vm._v("核实思路")]),
                  _c("div", { staticClass: "verificationIdeas-content" }, [
                    _vm._v(" " + _vm._s(item2.initData) + " "),
                  ]),
                ]
              )
            }),
          ],
          2
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }