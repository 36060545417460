var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "van-popup",
        {
          style: { height: "7.5rem" },
          attrs: { closeable: "", round: "", position: "bottom" },
          model: {
            value: _vm.vanPopupShareShow,
            callback: function ($$v) {
              _vm.vanPopupShareShow = $$v
            },
            expression: "vanPopupShareShow",
          },
        },
        [
          _c("div", { staticClass: "shareDiv" }, [
            _c("div", { staticClass: "shareTitle" }, [_vm._v("邀请他人填写")]),
            _c("div", { staticClass: "codeboX" }, [
              _c("span", [
                _c("span", { staticStyle: { "margin-right": "0.05rem" } }, [
                  _vm._v(_vm._s(_vm.phoneNumber)),
                ]),
                _vm._v(" 邀请您授权数据，请扫码完成操作"),
              ]),
              _c("img", {
                attrs: { src: _vm.imageUrl ? _vm.imageUrl : "", alt: "" },
              }),
            ]),
            _c("div", { staticClass: "copyBox" }, [
              _c("span", [_vm._v("您可复制链接或截图发送给被邀请者")]),
              _c(
                "button",
                { staticClass: "copy", on: { click: _vm.copyLink } },
                [_vm._v("复制")]
              ),
            ]),
            _c("div", [
              _vm._v(
                " 退出当前页后，可在【检测记录】中找到本次检测，并查看到授权状态 "
              ),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }