var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "try" }, [
    _c("div", { staticClass: "item", class: { itemType2: _vm.type == 2 } }, [
      _c("div", { staticClass: "link" }, [_vm._v("联系人")]),
      _c(
        "div",
        { staticClass: "item-input" },
        [
          _c("el-input", {
            attrs: { placeholder: "请输入真实姓名" },
            model: {
              value: _vm.name,
              callback: function ($$v) {
                _vm.name = typeof $$v === "string" ? $$v.trim() : $$v
              },
              expression: "name",
            },
          }),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "item", class: { itemType2: _vm.type == 2 } }, [
      _c("div", { staticClass: "link" }, [_vm._v("联系方式")]),
      _c(
        "div",
        { staticClass: "item-input" },
        [
          _c("el-input", {
            attrs: { placeholder: "请输入手机号码" },
            model: {
              value: _vm.phone,
              callback: function ($$v) {
                _vm.phone = typeof $$v === "string" ? $$v.trim() : $$v
              },
              expression: "phone",
            },
          }),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "item", class: { itemType2: _vm.type == 2 } }, [
      _c("div", { staticClass: "links" }, [_vm._v("需求描述")]),
      _c(
        "div",
        { staticClass: "item-inputs" },
        [
          _c("el-input", {
            attrs: {
              type: "textarea",
              maxlength: "15",
              "show-word-limit": "",
              rows: "3",
              placeholder: "请简要描述您的需求",
            },
            model: {
              value: _vm.detail,
              callback: function ($$v) {
                _vm.detail = typeof $$v === "string" ? $$v.trim() : $$v
              },
              expression: "detail",
            },
          }),
        ],
        1
      ),
    ]),
    _c(
      "div",
      {
        staticClass: "btn-item",
        class: { btnItem2: _vm.type != 2 },
        on: { click: _vm.submit },
      },
      [_vm._v("提交")]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }