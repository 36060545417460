var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "content-section" }, [
      _c("div", { staticClass: "title" }, [
        _vm._v(_vm._s(this.data.modelName)),
      ]),
      this.data.text
        ? _c("div", { staticClass: "danger" }, [
            _c("div", [_vm._v(_vm._s(this.data.text))]),
          ])
        : _vm._e(),
    ]),
    _c("div", {
      ref: "theChart",
      attrs: { className: "echarts1", id: "echarts1" },
    }),
    _c("div", [
      _c("table", { staticClass: "table" }, [
        _c(
          "tbody",
          [
            _c("tr", [
              _c(
                "td",
                { staticClass: "headTable" },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        this.data.data2.ruleOfInvoiceImageList[0].imageTitle
                      ) +
                      " "
                  ),
                  _vm._l(this.data.data2.body, function (item) {
                    return _c(
                      "i",
                      [
                        _c(
                          "el-tooltip",
                          {
                            staticClass: "item",
                            attrs: { effect: "dark", content: item[0].tip },
                          },
                          [
                            _c("i", {
                              staticClass: "el-icon-warning",
                              staticStyle: { color: "#4e5969" },
                            }),
                          ]
                        ),
                      ],
                      1
                    )
                  }),
                ],
                2
              ),
              _c("td", { staticClass: "headTable" }, [_vm._v("本企业")]),
              _c("td", { staticClass: "headTable" }, [_vm._v("行业平均")]),
            ]),
            _vm._l(this.data.data2.body, function (item) {
              return _c("tr", [
                _c("td", [_vm._v(_vm._s(_vm.ruleOfInvoiceImageList[0]))]),
                _c("td", [_vm._v(_vm._s(item[1].content))]),
                _c("td", [_vm._v(_vm._s(item[2].content))]),
              ])
            }),
            _vm._l(this.data.data2.body, function (item) {
              return _c("tr", [
                _c("td", [_vm._v(_vm._s(_vm.ruleOfInvoiceImageList[1]))]),
                _c("td", [_vm._v(_vm._s(item[3].content))]),
                _c("td", [_vm._v(_vm._s(item[4].content))]),
              ])
            }),
            _vm._l(this.data.data2.body, function (item) {
              return _c("tr", [
                _c("td", [_vm._v(_vm._s(_vm.ruleOfInvoiceImageList[2]))]),
                _c("td", [_vm._v(_vm._s(item[5].content))]),
                _c("td", [_vm._v(_vm._s(item[6].content))]),
              ])
            }),
          ],
          2
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }