var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "orderInformation" },
    [
      _c("div", { staticClass: "topContent" }, [
        _c("div", { staticClass: "selectButton" }, [
          _c(
            "div",
            {
              class:
                _vm.state != "data_authorize" &&
                _vm.state != "data_manual_upload"
                  ? "disabled"
                  : _vm.state == "data_authorize"
                  ? "selected"
                  : "",
              on: {
                click: function ($event) {
                  return _vm.stateChange("data_authorize")
                },
              },
            },
            [
              _vm._v("授权自动采集数据 "),
              _vm.autoFinish
                ? _c("i", { staticClass: "iconfont icon-icon_succeed" })
                : _vm._e(),
            ]
          ),
          _vm.withHands
            ? _c(
                "div",
                {
                  class:
                    _vm.state != "data_authorize" &&
                    _vm.state != "data_manual_upload"
                      ? "disabled"
                      : _vm.state == "data_manual_upload"
                      ? "selected"
                      : "",
                  on: {
                    click: function ($event) {
                      return _vm.stateChange("data_manual_upload")
                    },
                  },
                },
                [
                  _vm._v("手动补充数据 "),
                  _vm.handsFinish
                    ? _c("i", { staticClass: "iconfont icon-icon_succeed" })
                    : _vm._e(),
                ]
              )
            : _vm._e(),
        ]),
        _c("img", {
          staticClass: "little",
          attrs: { src: require("../assets/jiantou02.png"), alt: "" },
        }),
        _c("div", { staticClass: "selectButton" }, [
          _c(
            "div",
            { class: _vm.state == "analyze" ? "selected" : "disabled" },
            [_vm._v("统计分析")]
          ),
        ]),
        _c("img", {
          staticClass: "little",
          attrs: { src: require("../assets/jiantou02.png"), alt: "" },
        }),
        _c("div", { staticClass: "selectButton" }, [
          _c(
            "div",
            { class: _vm.state == "generate" ? "selected" : "disabled" },
            [_vm._v("生成报告")]
          ),
        ]),
      ]),
      !_vm.autoFinish
        ? _c("iframe", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.state == "data_authorize",
                expression: "state == 'data_authorize'",
              },
            ],
            ref: "iframeRef",
            staticClass: "fetchBaseUrl",
            attrs: { src: _vm.fetchBaseUrl },
          })
        : _vm.state == "data_authorize" && _vm.autoFinish
        ? _c("div", { staticClass: "imgbox" }, [
            _c("img", {
              attrs: { src: require("../assets/autoing.png"), alt: "" },
            }),
            _vm._m(0),
            _vm._m(1),
          ])
        : _vm._e(),
      _vm.state == "data_manual_upload"
        ? _c("div", { staticClass: "imgbox" }, [
            _c("img", {
              attrs: { src: require("../assets/noposter.png"), alt: "" },
            }),
            _c("div", { staticClass: "imgTitle" }, [
              _vm._v("当前页面交互较复杂，请到电脑端操作或跳过进入下一环节"),
            ]),
            _vm._m(2),
          ])
        : _vm._e(),
      _vm.state == "analyze"
        ? _c("div", { staticClass: "imgbox" }, [
            _c("img", {
              attrs: { src: require("../assets/noposter.png"), alt: "" },
            }),
            _c("div", { staticClass: "imgTitle" }, [
              _vm._v("报告生成中，请稍候…"),
            ]),
          ])
        : _vm._e(),
      _vm.state == "generate"
        ? _c("div", { staticClass: "imgbox" }, [
            _c("img", {
              attrs: { src: require("../assets/reportSuccess.png"), alt: "" },
            }),
            _c("div", { staticClass: "imgTitle" }, [
              _vm._v("报告已生成，请前往查看报告"),
            ]),
            _c(
              "div",
              {
                staticClass: "copy",
                staticStyle: { width: "4rem", "margin-top": "0.6rem" },
                on: { click: _vm.toReport },
              },
              [_vm._v(" 查看报告 ")]
            ),
          ])
        : _vm._e(),
      _vm.state == "data_authorize" && !_vm.autoFinish
        ? _c("div", { staticClass: "tips", on: { click: _vm.openTips } }, [
            _c("i", { staticClass: "iconfont icon-icon_tishi" }),
            _c("span", { staticStyle: { "margin-top": "6px" } }, [
              _vm._v("温馨提示"),
            ]),
          ])
        : _vm._e(),
      _c("shouquan", { ref: "shouquanRef", on: { read: _vm.read } }),
      _c("sharePopup", { ref: "sharePopupRef" }),
      _c("waitloading", { ref: "waitloadingref" }),
      _c(
        "van-dialog",
        {
          attrs: {
            title: "温馨提示",
            "cancel-button-color": "#165DFF",
            "confirm-button-text": "知道了",
            "confirm-button-color": "#fff",
          },
          model: {
            value: _vm.showDialog,
            callback: function ($$v) {
              _vm.showDialog = $$v
            },
            expression: "showDialog",
          },
        },
        [
          _c("div", { staticClass: "dialogContent" }, [
            _c("div", [
              _vm._v(
                "1、在检测过程中，请勿使用该公司账号登录电子税务局，或者在其他平台进行数据授权，否则数据采集将被中断。"
              ),
            ]),
            _c("div", [
              _vm._v(
                "2、当同一个办税员绑定多家企业时，系统不支持同时授权，否则可能导致数据采集中断，无法生成报告。"
              ),
            ]),
          ]),
        ]
      ),
      _c(
        "van-dialog",
        {
          attrs: {
            title: "温馨提示",
            "cancel-button-color": "#165DFF",
            "confirm-button-text": "知道了",
            "confirm-button-color": "#fff",
          },
          model: {
            value: _vm.showDialog2,
            callback: function ($$v) {
              _vm.showDialog2 = $$v
            },
            expression: "showDialog2",
          },
        },
        [
          _c("div", { staticClass: "dialogContent" }, [
            _c("div", [
              _vm._v("无法选择，报告生成后可在【数据分析工具】中查看或编辑"),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "imgTitle" }, [
      _vm._v("数据自动采集中，预计 "),
      _c("span", { staticClass: "imgNumber" }, [_vm._v("2")]),
      _vm._v(" 小时内完成"),
      _c("br"),
      _vm._v("过程中可关闭浏览器"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "imgTip" }, [
      _c("p", [
        _vm._v(
          "• 在检测过程中，请勿使用该公司账号登录电子税务局，或者在其他平台进行数据授权，否则数据采集将被中断。"
        ),
      ]),
      _c("p", [
        _vm._v(
          "• 当同一个办税员绑定多家企业时，系统不支持同时授权，否则可能导致数据采集中断，无法生成报告。"
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "imgTip" }, [
      _c("p", [
        _vm._v(
          "• 手动补充数据指导入序时账、余额表等内容，从而提升检测报告的准确度。"
        ),
      ]),
      _c("p", [
        _vm._v(
          "• 当前操作不适合手机端，故系统将自动跳过这一步。您可以在报告生成后，前往电脑端补充数据。 "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }