var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "orderInformation" },
    [
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticStyle: { "margin-bottom": "3rem" },
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-width": "150px",
            id: "demo-ruleForm",
          },
        },
        [
          _c("div", { staticClass: "title" }, [
            _c("div", [_vm._v("基础信息设置")]),
          ]),
          _c(
            "el-form-item",
            { staticClass: "demo-ruleForm", attrs: { prop: "name" } },
            [
              _c("div", { staticClass: "enterpriseName" }, [
                _vm._v("海报名称"),
                _c("span", [_vm._v("*")]),
              ]),
              _c("el-input", {
                attrs: {
                  placeholder: "请输入海报名称，最多10个字",
                  maxlength: "10",
                },
                model: {
                  value: _vm.ruleForm.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "name", $$v)
                  },
                  expression: "ruleForm.name",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "name" } },
            [
              _c("div", { staticClass: "enterpriseName" }, [
                _vm._v("联系人"),
                _c("span", [_vm._v("*")]),
              ]),
              _c("el-input", {
                attrs: { placeholder: "请输入推广人姓名" },
                model: {
                  value: _vm.ruleForm.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "name", $$v)
                  },
                  expression: "ruleForm.name",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "name" } },
            [
              _c("div", { staticClass: "enterpriseName" }, [
                _vm._v("联系方式"),
                _c("span", [_vm._v("*")]),
              ]),
              _c("el-input", {
                attrs: { placeholder: "请输入推广人联系方式" },
                model: {
                  value: _vm.ruleForm.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "name", $$v)
                  },
                  expression: "ruleForm.name",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "value1" } },
            [
              _c("div", { staticClass: "enterpriseName" }, [
                _vm._v("海报有效期"),
                _c("span", [_vm._v("*")]),
              ]),
              _c("el-date-picker", {
                attrs: { type: "date", placeholder: "请选择海报有效期" },
                model: {
                  value: _vm.ruleForm.value1,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "value1", $$v)
                  },
                  expression: "ruleForm.value1",
                },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "title" }, [
            _c("div", [_vm._v("产品信息设置")]),
          ]),
          _c(
            "el-form-item",
            { staticClass: "demo-ruleForm", attrs: { prop: "name" } },
            [
              _c("div", { staticClass: "enterpriseName" }, [
                _vm._v("产品名称"),
                _c("span", [_vm._v("*")]),
              ]),
              _c("el-input", {
                attrs: { placeholder: "请选择要推广的产品" },
                model: {
                  value: _vm.ruleForm.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "name", $$v)
                  },
                  expression: "ruleForm.name",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { staticClass: "demo-ruleForm", attrs: { prop: "name" } },
            [
              _c("div", { staticClass: "enterpriseName" }, [
                _vm._v("报告单价"),
                _c("span", [_vm._v("*")]),
              ]),
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择产品售卖单价" },
                  model: {
                    value: _vm.ruleForm.region,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "region", $$v)
                    },
                    expression: "ruleForm.region",
                  },
                },
                [
                  _c("el-option", {
                    attrs: { label: "区域一", value: "shanghai" },
                  }),
                  _c("el-option", {
                    attrs: { label: "区域二", value: "beijing" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { staticClass: "demo-ruleForm", attrs: { prop: "name" } },
            [
              _c("div", { staticClass: "enterpriseName" }, [
                _vm._v("用户数量"),
                _c("span", [_vm._v("*")]),
              ]),
              _c("el-input", {
                attrs: { placeholder: "请输入可购买产品的用户数量" },
                model: {
                  value: _vm.ruleForm.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "name", $$v)
                  },
                  expression: "ruleForm.name",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { staticClass: "demo-ruleForm", attrs: { prop: "name" } },
            [
              _c("div", { staticClass: "enterpriseName" }, [
                _vm._v("检测次数"),
                _c("span", [_vm._v("*")]),
              ]),
              _c("el-input", {
                attrs: { placeholder: "请输入每位用户购买后可获得的检测次数" },
                model: {
                  value: _vm.ruleForm.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "name", $$v)
                  },
                  expression: "ruleForm.name",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { staticClass: "demo-ruleForm", attrs: { prop: "name" } },
            [
              _c("div", { staticClass: "enterpriseName" }, [
                _vm._v("检测时效"),
                _c("span", [_vm._v("*")]),
              ]),
              _c("el-input", {
                attrs: { placeholder: "请输入用户获得的检测次数有效期" },
                model: {
                  value: _vm.ruleForm.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "name", $$v)
                  },
                  expression: "ruleForm.name",
                },
              }),
            ],
            1
          ),
          _c("div", [_c("div", { staticStyle: { height: "0.18rem" } })]),
        ],
        1
      ),
      _c("div", { staticClass: "btn2" }, [
        _vm._m(0),
        _c("div", { staticClass: "btnList" }, [
          _c(
            "div",
            {
              staticClass: "order",
              on: {
                click: function ($event) {
                  return _vm.previewPoster("ruleForm")
                },
              },
            },
            [_vm._v("预览海报")]
          ),
          _c(
            "div",
            {
              staticClass: "cost",
              on: {
                click: function ($event) {
                  return _vm.submitForm("ruleForm")
                },
              },
            },
            [_vm._v("生成海报")]
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "posterTip" }, [
      _c("i", { staticClass: "el-icon-warning" }),
      _vm._v("上述信息填写完整后可预览再生成海报！ "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }