var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "my" },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showFixBottom,
              expression: "showFixBottom",
            },
          ],
          staticClass: "fixBottom",
        },
        [
          _c("span", [_vm._v("可登录到电脑端查看报告")]),
          _c("div", [
            _c("div", { staticClass: "buttons", on: { click: _vm.copy } }, [
              _vm._v("复制登录地址"),
            ]),
            _c("i", {
              staticClass: "iconfont icon-icon_close",
              on: {
                click: function ($event) {
                  _vm.showFixBottom = false
                },
              },
            }),
          ]),
        ]
      ),
      _c("card", {
        staticClass: "card",
        on: { changeActive: _vm.changeActive },
      }),
      _vm.active == 1
        ? _c("div", { staticClass: "card-item card-item-pay" }, [
            _c("div", { staticClass: "pay-title" }, [
              _c(
                "div",
                {
                  class: { payActive: _vm.payStatusType == 0 },
                  on: {
                    click: function ($event) {
                      return _vm.payStatus(0)
                    },
                  },
                },
                [_vm._v(" 全部(" + _vm._s(_vm.payListTotal.length) + ") ")]
              ),
              _c(
                "div",
                {
                  class: { payActive: _vm.payStatusType == 1 },
                  on: {
                    click: function ($event) {
                      return _vm.payStatus(1)
                    },
                  },
                },
                [
                  _vm._v(
                    " 待支付(" + _vm._s(_vm.payListWaitForPay.length) + ") "
                  ),
                ]
              ),
              _c(
                "div",
                {
                  class: { payActive: _vm.payStatusType == 2 },
                  on: {
                    click: function ($event) {
                      return _vm.payStatus(2)
                    },
                  },
                },
                [_vm._v(" 已完成(" + _vm._s(_vm.payListSuccess.length) + ") ")]
              ),
              _c(
                "div",
                {
                  class: { payActive: _vm.payStatusType == -1 },
                  on: {
                    click: function ($event) {
                      return _vm.payStatus(-1)
                    },
                  },
                },
                [_vm._v(" 已取消(" + _vm._s(_vm.payListCancel.length) + ") ")]
              ),
            ]),
            _vm.payList && _vm.payList.length > 0
              ? _c(
                  "div",
                  [
                    _vm._l(_vm.payList, function (item) {
                      return [
                        _c("pay-item", {
                          staticClass: "pay-item",
                          attrs: { item: item },
                        }),
                      ]
                    }),
                  ],
                  2
                )
              : _c("div", { staticClass: "nodata" }, [
                  _c("img", {
                    attrs: { src: require("@/assets/no-data.png"), alt: "" },
                  }),
                  _c("div", { staticClass: "nodata-title" }, [
                    _vm._v("还没有提交过订单信息哦～"),
                  ]),
                  _c(
                    "div",
                    { staticClass: "nodataBtn", on: { click: _vm.toIndex } },
                    [_vm._v("立即购买")]
                  ),
                ]),
          ])
        : _vm._e(),
      _vm.active == 2
        ? _c(
            "div",
            { staticClass: "card-item" },
            [_c("tryInfo", { staticClass: "try-input" }), _vm._m(0)],
            1
          )
        : _vm._e(),
      _vm.active == 3
        ? _c(
            "div",
            { staticClass: "card-item" },
            [
              _c(
                "van-swipe",
                {
                  ref: "swipe",
                  staticClass: "swipeImg",
                  attrs: { loop: false, "stop-propagation": false },
                },
                [
                  _vm._l(_vm.bankData, function (item, index) {
                    return [
                      index % 2 == 0
                        ? _c("van-swipe-item", { key: index }, [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "justify-content": "space-between",
                                  margin: "0 0.2rem",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "cardList",
                                    attrs: { id: "colorList" + index },
                                    on: {
                                      touchmove: _vm.onTouchMove,
                                      touchstart: _vm.onTouchStart,
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "top-img",
                                        style: {
                                          background:
                                            "url(" + item.showImageUrl + ")",
                                          "background-size": "100% 100%",
                                        },
                                      },
                                      [
                                        _c("img", {
                                          staticClass: "version-logo",
                                          attrs: {
                                            src: _vm.getCornerPic(item.skuAttr),
                                            alt: "",
                                          },
                                        }),
                                        _c(
                                          "div",
                                          { staticClass: "mask-content" },
                                          [
                                            _c("p", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getAttrVal(
                                                    _vm.bankData[index].skuAttr,
                                                    "chargeMode"
                                                  )
                                                )
                                              ),
                                            ]),
                                            _c("p", [
                                              _vm._v(
                                                "有效期：" +
                                                  _vm._s(
                                                    _vm.getAttrVal(
                                                      _vm.bankData[index]
                                                        .skuAttr,
                                                      "validity"
                                                    )
                                                  ) +
                                                  "   "
                                              ),
                                              _c("span", [
                                                _vm._v(
                                                  "可用数：" +
                                                    _vm._s(
                                                      _vm.bankData[index]
                                                        .usableNum
                                                    )
                                                ),
                                              ]),
                                            ]),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c("div", { staticClass: "bckDiv" }, [
                                      _c("p", [
                                        _vm._v(
                                          _vm._s(_vm.bankData[index].goodsName)
                                        ),
                                      ]),
                                      _c(
                                        "span",
                                        {
                                          staticClass: "ImmediateDetection",
                                          on: {
                                            click: function ($event) {
                                              return _vm.goDetection(
                                                _vm.bankData[index]
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("立即检测")]
                                      ),
                                    ]),
                                  ]
                                ),
                                _vm.bankData[index + 1]
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "cardList",
                                        attrs: {
                                          id: "colorList" + [index + 1],
                                        },
                                        on: {
                                          touchmove: _vm.onTouchMove,
                                          touchstart: _vm.onTouchStart,
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "top-img",
                                            style: {
                                              background:
                                                "url(" +
                                                _vm.bankData[index + 1]
                                                  .showImageUrl +
                                                ")",
                                              "background-size": "100% 100%",
                                            },
                                          },
                                          [
                                            _c("img", {
                                              staticClass: "version-logo",
                                              attrs: {
                                                src: _vm.getCornerPic(
                                                  _vm.bankData[index + 1]
                                                    .skuAttr
                                                ),
                                                alt: "",
                                              },
                                            }),
                                            _c(
                                              "div",
                                              { staticClass: "mask-content" },
                                              [
                                                _c("p", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.getAttrVal(
                                                        _vm.bankData[index + 1]
                                                          .skuAttr,
                                                        "chargeMode"
                                                      )
                                                    )
                                                  ),
                                                ]),
                                                _c("p", [
                                                  _vm._v(
                                                    "有效期：" +
                                                      _vm._s(
                                                        _vm.getAttrVal(
                                                          _vm.bankData[
                                                            index + 1
                                                          ].skuAttr,
                                                          "validity"
                                                        )
                                                      ) +
                                                      "   "
                                                  ),
                                                  _c("span", [
                                                    _vm._v(
                                                      "可用数：" +
                                                        _vm._s(
                                                          _vm.bankData[
                                                            index + 1
                                                          ].usableNum
                                                        )
                                                    ),
                                                  ]),
                                                ]),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c("div", { staticClass: "bckDiv" }, [
                                          _c("p", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.bankData[index + 1]
                                                  .goodsName
                                              )
                                            ),
                                          ]),
                                          _c(
                                            "span",
                                            {
                                              staticClass: "ImmediateDetection",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.goDetection(
                                                    _vm.bankData[index + 1]
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("立即检测")]
                                          ),
                                        ]),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            ),
                          ])
                        : _vm._e(),
                    ]
                  }),
                ],
                2
              ),
              _c("div", { staticClass: "mobileIcon" }, [
                _vm.showArrow
                  ? _c("img", {
                      staticClass: "arrow-left",
                      attrs: {
                        src: require("@/assets/arrow_left.png"),
                        alt: "",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.changeSwiper("left")
                        },
                      },
                    })
                  : _vm._e(),
                _vm.showArrow
                  ? _c("img", {
                      staticClass: "arrow-right",
                      attrs: {
                        src: require("@/assets/arrow_right.png"),
                        alt: "",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.changeSwiper("right")
                        },
                      },
                    })
                  : _vm._e(),
              ]),
              _c("div", { staticClass: "detection" }, [_vm._v("检测记录")]),
              _vm.dataPage == ""
                ? _c("div", { staticClass: "nullPic" }, [
                    _c("img", {
                      attrs: { src: require("@/assets/暂无数据.png"), alt: "" },
                    }),
                    _vm._v(" 您还没有发起过风险检测哦～ "),
                  ])
                : _vm._l(_vm.dataPage, function (item, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass: "detectionCard",
                        class: index == 0 ? "topStyle" : "",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "cardTime",
                            on: {
                              click: function ($event) {
                                return _vm.clickStatusTip(item)
                              },
                            },
                          },
                          [
                            _c("div", { staticClass: "haibaoPic" }, [
                              _c("img", {
                                staticClass: "backPic",
                                attrs: { src: item.showImageUrl, alt: "" },
                              }),
                              _c("img", {
                                staticClass: "version-logo",
                                attrs: {
                                  src: _vm.getCornerPic(item.attrs),
                                  alt: "",
                                },
                              }),
                            ]),
                            _c(
                              "div",
                              { staticStyle: { "margin-left": "0.3rem" } },
                              [
                                _c("div", { staticClass: "timeOne" }, [
                                  _vm._v(_vm._s(item.taxName)),
                                ]),
                                _c("div", { staticClass: "timeTwo" }, [
                                  _vm._v(" " + _vm._s(item.goodName) + " "),
                                ]),
                                _c("div", { staticClass: "timeThree" }, [
                                  _vm._v(_vm._s(item.createTime)),
                                ]),
                              ]
                            ),
                            _c("div", { staticClass: "status" }, [
                              item.status == 0 || item.status == 1
                                ? _c("div", { staticClass: "statusBlue" }, [
                                    _c("div", {
                                      staticClass: "statusCircleBlue",
                                    }),
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.statusList.get(item.status)
                                        ) +
                                        " "
                                    ),
                                  ])
                                : item.status == -2
                                ? _c("div", { staticClass: "statusGray" }, [
                                    _c("div", {
                                      staticClass: "statusCircleGray",
                                    }),
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.statusList.get(item.status)
                                        ) +
                                        " "
                                    ),
                                  ])
                                : item.status == 3
                                ? _c("div", { staticClass: "statusYellow" }, [
                                    _c("div", {
                                      staticClass: "statusCircleYellow",
                                    }),
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.statusList.get(item.status)
                                        ) +
                                        " "
                                    ),
                                  ])
                                : _c("div", { staticClass: "statusGreen" }, [
                                    _c("div", {
                                      staticClass: "statusCircleGreen",
                                    }),
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.statusList.get(item.status)
                                        ) +
                                        " "
                                    ),
                                  ]),
                            ]),
                          ]
                        ),
                      ]
                    )
                  }),
            ],
            2
          )
        : _vm._e(),
      _vm.active == 4 ? _c("div", {}, [_c("promotionPoster")], 1) : _vm._e(),
      _c(
        "div",
        [
          _c(
            "van-dialog",
            {
              attrs: {
                title: "温馨提示",
                "show-cancel-button": "",
                confirmButtonText: "立即购买",
                cancelButtonText: "稍后再说",
                className: "VantDialog",
                messageAlign: "center",
              },
              on: { confirm: _vm.goProductOrder },
              model: {
                value: _vm.showTitle,
                callback: function ($$v) {
                  _vm.showTitle = $$v
                },
                expression: "showTitle",
              },
            },
            [_c("div", [_vm._v("当前剩余检测次数为 0")])]
          ),
        ],
        1
      ),
      _c(
        "van-popup",
        {
          style: { height: "calc(100vh - 100px)" },
          attrs: { closeable: "", round: "", position: "bottom" },
          model: {
            value: _vm.vanPopupShareShow,
            callback: function ($$v) {
              _vm.vanPopupShareShow = $$v
            },
            expression: "vanPopupShareShow",
          },
        },
        [
          _c("div", { staticClass: "shareDiv" }, [
            _c("div", { staticClass: "shareTitle" }, [_vm._v("报告详情")]),
            _c("div", { staticClass: "toptips" }, [
              _c("i", { staticClass: "iconfont icon-icon_warn" }),
              _c("div", [
                _vm._v(
                  " 《专业版3.0》报告内容交互较多，当前查看报告具体内容需登录电脑端系统，请“复制链接”后按以下步骤操作! "
                ),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "shareInput" },
              [
                _c(
                  "el-input",
                  {
                    staticStyle: { width: "345px" },
                    attrs: { placeholder: "请输入内容" },
                    model: {
                      value: _vm.loginUrl,
                      callback: function ($$v) {
                        _vm.loginUrl = $$v
                      },
                      expression: "loginUrl",
                    },
                  },
                  [
                    _c(
                      "el-button",
                      {
                        staticStyle: {
                          background: "#165dff",
                          color: "#fff",
                          "border-radius": "0px 8px 8px 0px",
                        },
                        attrs: { slot: "append" },
                        on: { click: _vm.loginMsgdial },
                        slot: "append",
                      },
                      [_vm._v("复制链接 ")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("div", { staticClass: "gekai" }),
            _c("div", { staticClass: "sharePng" }, [
              _c("img", { attrs: { src: require("@/assets/step.png") } }),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "qr-code" }, [
      _c("div", { staticClass: "wx-code" }, [
        _c("img", {
          attrs: { src: require("@/assets/link-code.png"), alt: "" },
        }),
      ]),
      _c("div", [_vm._v("长按二维码添加专属客服！")]),
      _c("div", { staticClass: "phone" }, [_vm._v("联系电话：0571-85055930")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }