var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.goodsList && _vm.goodsList.length > 0
    ? _c(
        "div",
        { staticClass: "home-page", attrs: { id: "target" } },
        [
          _c("login", {
            key: _vm.keyData,
            staticClass: "home-login",
            attrs: { goodsList: _vm.goodsList[_vm.swipeIndex] },
            on: { addPhone: _vm.addPhone },
          }),
          _vm.isMobile
            ? _c("mobile", {
                staticClass: "home-mobile",
                on: {
                  closeMobile: _vm.closeMobile,
                  bindPhoneWx: _vm.bindPhoneWx,
                },
              })
            : _vm._e(),
          _c("img", {
            staticClass: "arrow-left",
            attrs: { src: require("@/assets/arrow_left.png"), alt: "" },
            on: {
              click: function ($event) {
                return _vm.changeSwiper("left")
              },
            },
          }),
          _c("img", {
            staticClass: "arrow-right",
            attrs: { src: require("@/assets/arrow_right.png"), alt: "" },
            on: {
              click: function ($event) {
                return _vm.changeSwiper("right")
              },
            },
          }),
          _c("div", { staticClass: "home-pages" }, [
            _vm._v(
              _vm._s(_vm.swipeIndex + 1) + "/" + _vm._s(_vm.goodsList.length)
            ),
          ]),
          _c(
            "mt-swipe",
            {
              ref: "swipe",
              style: { height: `${_vm.swiperHieght}px` },
              attrs: { auto: 0, "show-indicators": false },
              on: { change: _vm.IMGChange },
            },
            _vm._l(_vm.goodsList, function (item) {
              return _c("mt-swipe-item", { key: item.id }, [
                _c("img", {
                  ref: "img",
                  refInFor: true,
                  staticClass: "swiper-img",
                  attrs: { src: item.posterUrl, alt: "" },
                }),
              ])
            }),
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }