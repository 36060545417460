var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "table-wrapper" }, [
    _c(
      "div",
      {
        staticStyle: {
          "overflow-x": "hidden",
          width: "100%",
          position: "relative",
        },
      },
      [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isOverFlowed,
                expression: "isOverFlowed",
              },
            ],
            staticClass: "fake",
            style: {
              "padding-right": `${_vm.scrollBarWidth}px`,
              height: `${_vm.tableHeaderHeight}px`,
              left: -_vm.realTableWrapperscrollLeft + "px",
            },
          },
          [
            _c(
              "table",
              { staticClass: "table", style: { width: _vm.tableWidth + "px" } },
              [
                _c(
                  "thead",
                  _vm._l(_vm.table.header, function (row, rowIndex) {
                    return _c(
                      "tr",
                      { key: rowIndex },
                      _vm._l(row, function (col, colIndex) {
                        return !col._removed
                          ? _c(
                              "th",
                              {
                                class: { removed: col._removed },
                                style: _vm.getThStyle(col),
                                attrs: {
                                  rowspan: col.rowspan,
                                  colspan: col.colspan,
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "content",
                                    attrs: { title: col.content },
                                  },
                                  [
                                    _vm._v(" " + _vm._s(col.content) + " "),
                                    col.tip
                                      ? [
                                          _c(
                                            "el-tooltip",
                                            {
                                              staticClass: "item",
                                              attrs: {
                                                effect: "dark",
                                                placement: "top",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  attrs: { slot: "content" },
                                                  slot: "content",
                                                },
                                                [_vm._v(_vm._s(col.tip))]
                                              ),
                                              _c("i", {
                                                staticClass:
                                                  "iconfont iconicon_tips",
                                              }),
                                            ]
                                          ),
                                        ]
                                      : _vm._e(),
                                  ],
                                  2
                                ),
                              ]
                            )
                          : _vm._e()
                      }),
                      0
                    )
                  }),
                  0
                ),
                _c(
                  "tbody",
                  [
                    _vm.table.body && _vm.table.body.length > 0
                      ? [
                          _vm._l(_vm.table.body, function (row, rowIndex) {
                            return [
                              _c(
                                "tr",
                                { key: rowIndex },
                                _vm._l(row, function (col, colIndex) {
                                  return !col._removed
                                    ? _c(
                                        "td",
                                        {
                                          key: `${colIndex}`,
                                          style: _vm.getTdStyle(
                                            _vm.table,
                                            col,
                                            colIndex
                                          ),
                                          attrs: {
                                            rowspan: col.rowspan,
                                            colspan: col.colspan,
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "content",
                                              style: { color: col.color },
                                              attrs: { title: col.content },
                                            },
                                            [
                                              _vm._v(
                                                " " + _vm._s(col.content) + " "
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _vm._e()
                                }),
                                0
                              ),
                              _vm._l(row, function (col, colIndex) {
                                return [
                                  col.children && col.showChildren
                                    ? _vm._l(
                                        col.children,
                                        function (row2, rowIndex2) {
                                          return _c(
                                            "tr",
                                            { key: `${rowIndex2}_children` },
                                            _vm._l(
                                              row2,
                                              function (col2, colIndex2) {
                                                return !col2._removed
                                                  ? _c(
                                                      "td",
                                                      {
                                                        key: `${colIndex2}_children`,
                                                        style: _vm.getTdStyle(
                                                          _vm.table,
                                                          col2,
                                                          colIndex2
                                                        ),
                                                        attrs: {
                                                          rowspan: col2.rowspan,
                                                          colspan: col2.colspan,
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "content",
                                                            style: {
                                                              color: col2.color,
                                                            },
                                                            attrs: {
                                                              title:
                                                                col2.content,
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  col2.content
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e()
                                              }
                                            ),
                                            0
                                          )
                                        }
                                      )
                                    : _vm._e(),
                                ]
                              }),
                            ]
                          }),
                        ]
                      : [
                          _c("tr", [
                            _c(
                              "td",
                              {
                                staticClass: "no-data",
                                staticStyle: { "text-align": "center" },
                                attrs: { colspan: _vm.columnCount },
                              },
                              [_vm._v("暂无数据")]
                            ),
                          ]),
                        ],
                  ],
                  2
                ),
              ]
            ),
          ]
        ),
        _c(
          "div",
          {
            ref: "realTable-wrapper",
            staticClass: "realTable-wrapper bigPowerTable",
          },
          [
            _c("table", { ref: "realTable", staticClass: "table" }, [
              _c(
                "thead",
                _vm._l(_vm.table.header, function (row) {
                  return _c(
                    "tr",
                    _vm._l(row, function (col, colIndex) {
                      return !col._removed
                        ? _c(
                            "th",
                            {
                              class: { removed: col._removed },
                              style: _vm.getThStyle(col),
                              attrs: {
                                rowspan: col.rowspan,
                                colspan: col.colspan,
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "content",
                                  attrs: { title: col.content },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleCellClick(col)
                                    },
                                  },
                                },
                                [
                                  _vm._v(" " + _vm._s(col.content) + " "),
                                  col.tip
                                    ? [
                                        _c(
                                          "el-tooltip",
                                          {
                                            staticClass: "item",
                                            attrs: {
                                              effect: "dark",
                                              placement: "top",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                attrs: { slot: "content" },
                                                slot: "content",
                                              },
                                              [_vm._v(_vm._s(col.tip))]
                                            ),
                                            _c("i", {
                                              staticClass:
                                                "iconfont iconicon_tips",
                                            }),
                                          ]
                                        ),
                                      ]
                                    : _vm._e(),
                                ],
                                2
                              ),
                            ]
                          )
                        : _vm._e()
                    }),
                    0
                  )
                }),
                0
              ),
              _c(
                "tbody",
                [
                  _vm.table.body && _vm.table.body.length > 0
                    ? [
                        _vm._l(_vm.table.body, function (row, rowIndex) {
                          return [
                            _c(
                              "tr",
                              { key: `${rowIndex}` },
                              _vm._l(row, function (col, colIndex) {
                                return !col._removed
                                  ? _c(
                                      "td",
                                      {
                                        key: `${colIndex}`,
                                        style: _vm.getTdStyle(
                                          _vm.table,
                                          col,
                                          colIndex
                                        ),
                                        attrs: {
                                          rowspan: col.rowspan,
                                          colspan: col.colspan,
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "content",
                                            class: {
                                              clickable:
                                                !col.click ||
                                                (col.children &&
                                                  col.children.length > 0),
                                            },
                                            attrs: { title: col.content },
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleCellClick(col)
                                              },
                                            },
                                          },
                                          [
                                            col.content == "高"
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass: "levelHeight",
                                                  },
                                                  [_vm._v(_vm._s(col.content))]
                                                )
                                              : _vm._e(),
                                            col.content == "中"
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass: "levelCenter",
                                                  },
                                                  [_vm._v(_vm._s(col.content))]
                                                )
                                              : _vm._e(),
                                            col.content == "低"
                                              ? _c(
                                                  "div",
                                                  { staticClass: "levelDown" },
                                                  [_vm._v(_vm._s(col.content))]
                                                )
                                              : _vm._e(),
                                            col.content == "无"
                                              ? _c(
                                                  "div",
                                                  { staticClass: "noLevel" },
                                                  [_vm._v(_vm._s(col.content))]
                                                )
                                              : _vm._e(),
                                            col.content != "高" &&
                                            col.content != "中" &&
                                            col.content != "低" &&
                                            col.content != "无"
                                              ? _c("div", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(col.content) +
                                                      " "
                                                  ),
                                                ])
                                              : _vm._e(),
                                            col.tip
                                              ? [
                                                  _c(
                                                    "el-tooltip",
                                                    {
                                                      staticClass: "item",
                                                      attrs: {
                                                        effect: "dark",
                                                        placement: "top",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          attrs: {
                                                            slot: "content",
                                                          },
                                                          slot: "content",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(col.tip)
                                                          ),
                                                        ]
                                                      ),
                                                      _c("i", {
                                                        staticClass:
                                                          "iconfont iconicon_tips",
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              : _vm._e(),
                                          ],
                                          2
                                        ),
                                      ]
                                    )
                                  : _vm._e()
                              }),
                              0
                            ),
                            _vm._l(row, function (col, colIndex) {
                              return [
                                col.children && col.showChildren
                                  ? _vm._l(
                                      col.children,
                                      function (row2, rowIndex2) {
                                        return _c(
                                          "tr",
                                          { key: `${rowIndex2}_children` },
                                          _vm._l(
                                            row2,
                                            function (col2, colIndex2) {
                                              return !col2._removed
                                                ? _c(
                                                    "td",
                                                    {
                                                      key: `${colIndex2}_children`,
                                                      style: _vm.getTdStyle(
                                                        _vm.table,
                                                        col2,
                                                        colIndex2
                                                      ),
                                                      attrs: {
                                                        rowspan: col2.rowspan,
                                                        colspan: col2.colspan,
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "content",
                                                          class: {
                                                            clickable:
                                                              col.click,
                                                          },
                                                          attrs: {
                                                            title: col2.content,
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.handleCellClick(
                                                                col2
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                col2.content
                                                              ) +
                                                              " "
                                                          ),
                                                          col2.tip
                                                            ? [
                                                                _c(
                                                                  "el-tooltip",
                                                                  {
                                                                    staticClass:
                                                                      "item",
                                                                    attrs: {
                                                                      effect:
                                                                        "dark",
                                                                      placement:
                                                                        "top",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        attrs: {
                                                                          slot: "content",
                                                                        },
                                                                        slot: "content",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            col2.tip
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c("i", {
                                                                      staticClass:
                                                                        "iconfont iconicon_tips",
                                                                    }),
                                                                  ]
                                                                ),
                                                              ]
                                                            : _vm._e(),
                                                        ],
                                                        2
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e()
                                            }
                                          ),
                                          0
                                        )
                                      }
                                    )
                                  : _vm._e(),
                              ]
                            }),
                          ]
                        }),
                      ]
                    : [
                        _c("tr", [
                          _c(
                            "td",
                            {
                              staticClass: "no-data",
                              staticStyle: { "text-align": "center" },
                              attrs: { colspan: _vm.columnCount },
                            },
                            [_vm._v("暂无数据")]
                          ),
                        ]),
                      ],
                ],
                2
              ),
            ]),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }