var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.value != null
    ? _c(
        "span",
        { staticClass: "fancy-decimal-wrapper" },
        [
          _c(
            "span",
            { staticClass: "unit", staticStyle: { "font-size": "0.24rem" } },
            [_vm._v("￥")]
          ),
          _vm.value === 0
            ? [_c("span", { staticClass: "integer" }, [_vm._v("0")])]
            : [
                _c("span", { staticClass: "integer" }, [
                  _vm._v(_vm._s(_vm.integerPart)),
                ]),
                _vm.fragmentPart
                  ? _c("span", { staticClass: "fragment" }, [
                      _vm._v(_vm._s(_vm.fragmentPart)),
                    ])
                  : _vm._e(),
              ],
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }