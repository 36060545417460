var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "pay" }, [
    _c("div", { staticClass: "pay-top" }, [
      _c("div", { staticClass: "haibaoPic" }, [
        _c("img", {
          staticClass: "backPic",
          attrs: { src: _vm.item.showImageUrl, alt: "" },
        }),
        _c("img", {
          staticClass: "version-logo",
          attrs: { src: _vm.getCornerPic(_vm.item.attrs), alt: "" },
        }),
      ]),
      _c("div", { staticStyle: { "padding-left": "0.3rem" } }, [
        _c("div", { staticClass: "pay-title" }, [
          _vm._v(
            _vm._s(_vm.item.productName ? _vm.item.productName : "老板看报告")
          ),
        ]),
        _c("div", { staticClass: "pay-price" }, [
          _c("div", { staticClass: "productUnitPrice" }, [
            _vm._v(" ￥"),
            _c(
              "span",
              {
                staticClass: "price-title",
                staticStyle: { "margin-right": "10px" },
              },
              [_vm._v(_vm._s(_vm.formatToDecimal(_vm.item.currentPrice)))]
            ),
            _vm.item.discount
              ? _c("span", { staticClass: "price-title" }, [
                  _vm._v(
                    "￥" + _vm._s(_vm.formatToDecimal(_vm.item.salesUnitPrice))
                  ),
                ])
              : _vm._e(),
          ]),
          _c("div", { staticClass: "pay-del" }, [
            _c(
              "div",
              {
                staticClass: "item item-left",
                class: _vm.item.bundlingNum ? "disabled" : "",
                attrs: { id: _vm.num == 1 || _vm.num == "1" ? "zero" : "" },
                on: { click: _vm.delNum },
              },
              [_vm._v(" - ")]
            ),
            _c("div", { staticClass: "item item-center" }, [
              _vm._v(
                _vm._s(_vm.item.bundlingNum ? _vm.item.bundlingNum : _vm.num)
              ),
            ]),
            _c(
              "div",
              {
                staticClass: "item item-right",
                class: _vm.item.bundlingNum ? "disabled" : "",
                on: { click: _vm.addNum },
              },
              [_vm._v("+ ")]
            ),
          ]),
        ]),
      ]),
    ]),
    _vm.item.bundlingNum != null
      ? _c("div", { staticClass: "pay-bottom-text" }, [
          _c("i", { staticClass: "el-icon-warning-outline" }),
          _c("div", [
            _vm._v(" 免费产品限购" + _vm._s(_vm.item.bundlingNum) + "份 "),
          ]),
        ])
      : _vm._e(),
    _c("div", { staticClass: "pay-total" }, [
      _c("div", { staticClass: "txt" }, [_vm._v("应付金额")]),
      _c("div", { staticClass: "txtMoney" }, [
        _c("span", [_vm._v("￥")]),
        _vm._v(
          " " +
            _vm._s(
              _vm.formatToDecimal(
                Number(_vm.goodsCount) * Number(_vm.item.currentPrice)
              )
            ) +
            " "
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }