var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "s-order-radio" },
    _vm._l(_vm.options, function (item, index) {
      return _c(
        "div",
        {
          key: index,
          staticClass: "s-radio-box",
          class: { active: _vm.val === item.attrKey },
          on: {
            click: function ($event) {
              return _vm.changeTab(item)
            },
          },
        },
        [_vm._v(" " + _vm._s(item.attrVal) + " ")]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }