var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "van-overlay",
    {
      attrs: { show: _vm.show },
      on: {
        click: function ($event) {
          _vm.show = false
        },
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "wrapper",
          on: {
            click: function ($event) {
              $event.stopPropagation()
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "inbox",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                },
              },
            },
            [
              _c("img", {
                attrs: {
                  src: require("@/assets/web_chahua_team@2.png"),
                  alt: "",
                },
              }),
              _c("div", { staticClass: "secondTile" }, [
                _vm._v("系统验证授权信息约 "),
                _c("span", { staticStyle: { color: "#165DFF" } }, [
                  _vm._v("3"),
                ]),
                _vm._v(" 分钟，请不要离开！"),
              ]),
              _c("div", { staticClass: "lastdiv" }, [
                _vm._v("校验通过将自动进入系统数据分析流程， "),
                _c("br"),
                _vm._v("如检验失败，请按照系统提示调整后重新授权。"),
              ]),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }