var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "van-tabs",
        {
          staticClass: "btn-tabs",
          attrs: { type: "card" },
          model: {
            value: _vm.activeIndex,
            callback: function ($$v) {
              _vm.activeIndex = $$v
            },
            expression: "activeIndex",
          },
        },
        _vm._l(_vm.data.yearChart, function (yearChart, year) {
          return _c("van-tab", { key: year, attrs: { title: year } }, [
            _c(
              "div",
              { staticClass: "tab-content" },
              [
                _c("BossTable", {
                  staticClass: "table",
                  attrs: { loaded: !!yearChart.table, data: yearChart.table },
                }),
                _c("ColumnChart", {
                  attrs: {
                    "v-if": yearChart.invoiceImageMap,
                    data: yearChart.invoiceImageMap.ruleOfInvoiceImageList[0],
                  },
                }),
              ],
              1
            ),
          ])
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }