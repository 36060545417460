var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "poster" }, [
    _c("div", { staticClass: "title" }, [_vm._v("基础数据")]),
    _c(
      "div",
      { staticClass: "detectionTab" },
      [
        _c(
          "el-radio-group",
          {
            model: {
              value: _vm.tabPosition,
              callback: function ($$v) {
                _vm.tabPosition = $$v
              },
              expression: "tabPosition",
            },
          },
          [
            _c("el-radio-button", { attrs: { label: "every" } }, [
              _vm._v("不限制"),
            ]),
            _c("el-radio-button", { attrs: { label: "7" } }, [_vm._v("近7日")]),
            _c("el-radio-button", { attrs: { label: "30" } }, [
              _vm._v("近30日"),
            ]),
          ],
          1
        ),
      ],
      1
    ),
    _vm._m(0),
    _c("div", { staticClass: "title" }, [_vm._v("邀请海报")]),
    _c("img", {
      staticClass: "banner",
      attrs: { src: require("@/assets/banner.png"), alt: "" },
      on: { click: _vm.goPosterProduction },
    }),
    _c("div", { staticClass: "title" }, [_vm._v("海报生成记录")]),
    _c(
      "div",
      { staticClass: "posterRecordTab" },
      [
        _c(
          "el-radio-group",
          {
            model: {
              value: _vm.tabPosition2,
              callback: function ($$v) {
                _vm.tabPosition2 = $$v
              },
              expression: "tabPosition2",
            },
          },
          [
            _c("el-radio-button", { attrs: { label: "everyOne" } }, [
              _vm._v("全部"),
            ]),
            _c("el-radio-button", { attrs: { label: "1" } }, [
              _vm._v("生效中"),
            ]),
            _c("el-radio-button", { attrs: { label: "2" } }, [
              _vm._v("未生效"),
            ]),
            _c("el-radio-button", { attrs: { label: "3" } }, [
              _vm._v("已失效"),
            ]),
          ],
          1
        ),
      ],
      1
    ),
    _vm.tabPosition2 == "everyOne"
      ? _c(
          "div",
          {
            staticClass: "detectionCard",
            on: { click: _vm.clickPosterDetail },
          },
          [
            _c("div", { staticClass: "cardTime" }, [
              _c("img", {
                staticClass: "backPic",
                attrs: { src: require("@/assets/老板看报告插图.png"), alt: "" },
              }),
              _vm._m(1),
              _c("div", { staticClass: "status" }, [
                _c(
                  "div",
                  {
                    staticClass: "statusBlue",
                    on: {
                      click: function ($event) {
                        return _vm.clickStatusTip(1)
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "statusCircleBlue" }),
                    _vm._v(" 生成中 "),
                  ]
                ),
              ]),
            ]),
            _c("div", { staticClass: "pay-bottom" }, [
              _c(
                "div",
                {
                  staticClass: "pay-btn",
                  on: {
                    click: function ($event) {
                      return _vm.clickDelete()
                    },
                  },
                },
                [_vm._v("删除")]
              ),
            ]),
          ]
        )
      : _vm._e(),
    _vm.tabPosition2 == "everyOne"
      ? _c("div", { staticClass: "detectionCard" }, [
          _c("div", { staticClass: "cardTime" }, [
            _c("img", {
              staticClass: "backPic",
              attrs: {
                src: require("@/assets/老板看报告插图 (1).png"),
                alt: "",
              },
            }),
            _vm._m(2),
            _c("div", { staticClass: "status" }, [
              _c(
                "div",
                {
                  staticClass: "statusGreen",
                  on: {
                    click: function ($event) {
                      return _vm.clickStatusTip(2)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "statusCircleGreen" }),
                  _vm._v(" 已生成 "),
                ]
              ),
            ]),
          ]),
          _vm._m(3),
        ])
      : _vm._e(),
    _vm.tabPosition2 == "everyOne"
      ? _c("div", { staticClass: "detectionCard" }, [
          _c("div", { staticClass: "cardTime" }, [
            _c("img", {
              staticClass: "backPic",
              attrs: {
                src: require("@/assets/老板看报告插图 (1).png"),
                alt: "",
              },
            }),
            _vm._m(4),
            _c("div", { staticClass: "status" }, [
              _c(
                "div",
                {
                  staticClass: "statusRed",
                  on: {
                    click: function ($event) {
                      return _vm.clickStatusTip(3)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "statusCircleRed" }),
                  _vm._v(" 生成出错 "),
                ]
              ),
            ]),
          ]),
          _vm._m(5),
        ])
      : _vm._e(),
    _vm.tabPosition2 == "everyOne"
      ? _c(
          "div",
          {
            staticClass: "detectionCard",
            staticStyle: { background: "rgba(0, 0, 0, 0.1)" },
          },
          [
            _c("div", { staticClass: "cardTime" }, [
              _c("img", {
                staticClass: "backPic",
                attrs: {
                  src: require("@/assets/老板看报告插图 (1).png"),
                  alt: "",
                },
              }),
              _vm._m(6),
              _c("div", { staticClass: "status" }, [
                _c(
                  "div",
                  {
                    staticClass: "statusGray",
                    on: {
                      click: function ($event) {
                        return _vm.clickStatusTip(4)
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "statusCircleGray" }),
                    _vm._v(" 已删除 "),
                  ]
                ),
              ]),
            ]),
          ]
        )
      : _c("div", { staticClass: "nullPic" }, [
          _c("img", {
            staticClass: "backPic",
            attrs: { src: require("@/assets/暂无数据.png"), alt: "" },
          }),
          _vm._v(" 您还没有发起过风险检测哦～ "),
        ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "posterList" }, [
      _c("div", { staticClass: "posterItem borderBt borderRg" }, [
        _c("div", { staticClass: "posterNum" }, [_vm._v("3412")]),
        _c("div", { staticClass: "posterName" }, [_vm._v("海拔扫码次数")]),
      ]),
      _c("div", { staticClass: "posterItem borderBt" }, [
        _c("div", { staticClass: "posterNum" }, [_vm._v("34242")]),
        _c("div", { staticClass: "posterName" }, [_vm._v("推广用户数")]),
      ]),
      _c("div", { staticClass: "posterItem borderRg" }, [
        _c("div", { staticClass: "posterNum" }, [_vm._v("3442")]),
        _c("div", { staticClass: "posterName" }, [_vm._v("领取检测次数")]),
      ]),
      _c("div", { staticClass: "posterItem" }, [
        _c("div", { staticClass: "posterNum" }, [_vm._v("34")]),
        _c("div", { staticClass: "posterName" }, [_vm._v("销售总金额")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticStyle: { "margin-left": "0.3rem" } }, [
      _c("div", { staticClass: "timeOne" }, [_vm._v("专业版2.0")]),
      _c("div", { staticClass: "timeTwo" }, [
        _vm._v("海报有效期：2023.08.28～2023.08.29"),
      ]),
      _c("div", { staticClass: "timeThree" }, [
        _vm._v("创建时间：2023.08.28 16:01:30"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticStyle: { "margin-left": "0.3rem" } }, [
      _c("div", { staticClass: "timeOne" }, [_vm._v("专业版2.0")]),
      _c("div", { staticClass: "timeTwo" }, [
        _vm._v("报税版本-年度检测-2019年"),
      ]),
      _c("div", { staticClass: "timeThree" }, [_vm._v("2019-10-11 12:23:34")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "pay-bottom" }, [
      _c("div", { staticClass: "pay-btn" }, [_vm._v("删除")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticStyle: { "margin-left": "0.3rem" } }, [
      _c("div", { staticClass: "timeOne" }, [_vm._v("专业版2.0")]),
      _c("div", { staticClass: "timeTwo" }, [
        _vm._v("报税版本-年度检测-2019年"),
      ]),
      _c("div", { staticClass: "timeThree" }, [_vm._v("2019-10-11 12:23:34")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "pay-bottom" }, [
      _c("div", { staticClass: "pay-btn" }, [_vm._v("删除")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticStyle: { "margin-left": "0.3rem" } }, [
      _c("div", { staticClass: "timeOne" }, [_vm._v("专业版2.0")]),
      _c("div", { staticClass: "timeTwo" }, [
        _vm._v("报税版本-年度检测-2019年"),
      ]),
      _c("div", { staticClass: "timeThree" }, [_vm._v("2019-10-11 12:23:34")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }